<template>
  <section class="quote-figures">
    <div class="form">
      <ValidationObserver v-slot="{ invalid }">
        <div class="field">
          <ValidationProvider
            :rules="form.current_mileage.rules"
            tag="div"
            v-slot="{ errors }"
            class="quote-figures__row"
          >
            <label for="current-mileage"
              >Current mileage
              <ToolTip text="The exact mileage on the vehicle you are buying." class="ml-1 -mt-1" />
            </label>

            <input
              type="number"
              v-model="form.current_mileage.value"
              min="0"
              id="current-mileage"
              placeholder="Current mileage"
              class="bg-white shadow-sm rounded-md border-2 border-['#ddd'] px-4"
              :class="{ 'border-red-300': errors.length }"
            />
            <div class="text-red-500" v-if="errors">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>

        <div class="field">
          <ValidationProvider
            :rules="form.price.rules"
            tag="div"
            v-slot="{ errors }"
            class="quote-figures__row"
          >
            <label for="price"
              >Cash price
              <ToolTip text="The total price of the vehicle you are buying" class="ml-1 -mt-1" />
            </label>
            <div
              class="flex items-center bg-white shadow-sm rounded-md border-2 border-['#ddd'] px-4"
              :class="{ 'border-red-300': errors.length }"
            >
              <i class="fas fa-pound-sign text-gray-400 dealer-text-secondary text-lg mt-1"></i>
              <input
                type="number"
                v-model="form.price.value"
                min="2000"
                id="price"
                placeholder="Price"
                class="shadow-none border-none"
              />
            </div>
            <div class="text-red-500" v-if="errors">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>

        <div class="field">
          <ValidationProvider
            :rules="form.deposit.rules"
            tag="div"
            v-slot="{ errors }"
            class="quote-figures__row"
          >
            <label for="deposit"
              >Deposit
              <ToolTip
                text="The amount of cash deposit (if any) you are putting towards the purchase"
                class="ml-1 -mt-1"
              />
            </label>
            <div
              class="flex items-center bg-white shadow-sm rounded-md border-2 border-['#ddd'] px-4"
              :class="{ 'border-red-300': errors.length }"
            >
              <i class="fas fa-pound-sign text-gray-400 dealer-text-secondary text-lg mt-1"></i>
              <input
                type="number"
                v-model="form.deposit.value"
                min="0"
                id="deposit"
                class="bg-none border-none shadow-none"
                placeholder="Deposit"
              />
            </div>
            <div class="text-red-500" v-if="errors">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>

        <div class="field">
          <ValidationProvider
            :rules="form.part_exchange.rules"
            tag="div"
            v-slot="{ errors }"
            class="quote-figures__row"
          >
            <label for="part-exchange"
              >Part exchange
              <ToolTip text="Use your own vehicle as a deposit" class="ml-1 -mt-1" />
            </label>
            <div
              class="flex items-center bg-white shadow-sm rounded-md border-2 border-['#ddd'] px-4"
              :class="{ 'border-red-300': errors.length }"
            >
              <i class="fas fa-pound-sign text-gray-400 dealer-text-secondary text-lg mt-1"></i>
              <input
                type="number"
                v-model="form.part_exchange.value"
                min="0"
                id="part-exchange"
                class="bg-none border-none shadow-none"
                placeholder="Part exchange"
              />
            </div>
            <div class="text-red-500" v-if="errors">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>

        <div class="field">
          <ValidationProvider
            :rules="form.outstanding_settlement.rules"
            tag="div"
            v-slot="{ errors }"
            class="quote-figures__row"
          >
            <label for="outstanding-settlement"
              >Existing finance
              <ToolTip
                text="The amount of any finance outstanding/left to pay on your current car (if any)"
                class="ml-1 -mt-1"
              />
            </label>
            <div
              class="flex items-center bg-white shadow-sm rounded-md border-2 border-['#ddd'] px-4"
              :class="{ 'border-red-300': errors.length }"
            >
              <i class="fas fa-pound-sign text-gray-400 dealer-text-secondary text-lg mt-1"></i>
              <input
                type="number"
                v-model="form.outstanding_settlement.value"
                min="0"
                class="bg-none shadow-none border-none"
                id="outstanding-settlement"
                placeholder="Existing finance"
              />
            </div>
            <div class="text-red-500" v-if="errors">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>

        <div class="field">
          <ValidationProvider
            :rules="form.term.rules"
            tag="div"
            v-slot="{ errors }"
            class="quote-figures__row"
          >
            <label for="term"
              >Term
              <ToolTip
                text="Over how many months would you like to pay for your car. Longer terms have lower payments."
                class="ml-1 -mt-1"
              />
            </label>
            <select id="term" v-model="form.term.value" class="form-select">
              <option v-for="(option, i) in form.term.options" :key="i" :value="option.value">
                {{ option.label }}
              </option>
            </select>
            <div class="text-red-500" v-if="errors">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>

        <div class="field">
          <ValidationProvider
            :rules="form.estimated_mileage.rules"
            tag="div"
            v-slot="{ errors }"
            class="quote-figures__row"
          >
            <label for="estimated-mileage"
              >Estimated annual mileage
              <ToolTip
                text="How many miles you travel per year. You can change this later if you need to."
                class="ml-1 -mt-1"
              />
            </label>
            <select
              id="estimated-mileage"
              v-model="form.estimated_mileage.value"
              class="form-select"
            >
              <option
                v-for="(option, i) in form.estimated_mileage.options"
                :key="i"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
            <div class="text-red-500" v-if="errors">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>

        <div class="field">
          <div class="quote-figures__row">
            <label
              >Finance balance
              <ToolTip
                text="How much you would like to borrow based on the figures above"
                class="ml-1 -mt-1"
              />
            </label>
            <div class="value">
              <h2 class="mb-0 text-flow-blue dealer-text-secondary">{{ balance | toCurrency }}</h2>
            </div>
          </div>
        </div>

        <div class="form-actions flex justify-end">
          <button
            @click="submit"
            class="dealer-btn-primary"
            :disabled="invalid"
            :class="{ 'opacity-50': invalid }"
          >
            Next
          </button>
        </div>
      </ValidationObserver>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToolTip from '@/components/Form/ToolTip';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ToolTip,
  },

  data() {
    return {
      form: {
        current_mileage: {
          value: null,
          rules: 'required',
        },
        price: {
          value: null,
          rules: 'required',
        },
        deposit: {
          value: 0,
          rules: 'required',
        },
        part_exchange: {
          value: 0,
          rules: 'required',
        },
        outstanding_settlement: {
          value: 0,
          rules: 'required',
        },
        term: {
          value: 48,
          rules: 'required',
          options: [], // Populated in the mounted() hook
        },
        estimated_mileage: {
          value: null,
          rules: 'required',
          options: [],
        },
      },
      formatMileage: 0,
    };
  },

  watch: {
    // Comma separate number, please keep
    // formatMileage: function(newValue) {
    // 	const result = newValue.replace(/\D/g, "")
    // 		.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // 	this.$nextTick(() => {
    // 		this.formatMileage = result
    // 	});
    // }
  },

  computed: {
    ...mapState(['dashboard', 'dealer']),

    /**
     * Returns the finance balance
     * @returns {number}
     */
    balance() {
      if (this.form.price.value) {
        return (
          (parseFloat(this.form.price.value) +
            parseFloat(this.form.outstanding_settlement.value) -
            parseFloat(this.form.deposit.value) -
            parseFloat(this.form.part_exchange.value)) *
          100
        );
      }
      return 0;
    },
  },

  methods: {
    async submit() {
      // Store the figures
      this.$store.commit('setCalculatorFigures', this.mapCalculatorFigures());

      // Proceed to next step
      return await this.$router.push({ name: 'dashboard__calculator__results' });
    },

    mapCalculatorFigures() {
      return {
        // current_mileage: parseInt(this.formatMileage.replace(/\D/g, "")),
        current_mileage: parseInt(this.form.current_mileage.value),
        price: parseInt(this.form.price.value),
        deposit: parseInt(this.form.deposit.value),
        part_exchange: parseInt(this.form.part_exchange.value),
        outstanding_settlement: parseInt(this.form.outstanding_settlement.value),
        term: parseInt(this.form.term.value),
        estimated_mileage: parseInt(this.form.estimated_mileage.value),
      };
    },

    getEstimatedMileageOptions() {
      const min = 5000;
      const max = 40000;
      const increment = 1000;

      for (let i = min; i <= max; i += increment) {
        this.form.estimated_mileage.options.push({
          value: i,
          label: i,
        });
      }

      this.form.estimated_mileage.value = this.form.estimated_mileage.options[1].value;
    },
  },

  async mounted() {
    // Have they selected a vehicle?
    if (this.dashboard.calculator.vehicle === null) {
      return await this.$router.push({ name: 'dashboard__calculator__quotes' });
    }

    // Get annual mileage drop down options
    this.getEstimatedMileageOptions();

    // Get the term drop down options
    this.form.term.options = this.getTermOptions();
  },
};
</script>
