var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{ref:"validator",attrs:{"mode":_vm.validationMode,"rules":_vm.rules,"name":_vm.veeName ? _vm._uid + '_' + _vm.veeName : _vm._uid + '_' + _vm.name},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('div',{staticClass:"mt-1 flex rounded-md"},[_c('div',{staticClass:"relative flex items-stretch flex-grow focus-within:z-10 text-input-button"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"h-12 m:leading-6 text-lg form-input-button",class:{ 'border-red-300': errors.length, uppercase: _vm.isUppercase },attrs:{"autocomplete":_vm.autocomplete,"tabindex":_vm.tabindex,"id":_vm.id,"name":_vm.name,"placeholder":_vm.placeholder},domProps:{"value":(_vm.localValue)},on:{"change":_vm.onChange,"input":function($event){if($event.target.composing)return;_vm.localValue=$event.target.value}}}),_c('button',{staticClass:"rounded-md bg-flow-blue dealer-bg-secondary -ml-px relative inline-flex items-center space-x-2 px-8 py-2 text-md font-bold focus:outline-none transition duration-150 ease-in-out uppercase",class:{
          'opacity-50 cursor-not-allowed pointer-events-none': _vm.isDisabled,
          'switch focus:outline-none focus:border-flow-blue focus:shadow-outline-flow-teal active:bg-tf-flow-teal':
            _vm.isSwitch,
          '': !_vm.isSwitch,
          'switch-on bg-tf-flow-teal text-white': _vm.selected && _vm.isSwitch,
          'switch-off bg-white dealer-text-primary border-tf-flow-teal': !_vm.selected && _vm.isSwitch,
        },attrs:{"disabled":_vm.isDisabled},on:{"click":function($event){return _vm.clickHandler()}}},[_c('svg',{staticClass:"mr-2 h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512","fill":"#ffffff"}},[_c('path',{attrs:{"d":"M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1l124 124 17 17L478.1 512l-17-17-124-124z"}})]),_vm._v(" "+_vm._s(_vm.valueButton)+" ")])])]),(errors && errors.length)?_c('p',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]):(_vm.hint)?_c('p',{staticClass:"input-hint mt-2 text-sm text-gray-500"},[_vm._v(_vm._s(_vm.hint))]):_vm._e()]}}])})
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }