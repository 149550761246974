<template>
  <section class="applications">
    <vue-headful
      description="At Flow car Finance we think car finance should be fair, simple & sincere. With over 30 years of knowledge, expertise and listening to you, we came up with an effortless car, van or motorcycle buying experience."
      url="https://flowcarfinance.co.uk/applications"
    />

    <Banner
      banner_title="Review About You"
      banner_subtitle="We would like to confirm a few details before creating a new application, this will not take long."
    />

    <main class="bg-gray-100">
      <div class="max-w-lg mx-auto w-full px-6 lg:px-8 py-12">
        <ValidationObserver ref="observer" v-slot="{ invalid }" tag="div">
          <!-- Marital status field -->
          <div class="field">
            <FormLabel>Which best describes you?</FormLabel>
            <RadioButtons
              :max-options="6"
              :rules="form.marital_status.rules"
              :options="form.marital_status.options"
              v-model="form.marital_status.value"
              cols="2"
            />
          </div>

          <!-- Driving licence field -->
          <div class="field">
            <FormLabel>What kind of driving license do you have?</FormLabel>
            <RadioButtons
              :max-options="6"
              :rules="form.driving_licence_type.rules"
              :options="form.driving_licence_type.options"
              v-model="form.driving_licence_type.value"
              cols="2"
            />
          </div>

          <p class="bg-gray-300 px-5 text-sm py-4 flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 mr-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
              />
            </svg>
            Please confirm if about you is correct
          </p>

          <div v-if="error" class="bg-red-500 text-white px-5 py-4 mb-5">
            {{ error }}
          </div>

          <div class="form-actions flex items-center">
            <div
              class="flex cursor-pointer space-x-2 text-flow-blue hover:text-flow-teal"
              @click="$router.push({ name: 'applications__review' })"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                />
              </svg>
              <span>Previous</span>
            </div>
            <button
              @click="save"
              :disabled="invalid"
              class="dealer-btn-primary ml-auto hover:dealer-text-primary"
              :class="{ 'opacity-50': invalid }"
            >
              Save
            </button>
          </div>
        </ValidationObserver>
      </div>
    </main>
  </section>
</template>

<script>
import Banner from '@/components/Ui/Applications/Banner';
import axios from 'axios';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import RadioButtons from '@/components/Form/RadioButtons';
import FormLabel from '@/components/Form/FormLabel';
import Api from '@/lib/Api';
import Bugsnag from '@bugsnag/js';
import moment from 'moment';

export default {
  name: 'ReviewAboutYou',
  data() {
    return {
      error: null,
      form: {
        marital_status: {
          options: [
            { label: 'Married', value: 'Married' },
            { label: 'Single', value: 'Single' },
            { label: 'Cohabiting', value: 'Cohabiting' },
            { label: 'Divorced', value: 'Divorced' },
            { label: 'Widowed', value: 'Widowed' },
            { label: 'Separated', value: 'Separated' },
          ],
          value: null,
          rules: 'required',
        },
        driving_licence_type: {
          options: [
            { label: 'Full UK', value: 'UK Full' },
            { label: 'Provisional UK', value: 'UK Provisional' },
            { label: 'EU', value: 'EU' },
            { label: 'International', value: 'International' },
            { label: 'None', value: 'No Licence Held' },
          ],
          value: null,
          rules: 'required',
        },
      },
    };
  },

  components: {
    Banner,
    ValidationObserver,
    ValidationProvider,
    RadioButtons,
    FormLabel,
  },

  computed: mapState(['dashboard']),

  methods: {
    ...Api,

    /**
     * Stores the applicant data in Vuex, saves a new application in the API and sends a
     * verification code to the phone number provided. If all succeeds, proceed to next step.
     */
    async save() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.$emit('loading', true, 'Saving...');
        this.error = null;

        const applicantData = {
          marital_status: this.form.marital_status.value,
          driving_licence_type: this.form.driving_licence_type.value,
        };

        // Update applicant in API
        axios
          .patch(
            this.getApiBaseUrl() +
              '/applications/' +
              this.dashboard.application.reference +
              '/applicants/' +
              this.dashboard.applicant.id,
            {
              applicant: applicantData,
            }
          )
          .then(async (r) => {
            if (r.data.success) {
              // Update data in Vuex
              await this.$store.commit('setDashboardApplicant', {
                ...this.dashboard.applicant,
                ...applicantData,
              });

              // Return to overview
              return await this.$router.push({ name: 'applications__review' });
            }
            throw new Error(
              `API failed to update applicant data (${this.journey.applicant.id}) when reviewing "About you" data`
            );
          })
          .catch((e) => {
            Bugsnag.notify(e);
            this.error =
              'There was a problem confirming your details. If this problem continues, please get in touch.';
            this.$emit('loading', false);
          });
      }
    },

    /**
     * Restores the existing that we already have.
     */
    restore() {
      const applicant = this.dashboard.applicant;

      if (applicant.marital_status) {
        this.form.marital_status.value = applicant.marital_status;
      }

      if (applicant.driving_licence_type) {
        this.form.driving_licence_type.value = applicant.driving_licence_type;
      }
    },
  },

  mounted() {
    this.restore();
  },
};
</script>

<style scoped></style>
