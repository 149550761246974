<template>
  <div class="page page--acceptance">
    <!-- Content -->
    <div class="col-span-2">
      <div class="max-w-screen-xl mx-auto">
        <template v-if="dealer.acceptance_terms">
          <div v-html="dealer.acceptance_terms" />
        </template>
        <template v-else>
          <h2 class="dealer-text-primary">Once you have your initial acceptance</h2>

          <p class="mt-4 text-xl leading-7 text-gray-600">
            We're here to help you every step along the way, without the hassle of paperwork. We’ll
            always treat you fairly and help you to buy the right car, from a good dealer and at a
            fair price. If you need any support, just use the chat box below, we’re here until 7pm
            on weekdays and 5pm on weekends.
          </p>
          <p class="mt-4 text-xl leading-7 text-gray-600">
            Here are the things we still need to check:
          </p>

          <div class="mt-10">
            <ul class="md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              <li v-for="(item, i) in pageContent.items" :key="i" class="mt-8 md:mt-0">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div
                      class="flex items-center justify-center h-10 text-flow-teal dealer-text-primary text-4xl"
                    >
                      <i :class="item.iconType + ' fa-' + item.iconName" class="-mt-3 text-4xl"></i>
                    </div>
                  </div>
                  <div class="ml-4">
                    <h4
                      class="text-lg leading-6 font-bold text-flow-blue dealer-text-secondary"
                      v-html="item.title"
                    />
                    <div v-html="item.content" />
                  </div>
                </div>
              </li>

              <li v-if="this.getApr() !== null">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div
                      class="flex items-center justify-center h-10 text-flow-teal dealer-text-primary"
                    >
                      <i class="fas fa-percentage -mt-3 text-4xl"></i>
                    </div>
                  </div>
                  <div class="ml-4">
                    <h4 class="text-lg leading-6 font-bold text-flow-blue dealer-text-secondary">
                      APR
                    </h4>
                    <div>
                      <p class="mt-2 text-base leading-6 text-gray-500">
                        The APR rate that we have provided is our very best estimate of the
                        {{ getApr() }}% APR you will be offered. This will vary depending on the
                        vehicle chosen, your credit profile and the term you choose. The
                        representative APR is <template v-if="isPrime()">7.9%</template
                        ><template v-else>16.6</template>% for the majority of our customers.
                      </p>
                    </div>
                  </div>
                </div>
              </li>

              <li v-else>
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div
                      class="flex items-center justify-center h-10 text-flow-teal dealer-text-primary"
                    >
                      <i class="fas fa-percentage -mt-3 text-4xl"></i>
                    </div>
                  </div>
                  <div class="ml-4">
                    <h4 class="text-lg leading-6 font-bold text-flow-blue dealer-text-secondary">
                      APR
                    </h4>
                    <div>
                      <p class="mt-2 text-base leading-6 text-gray-500">
                        If you can't see an APR in yoiur dashboard, this means we don't have an
                        acceptance is place just yet, and we'll need you to do a
                        <span
                          class="dealer-text-primary underline hover:no-underline hover:dealer-text-secondary cursor-pointer font-bold"
                          @click="resume"
                          >full application</span
                        >
                        so the lender can tell us (and you) if they will accept you and at what
                        finance rate.
                      </p>
                      <p class="text-gray-500">There are three main reasons why this can happen:</p>
                      <ul class="text-gray-500 list-decimal list-inside space-y-1.5">
                        <li>
                          Your credit score is low and we are waiting for lenders to let us know the
                          finance rate.
                        </li>
                        <li>
                          We've been unable to fully assess your credit profile, in which case your
                          Flow Score may be missing too.
                        </li>
                        <li>
                          There is a technical error, in which case your Flow Score may be missing
                          too.
                        </li>
                      </ul>
                      <p class="text-gray-500 mt-3">
                        In all cases we'll need to do a manual review and speak to you by chat or
                        phone.
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AcceptanceTerms',
  computed: {
    ...mapState(['dashboard', 'dealer']),
  },
  data() {
    return {
      pageContent: {
        items: [
          {
            iconName: 'hard-hat',
            iconType: 'fas',
            iconSize: '2x',
            title: 'Employment Details',
            content:
              "<p class=\"mt-2 text-base leading-6 text-gray-500\">Many of our lenders won't accept drivers who use their car as a taxi or as a courier/delivery driver. We ask you this right at the beginning of your application, so we assume that you are not. If you are in one of these occupations, it's likely that you will be declined for finance when you submit your final application with the car of your choice. We won't contact your employer, but we may ask for proof of income.</p>",
            active: true,
          },
          {
            iconName: 'coins',
            iconType: 'fas',
            iconSize: '2x',
            title: 'Affordability',
            content:
              "<p class=\"mt-2 text-base leading-6 text-gray-500\">It's important to us that we treat you fairly by assessing your finances. We'll let you know if we need to do further checks. Sometimes we\’ll ask for proof of income or bank statements to support your application, but we\’ll let you know if we do. Some of our lenders use Open Banking, which is a digital way of checking your incoming and outgoing finances. If we need to do this, we'll let you know!</p>",
            active: true,
          },
          {
            iconName: 'clipboard-check',
            iconType: 'fas',
            iconSize: '2x',
            title: 'Dealer check',
            content:
              '<p class="mt-2 text-base leading-6 text-gray-500">Our finance partners look for high dealer standards to make sure you\'re looked after if anything goes wrong with your car, so we check out the dealer on their behalf. You just need to tell us who the dealer is.</p>',
            active: true,
          },
          {
            iconName: 'piggy-bank',
            iconType: 'fas',
            iconSize: '2x',
            title: 'Bank account details',
            content:
              '<p class="mt-2 text-base leading-6 text-gray-500">You\'ll need to supply your bank details to finish off the application process. We\'ll keep your details safe. This helps the lender to verify your ID and also to complete their final checks.</p>',
            active: true,
          },
          {
            iconName: 'tag',
            iconType: 'fas',
            iconSize: '2x',
            title: 'Vehicle Price',
            content:
              '<p class="mt-2 text-base leading-6 text-gray-500">We\'ll check that the amount to finance is right for the make and model you are buying. This means you don\'t overpay, and the finance company isn’t lending too much on the car you are buying</p>',
            active: true,
          },
        ],
      },
    };
  },
  methods: {
    /**
     * Take them to their applicable journey (declined, regular)
     */
    apply() {
      return (window.location =
        process.env.VUE_APP_MAIN_SITE_URL + '/resume?ref=' + this.reference);

      // Used to test live environment on dev.
      // return window.location = 'https://dev.flowcarfinance.co.uk/short-form/apply/'
    },
  },
};
</script>
