<template>
  <div class="lg:grid md:grid-cols-1 lg:grid-cols-3 gap-6 mt-8">
    <!-- Filter  -->
    <div
      class="block lg:hidden text-center py-3 btn-primary dealer-btn-primary mb-8 md:w-1/2"
      @click="toggleFilter ^= true"
    >
      <h5
        v-if="loadingSearchResults"
        class="mb-0 text-white hover:text-flow-blue dealer-text-primary flex justify-center items-center"
      >
        <span
          class="spinner rounded-full w-5 h-5 spinner--2 mr-4"
          style="border: 2px solid #7ac5b6; border-top-color: transparent"
        ></span>
        Finding vehicles
      </h5>
      <h5 v-else class="mb-0 text-white hover:text-flow-blue dealer-text-primary">Filter Search</h5>
    </div>

    <section v-show="toggleFilter" class="filter-menu ease-in-out transition-all duration-300 mb-8">
      <div class="sm:border-1 sm:border-gray-200 rounded-lg sm:bg-white">
        <div class="border-b-1 border-gray-200 pb-6 sm:pt-6 mb-2 sm:mt-0 text-center">
          <strong>
            Showing
            <template v-if="results !== null && results.data.length">{{
              results.to.toLocaleString('en-GB')
            }}</template>
            <template v-else>0</template>
            of
            <template v-if="results !== null && results.data.length">{{
              results.total.toLocaleString('en-GB')
            }}</template>
            <template v-else>0</template>
            results
          </strong>

          <div class="mt-6 cursor-pointer" @click="resetFilters(true)">
            <svg
              aria-hidden="true"
              class="mr-2 svg-inline--fa fa-sync fa-w-16"
              data-icon="sync"
              data-prefix="fas"
              focusable="false"
              role="img"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z"
                fill="currentColor"
              ></path>
            </svg>
            <span>Reset</span>
          </div>

          <div v-if="activeFilters.length" class="w-full space-y-2 space-x-2 px-4">
            <FilterBadge
              v-for="(filter, i) in activeFilters"
              :key="i"
              :filter="filter"
              class="my-3"
              @click="resetFilter(filter.type)"
            />
          </div>
        </div>

        <!-- Location (postcode) -->
        <div class="field sm:px-4 grid grid-cols-2 gap-4">
          <div class="">
            <label class="flex items-center" for="filter-postcode">Postcode/Town</label>
            <input
              id="filter-postcode"
              v-model="filters.postcode"
              class=""
              placeholder="Postcode/Town"
              type="text"
              @change="getPostcodeCoords()"
              @keyup="errors.postcode.notFound = false"
              :class="{ 'opacity-50 pointer-events-none': loadingPostcode }"
            />
          </div>

          <template v-if="hasUserLocation">
            <ValidationProvider v-slot="{ errors }" class="" tag="div">
              <label class="flex items-center" for="radius">Distance</label>
              <select
                id="radius"
                v-model="filters.radius"
                class="z-1 form-select uppercase sm:leading-relaxed py-2 pl-3 pr-5 block text-2xl"
                :class="{
                  'opacity-50 pointer-events-none': !hasUserLocation() || !filters.postcode,
                }"
                name="radius"
              >
                <option
                  v-for="(option, key) in form.radius.options"
                  :key="key"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
              <div v-if="errors" class="text-red-500">{{ errors[0] }}</div>
            </ValidationProvider>
          </template>
        </div>
        <div v-if="errors.postcode.notFound" class="px-4 pb-2 text-red-500">
          Couldn't find that location
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <!-- Make and model -->
          <div class="field sm:px-4 border-t-1 border-gray-200 pt-4 grid grid-cols-2 gap-4">
            <ValidationProvider
              v-slot="{ errors }"
              :class="{ 'opacity-50 pointer-events-none': loadingMakes }"
              class=""
              tag="div"
            >
              <label class="text-lg block cursor-pointer flex justify-between items-center">
                Make
              </label>
              <transition name="fade">
                <select
                  id="make"
                  v-model="filters.make"
                  class="z-1 form-select uppercase sm:leading-relaxed py-2 pl-3 pr-5 block text-xl"
                  name="make"
                  @change="onMakeChange()"
                >
                  <option
                    v-for="(option, key) in form.make.options"
                    :key="key"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </transition>
              <div v-if="errors" class="text-red-500">{{ errors[0] }}</div>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              :class="{ 'opacity-50 pointer-events-none': loadingModels }"
              class=""
              tag="div"
            >
              <label class="text-lg block cursor-pointer flex justify-between items-center">
                Model
              </label>

              <transition name="fade">
                <select
                  id="model"
                  v-model="filters.model"
                  :class="filters.make != null ? '' : 'opacity-50 pointer-events-none'"
                  class="z-1 form-select uppercase sm:leading-relaxed py-2 pl-3 pr-5 block text-xl"
                  name="model"
                >
                  <option value="null">All</option>
                  <option v-for="(option, key) in orderedItems()" :key="key" :value="option.value">
                    {{ option.label }}
                  </option>
                </select>
              </transition>
              <div v-if="errors" class="text-red-500">{{ errors[0] }}</div>
            </ValidationProvider>
          </div>

          <!-- Age -->
          <div class="field sm:px-4 border-t-1 border-gray-200 pt-2">
            <label class="text-lg block cursor-pointer flex justify-between items-center">
              Year
            </label>

            <ValidationProvider v-slot="{ errors }" class="grid grid-cols-2 gap-4" tag="div">
              <div class="w-full items-center">
                <select
                  id="filter-min-age"
                  v-model="filters.min_age"
                  class="z-1 form-select uppercase sm:leading-relaxed py-2 pl-3 pr-5 block text-xl"
                  name="min-age"
                >
                  <option
                    v-for="(option, key) in form.min_age.options"
                    :key="key"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
              <div class="w-full items-center">
                <select
                  id="filter-max-age"
                  v-model="filters.max_age"
                  class="z-1 form-select uppercase sm:leading-relaxed py-2 pl-3 pr-5 block text-xl"
                  name="max-age"
                >
                  <option
                    v-for="(option, key) in form.max_age.options"
                    :key="key"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </ValidationProvider>
          </div>

          <!-- Mileage -->
          <div class="field sm:px-4 border-t-1 border-gray-200 pt-2">
            <label class="text-lg block cursor-pointer flex justify-between items-center">
              Mileage
            </label>
            <ValidationProvider v-slot="{ errors }" class="grid grid-cols-2 gap-4" tag="div">
              <div class="inline-flex w-full items-center">
                <input
                  id="min-mileage"
                  v-model="filters.min_mileage"
                  min="0"
                  placeholder="Min mileage"
                  step="10000"
                  type="number"
                />
              </div>
              <div class="inline-flex w-full items-center">
                <input
                  id="max-mileage"
                  v-model="filters.max_mileage"
                  min="0"
                  placeholder="Max mileage"
                  step="10000"
                  type="number"
                />
              </div>
            </ValidationProvider>
          </div>

          <!-- Prices -->
          <div class="field sm:px-4 border-t-1 border-gray-200 pt-2">
            <label class="text-lg block cursor-pointer flex justify-between items-center">
              Price
            </label>
            <ValidationProvider v-slot="{ errors }" class="grid grid-cols-2 gap-4" tag="div">
              <div class="inline-flex w-full items-center">
                <input
                  id="min-price"
                  v-model="filters.min_price"
                  :max="filters.max_price"
                  min="3500"
                  max="1000000"
                  step="500"
                  placeholder="Min price"
                  type="number"
                />
              </div>
              <div class="inline-flex w-full items-center">
                <input
                  id="max-price"
                  v-model="filters.max_price"
                  :min="filters.min_price"
                  min="3500"
                  max="1000000"
                  step="500"
                  placeholder="Max price"
                  type="number"
                />
              </div>
            </ValidationProvider>
          </div>

          <!-- Body type -->
          <div
            :class="{ 'opacity-50 pointer-events-none': loadingBodyTypes }"
            class="field sm:px-4 border-t-1 border-gray-200 pt-2"
          >
            <div class="grid grid-cols-2 gap-4 w-full items-center">
              <label class="flex items-center text-lg" for="filter-body-type">Body type</label>
              <select
                id="filter-body-type"
                v-model="filters.body_type"
                class="z-1 form-select uppercase sm:leading-relaxed py-2 pl-3 pr-5 block text-xl"
                name="body-type"
              >
                <option
                  v-for="(option, key) in form.body_type.options"
                  :key="key"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>

          <!-- Colour -->
          <div
            :class="{ 'opacity-50 pointer-events-none': loadingExteriorColors }"
            class="field sm:px-4 border-t-1 border-gray-200 pt-2"
          >
            <div class="grid grid-cols-2 gap-4 w-full items-center">
              <label class="flex items-center text-lg" for="filter-exterior-colour">Colour</label>
              <select
                id="filter-exterior-colour"
                v-model="filters.exterior_color"
                class="z-1 form-select uppercase sm:leading-relaxed py-2 pl-3 pr-5 block text-xl"
                name="exterior-colour"
              >
                <option
                  v-for="(option, key) in form.exterior_color.options"
                  :key="key"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>

          <!-- Doors -->
          <div class="field sm:px-4 border-t-1 border-gray-200 pt-2">
            <div class="grid grid-cols-2 gap-4 w-full items-center">
              <label class="flex items-center text-lg" for="filter-doors">Doors</label>
              <select
                id="filter-doors"
                v-model="filters.doors"
                class="z-1 form-select uppercase sm:leading-relaxed py-2 pl-3 pr-5 block text-xl"
                name="doors"
              >
                <option
                  v-for="(option, key) in form.doors.options"
                  :key="key"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>

          <!-- Fuel type -->
          <div class="field sm:px-4 border-t-1 border-gray-200 pt-2">
            <div class="grid grid-cols-2 gap-4 w-full items-center">
              <label class="flex items-center text-lg" for="filter-fuel-type">Fuel type</label>
              <select
                id="filter-fuel-type"
                v-model="filters.fuel_type"
                class="z-1 form-select uppercase sm:leading-relaxed py-2 pl-3 pr-5 block text-xl"
                name="fuel-type"
              >
                <option
                  v-for="(option, key) in form.fuel_type.options"
                  :key="key"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>

          <!-- Transmission -->
          <div class="field sm:px-4 border-t-1 border-gray-200 pt-2">
            <div class="grid grid-cols-2 gap-4 w-full items-center">
              <label class="flex items-center text-lg" for="filter-transmission">Gearbox</label>
              <select
                id="filter-transmission"
                v-model="filters.transmission"
                class="z-1 form-select uppercase sm:leading-relaxed py-2 pl-3 pr-5 block text-xl"
                name="transmission"
              >
                <option
                  v-for="(option, key) in form.transmission.options"
                  :key="key"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>

          <!-- Payment -->
          <div class="field sm:px-4 border-t-1 border-gray-200 pt-2">
            <div class="grid grid-cols-2 gap-4 w-full items-center">
              <label class="flex items-center text-lg" for="filter-transmission">Payment</label>
              <select
                id="filter-payment"
                v-model="filters.payment"
                class="z-1 form-select uppercase sm:leading-relaxed py-2 pl-3 pr-5 block text-xl"
                name="payment"
              >
                <option
                  v-for="(option, key) in form.payment.options"
                  :key="key"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>

          <div class="sticky bottom-0 pt-5 w-full text-center overflow-hidden">
            <div class="py-5 filter-search-button-container bg-gray-100">
              <div class="cursor-pointer w-full" @click="resetFilters(true)">
                <span>Reset filters - {{ activeFilters.length }} selected</span>
              </div>

              <button class="dealer-btn-primary normal-case mt-3" @click="search()">
                Search
                <template v-if="results !== null && results.data.length">{{
                  results.total.toLocaleString('en-GB')
                }}</template>
                cars
              </button>
            </div>
          </div>

          <div class="bg-gray-300 border-t-1 border-gray-200 px-8 py-5 mb-0 rounded-b-lg">
            <small
              >Quick search based on 4 year PCP or 5 year HP. We will use the estimated APR in your
              dashboard to work out your payments. You can tailor your deal on an individual car and
              your exact payments will depend on the deal you choose and the terms of the lender
              that accepts you.</small
            >
          </div>
        </ValidationObserver>
      </div>
    </section>

    <!-- Search results -->

    <!-- Loading skeleton -->
    <template v-if="loadingSearchResults">
      <section :class="{ 'pointer-events-none': loadingSearchResults }" class="col-span-2 relative">
        <!-- Pagination -->
        <div class="grid grid-cols-2 col-span-2">
          <span class="relative z-0 inline-flex rounded-md">
            <button
              class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-md font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-flow-teal w-12 h-11 justify-center bg-gray-300 animate-pulse"
            >
              <span class="sr-only">Previous</span>
              <i class="fas fa-angle-left"></i>
            </button>
            <button
              class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-md font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-flow-teal w-12 h-11 justify-center bg-gray-300 animate-pulse"
            >
              <span class="sr-only">Next</span>
              <i class="fas fa-angle-right"></i>
            </button>
          </span>

          <!-- Sort by -->
          <div class="ml-auto">
            <div class="flex justify-center items-center ml-auto">
              <label
                class="w-1/2 block text-sm font-medium text-gray-700 text-right mr-4"
                for="filter-value"
                >Sort by</label
              >
              <select
                v-model="form.sort.value"
                class="w-full mt-1 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md rounded-md bg-gray-300 animate-pulse"
              >
                <option :value="{ column: 'price', order: 'asc' }">Price (Lowest)</option>
                <option :value="{ column: 'price', order: 'desc' }">Price (Highest)</option>
                <option :value="{ column: 'miles', order: 'asc' }">Mileage</option>
                <option :value="{ column: 'year', order: 'desc' }">Age (Newest first)</option>
                <option :value="{ column: 'year', order: 'asc' }">Age (Oldest first)</option>
              </select>
            </div>
          </div>
        </div>

        <div class="md:grid grid-cols-1 sm:grid-cols-2 md:gap-4 mt-5">
          <div v-for="i in 8" :key="i">
            <div class="flex overflow-hidden">
              <div
                class="car-images car-primary w-full h-52 overflow-hidden mr-px flex justify-center items-center bg-gray-400 animate-pulse"
              ></div>
              <div class="w-1/4 overflow-hidden">
                <div class="h-1/3 car-images car-secondary bg-gray-300 animate-pulse" />
                <div class="h-px w-full bg-white" />
                <div class="h-1/3 car-images car-secondary bg-gray-300 animate-pulse" />
                <div class="h-px w-full bg-white" />
                <div class="h-1/3 car-images car-secondary bg-gray-300 animate-pulse" />
              </div>
            </div>
            <div class="px-4 py-5 sm:p-6">
              <div class="grid grid-cols-2 gap-4 relative">
                <div>
                  <h3 class="mb-0 bg-gray-400 animate-pulse h-5 w-3/4 rounded-full"></h3>
                  <h6 class="my-2 truncate bg-gray-300 animate-pulse h-4 w-2/4 rounded-full"></h6>
                  <p class="bg-gray-200 animate-pulse h-3 w-1/4 rounded-full"></p>
                </div>
                <div class="text-sm sm:text-md w-full">
                  <ul class="px-0 space-y-3">
                    <li class="flex items-center leading-4">
                      <div class="w-4 h-4 bg-gray-400 animate-pulse rounded-full mr-4"></div>
                      <div class="w-full h-4 bg-gray-300 animate-pulse rounded-full"></div>
                    </li>
                    <li class="flex items-center leading-4">
                      <div class="w-4 h-4 bg-gray-400 animate-pulse rounded-full mr-4"></div>
                      <div class="w-full h-4 bg-gray-300 animate-pulse rounded-full"></div>
                    </li>
                  </ul>
                </div>
              </div>
              <strong class="flex items-center text-md text-gray-500 mt-4 space-x-3">
                <span
                  class="summary mileage w-full h-3 bg-gray-400 animate-pulse rounded-full"
                ></span>
                <span class="summary year w-full h-3 bg-gray-400 animate-pulse rounded-full"></span>
                <span
                  class="summary transmission w-full h-3 bg-gray-400 animate-pulse rounded-full"
                ></span>
                <span
                  class="summary colour w-full h-3 bg-gray-400 animate-pulse rounded-full"
                ></span>
              </strong>
            </div>
          </div>
        </div>
      </section>
    </template>

    <template v-else>
      <section
        v-if="results !== null && results.data.length"
        :class="{ 'pointer-events-none': loadingSearchResults }"
        class="col-span-2 relative"
      >
        <!-- Pagination -->
        <div class="grid grid-cols-2 col-span-2">
          <span class="relative z-0 inline-flex rounded-md">
            <button
              class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-md font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-flow-teal w-12 h-11 justify-center"
              type="button"
              @click="previousPage()"
            >
              <span class="sr-only">Previous</span>
              <i class="fas fa-angle-left"></i>
            </button>
            <button
              class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-md font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-flow-teal w-12 h-11 justify-center"
              type="button"
              @click="nextPage()"
            >
              <span class="sr-only">Next</span>
              <i class="fas fa-angle-right"></i>
            </button>
          </span>

          <!-- Sort by -->
          <div class="ml-auto">
            <div class="flex justify-center items-center ml-auto">
              <label
                class="w-1/2 block text-sm font-medium text-gray-700 text-right mr-4"
                for="filter-value"
                >Sort by</label
              >
              <select
                id="filter-value"
                v-model="form.sort.value"
                class="w-full mt-1 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md rounded-md"
              >
                <option :value="{ column: 'price', order: 'asc' }">Price (Lowest)</option>
                <option :value="{ column: 'price', order: 'desc' }">Price (Highest)</option>
                <option :value="{ column: 'miles', order: 'asc' }">Mileage (Lowest)</option>
                <option :value="{ column: 'year', order: 'desc' }">Age (Newest first)</option>
                <option :value="{ column: 'year', order: 'asc' }">Age (Oldest first)</option>
                <option
                  v-if="filters.lat !== null && filters.lng !== null && filters.radius !== null"
                  :value="{ column: 'distance', order: 'asc' }"
                >
                  Distance (Nearest)
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="md:grid grid-cols-1 sm:grid-cols-2 md:gap-4 mt-5">
          <!-- Vehicles -->
          <div
            v-for="(vehicle, i) in results.data"
            :key="i"
            class="mt-5 md:mt-0 bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 hover:shadow-lg cursor-pointer relative vehicle-container"
            @click="viewCar(vehicle.quick_code)"
          >
            <div v-if="vehicle.images.length" class="flex overflow-hidden">
              <div
                class="app-type-label"
                v-if="vehicle.dealer.rating !== null && vehicle.dealer.rating === 10"
              >
                Recommended
              </div>

              <div
                v-lazy:background-image="vehicle.images[0]"
                class="bg-cover car-images car-primary w-full h-52 overflow-hidden mr-px flex justify-center items-center"
              />
              <div v-if="vehicle.images.length >= 4" class="w-1/4 overflow-hidden">
                <div
                  v-lazy:background-image="vehicle.images[1]"
                  class="bg-cover h-1/3 car-images car-secondary"
                />
                <div class="h-px w-full bg-white" />
                <div
                  v-lazy:background-image="vehicle.images[2]"
                  class="bg-cover h-1/3 car-images car-secondary"
                />
                <div class="h-px w-full bg-white" />
                <div
                  v-lazy:background-image="vehicle.images[3]"
                  class="bg-cover h-1/3 car-images car-secondary"
                />
              </div>
            </div>

            <div v-else class="flex overflow-hidden">
              <div
                class="car-images car-primary w-full h-52 overflow-hidden mr-px flex justify-center items-center"
              >
                <div>
                  <div class="w-full text-center text-6xl">
                    <i class="fal fa-image-polaroid text-gray-300"></i>
                  </div>
                  <h6 class="w-full text-gray-400">No image available</h6>
                </div>
              </div>
            </div>

            <div class="px-5 pt-3 pb-2 relative vehicle-details">
              <div class="grid grid-cols-2 relative text-flow-blue">
                <div>
                  <h3 class="mb-0">&pound;{{ vehicle.price.toLocaleString('en-GB') }}</h3>
                  <h6 class="mb-0 truncate">{{ vehicle.make }} {{ vehicle.model }}</h6>
                  <p>{{ vehicle.variant }}</p>
                </div>

                <div class="text-sm sm:text-md w-full">
                  <ul class="px-0 space-y-3">
                    <li class="flex items-center leading-4">
                      <i class="fad fa-map-marker-alt mr-4"></i> {{ vehicle.dealer.city }}
                    </li>
                    <li class="flex items-center leading-4">
                      <i class="fad fa-store-alt mr-2"></i> {{ vehicle.dealer.seller_name }}
                    </li>
                    <li v-if="vehicle.distance" class="flex items-center leading-4">
                      <i class="fal fa-road mr-2"></i>
                      {{ vehicle.distance.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }}
                      mile(s)
                    </li>
                  </ul>
                </div>
              </div>
              <div class="flex items-center text-md text-gray-500">
                <span v-if="vehicle.miles !== null" class="summary mileage"
                  >{{ vehicle.miles.toLocaleString('en-GB') }} miles</span
                >
                <span v-if="vehicle.year !== null" class="w-px h-3 bg-gray-400 mx-1" />
                <span v-if="vehicle.year !== null" class="summary year">{{ vehicle.year }}</span>
                <span v-if="vehicle.transmission !== null" class="w-px h-3 bg-gray-400 mx-1" />
                <span v-if="vehicle.transmission !== null" class="summary transmission">{{
                  vehicle.transmission
                }}</span>
                <span v-if="vehicle.exterior_color !== null" class="w-px h-3 bg-gray-400 mx-1" />
                <span v-if="vehicle.exterior_color !== null" class="summary colour">{{
                  vehicle.exterior_color
                }}</span>
              </div>
            </div>

            <div
              v-if="vehicle.cheapest_quote !== null"
              class="absolute bottom-0 flex items-center text-sm sm:text-md w-full text-center mt-3 border-t-1 border-gray-200 py-2 px-4 bg-gray-100"
            >
              <div
                class="inline-flex w-10 h-10 bg-flow-teal dealer-bg-secondary text-white justify-center items-center rounded-full p-1.5 font-bold mr-3"
              >
                {{ vehicle.cheapest_quote.res_type }}
              </div>
              <span class="font-bold text-2xl mr-1">
                £{{ vehicle.cheapest_quote.res_monthly_payment }}
              </span>
              <span class="font-light text-lg"> /month </span>
              <button
                class="dealer-btn-primary normal-case btn-xs ml-auto"
                @click="viewCar(vehicle.quick_code)"
              >
                View
              </button>
            </div>
            <div
              v-else
              class="absolute bottom-0 flex items-center text-sm sm:text-md w-full text-center mt-3 border-t-1 border-gray-200 py-3 px-4 bg-gray-100"
            >
              <span class="font-light text-sm"> See monthly costs for this vehicle </span>
              <button
                class="dealer-btn-primary normal-case btn-xs ml-auto"
                @click="viewCar(vehicle.quick_code)"
              >
                View
              </button>
            </div>
          </div>
        </div>
      </section>

      <section
        v-else
        class="no-results col-span-2 md:grid grid-cols-1 sm:grid-cols-2 md:gap-4 relative"
      >
        <h3>Sorry, we couldn't find any vehicles matching that selection.</h3>
      </section>
    </template>

    <!-- Pagination -->
    <nav
      v-if="results !== null && results.data.length"
      aria-label="Pagination"
      class="col-span-3 py-3 flex items-center justify-between border-t mt-5 border-gray-200"
    >
      <div class="">
        <p class="text-sm text-gray-700">
          Page <span class="font-medium">{{ page }}</span> of
          <span class="font-medium">{{ results.last_page.toLocaleString('en-GB') }}</span> results
        </p>
      </div>

      <span class="relative z-0 inline-flex shadow-sm rounded-md justify-end">
        <button
          class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-md font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-flow-teal w-12 h-11 justify-center"
          type="button"
          @click="previousPage()"
        >
          <span class="sr-only">Previous</span>
          <i class="fas fa-angle-left"></i>
        </button>
        <button
          class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-md font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-flow-teal w-12 h-11 justify-center"
          type="button"
          @click="nextPage()"
        >
          <span class="sr-only">Next</span>
          <i class="fas fa-angle-right"></i>
        </button>
      </span>
    </nav>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import FilterSearch from '@/components/Ui/CarSearch/FilterSearch';
import VehicleFeed from '@/lib/VehicleFeed';
import RadioButtons from '@/components/Form/RadioButtons';
import FilterBadge from '@/components/Form/FilterBadge';
import Spinner from '@/components/Ui/Spinner';
import GoogleMaps from '@/lib/GoogleMaps';
import Api from '@/lib/Api';
const _ = require('lodash');

export default {
  components: {
    FilterSearch,
    ValidationProvider,
    ValidationObserver,
    RadioButtons,
    Spinner,
    FilterBadge,
  },

  data() {
    return {
      errors: {
        postcode: {
          notFound: false,
        },
      },
      googleMaps: new GoogleMaps(['geocoder']),
      loadingSearchResults: true,
      toggleFilter: false,
      showFilterSection: false,
      feed: new VehicleFeed(),
      activeFilters: {},
      filters: {
        make: null,
        model: null,
        min_price: null,
        max_price: null,
        sort: null,
        order: null,
        postcode: null,
        // city: null,
        body_type: null,
        exterior_color: null,
        doors: null,
        min_age: null,
        max_age: null,
        min_mileage: null,
        max_mileage: null,
        fuel_type: null,
        transmission: null,
        lat: null,
        lng: null,
        radius: null,
        payment: null,
        featured: null,
      },
      form: {
        make: { options: [{ label: 'All', value: null }] },
        model: { options: [] },
        radius: {
          value: null,
          options: [
            { value: 10, label: 'Within 10 miles' },
            { value: 25, label: 'Within 25 miles' },
            { value: 50, label: 'Within 50 miles' },
            { value: 75, label: 'Within 75 miles' },
            { value: 100, label: 'Within 100 miles' },
            { value: 150, label: 'Within 150 miles' },
            { value: null, label: 'Nationwide' },
          ],
        },
        body_type: { options: [{ label: 'All', value: null }] },
        exterior_color: { options: [{ label: 'All', value: null }] },
        doors: {
          options: [
            { label: 'All', value: null },
            { label: 2, value: 2 },
            { label: 3, value: 3 },
            { label: 4, value: 4 },
            { label: 5, value: 5 },
            { label: 6, value: 6 },
          ],
        },
        min_age: {
          options: [{ value: null, label: 'From' }],
        },
        max_age: {
          options: [{ value: null, label: 'To' }],
        },
        min_mileage: {
          options: [{ value: null, label: 'All' }],
        },
        max_mileage: {
          options: [{ value: null, label: 'All' }],
        },
        fuel_type: {
          options: [
            { value: null, label: 'All' },
            { value: 'petrol', label: 'Petrol' },
            { value: 'diesel', label: 'Diesel' },
            { value: 'electric', label: 'Electric' },
            { value: 'electric hybrid', label: 'Hybrid' },
            { value: 'diesel electric hybrid', label: 'Diesel hybrid' },
            { value: 'hydrogen/electric', label: 'Hydrogen hybrid' },
            { value: 'petrol electric hybrid', label: 'Petrol hybrid' },
            { value: 'bio fuel', label: 'Bio fuel' },
            { value: 'ethanol', label: 'Ethanol' },
            { value: 'gas', label: 'Gas' },
          ],
        },
        transmission: {
          options: [
            { value: null, label: 'All' },
            { value: 'manual', label: 'Manual' },
            { value: 'automatic', label: 'Automatic' },
            { value: 'semi-automatic', label: 'Semi automatic' },
            { value: 'cvt', label: 'CVT' },
            { value: 'dual clutch', label: 'Dual clutch' },
          ],
        },
        price: {
          min: {
            value: null,
            guide: 0,
          },
          max: {
            value: null,
            guide: 500000,
          },
        },
        payment_type: {
          options: [
            { label: 'Payment', value: 'payment' },
            { label: 'Cash', value: 'cash' },
          ],
          value: null,
          rules: 'required',
        },
        sort: {
          value: {
            column: 'price',
            order: 'asc',
          },
        },
        payment: {
          options: [
            { value: null, label: 'All' },
            { value: 'pcp', label: 'Monthly' },
          ],
        },
      },
      page: 1,
      results: null,
      loading: false,
      loadingBodyTypes: false,
      loadingExteriorColors: false,
      loadingMakes: false,
      loadingModels: false,
      loadingPostcode: false,
      sortCount: 1,
      currentOrder: 'value',
    };
  },

  watch: {
    'form.sort.value': function (newVal) {
      const shouldRefresh = this.sortCount > 1;
      this.sort(newVal.column, newVal.order, shouldRefresh);
      this.sortCount++;
    },
  },

  methods: {
    ...Api,

    orderedItems() {
      return _.orderBy(this.form.model.options, 'value', 'asc');
    },

    getFilterBadges() {
      let activeFilters = [];
      let excludeFilters = ['sort', 'order', 'lat', 'lng'];

      for (const [key, value] of Object.entries(this.filters)) {
        if (value !== null && !excludeFilters.includes(key)) {
          activeFilters.push({
            type: key,
            value: value,
          });
        }
      }
      return activeFilters;
    },

    getUrlSearchParams() {
      const query = this.$route.query;

      this.filters.make = query.make ?? null;
      this.filters.model = query.model ?? null;
      this.filters.min_price = query.min_price ?? null;
      this.filters.max_price = query.max_price ?? null;
      this.filters.sort = query.sort ?? 'price';
      this.filters.order = query.order ?? 'asc';
      this.filters.postcode = query.postcode ?? null;
      this.filters.body_type = query.body_type ?? null;
      this.filters.exterior_color = query.exterior_color ?? null;
      this.filters.doors = query.doors ?? null;
      this.filters.min_age = query.min_age ?? null;
      this.filters.max_age = query.max_age ?? null;
      this.filters.min_mileage = query.min_mileage ?? null;
      this.filters.max_mileage = query.max_mileage ?? null;
      this.filters.fuel_type = query.fuel_type ?? null;
      this.filters.transmission = query.transmission ?? null;
      this.filters.lat = query.lat ?? null;
      this.filters.lng = query.lng ?? null;
      this.filters.radius = query.radius ?? null;
      this.page = query.page ?? 1;
      this.filters.payment = query.payment ?? null;
      this.filters.featured = query.featured ?? null;

      // Update the sort drop down according to the URL
      if (this.filters.sort && this.filters.order) {
        this.form.sort.value = {
          column: this.filters.sort,
          order: this.filters.order,
        };
      }
    },

    setUrlSearchParams() {
      this.activeFilters = this.getFilterBadges(this.filters);

      this.$router
        .replace({
          query: {
            make: this.filters.make,
            model: this.filters.model,
            min_price: this.filters.min_price,
            max_price: this.filters.max_price,
            sort: this.filters.sort,
            order: this.filters.order,
            postcode: this.filters.postcode,
            body_type: this.filters.body_type,
            exterior_color: this.filters.exterior_color,
            doors: this.filters.doors,
            min_age: this.filters.min_age,
            max_age: this.filters.max_age,
            min_mileage: this.filters.min_mileage,
            max_mileage: this.filters.max_mileage,
            fuel_type: this.filters.fuel_type,
            transmission: this.filters.transmission,
            lat: this.filters.lat,
            lng: this.filters.lng,
            radius: this.filters.radius,
            page: this.page,
            payment: this.filters.payment,
            featured: this.filters.featured,
          },
        })
        .catch((err) => {
          // Catching errors here or else Vue router complains about duplicate navigation.
        });
    },

    async search() {
      this.toggleFilter = false;
      this.loadingSearchResults = true;
      this.setUrlSearchParams();
      this.results = await this.feed.searchVehicles(this.page, this.filters);
      this.loadingSearchResults = false;
    },

    async getAllMakes() {
      this.loadingMakes = true;
      const makes = await this.feed.getMakes();

      if (makes.length) {
        makes.forEach((make) => {
          this.form.make.options.push({
            label: `${make.make} (${make.count.toLocaleString('en-GB')})`,
            value: make.make,
          });
        });
      }
      this.loadingMakes = false;
    },

    nextPage() {
      if (this.page < this.results.last_page) {
        this.page++;
        this.setUrlSearchParams();
        this.search();
      }
    },

    previousPage() {
      if (this.page > 1) {
        this.page--;
        this.setUrlSearchParams();
        this.search();
      }
    },

    // When a 'sort' option is selected in the filters component
    sort(column, order, shouldRefresh = false) {
      // Set the column to order by and the order
      this.filters.sort = column;
      this.filters.order = order;

      // Update the URL
      this.setUrlSearchParams();

      if (shouldRefresh) {
        // Update the search results
        this.search();
      }
    },

    resetFilters(shouldRefreshResults = true) {
      for (const [key] of Object.entries(this.filters)) {
        this.filters[key] = null;
      }

      // Reset the sort col and order
      this.resetOrder();

      this.setUrlSearchParams();

      if (shouldRefreshResults) {
        this.search();
      }
    },

    resetFilter(filter) {
      if (filter === 'postcode') {
        this.filters.radius = null;
        this.filters.lat = null;
        this.filters.lng = null;
      }
      this.filters[filter] = null;
      this.search();
    },

    resetOrder() {
      this.form.sort.value = { column: 'price', order: 'asc' };
      this.filters.sort = 'price';
      this.filters.order = 'asc';
    },

    async getFilters() {
      this.loadingFilters = true;
      const response = await this.feed.getFilters();

      if (response.body_type.length) {
        response.body_type.options = [{ value: null, label: 'All' }];
        response.body_type.forEach((body_type) => {
          this.form.body_type.options.push({
            value: body_type,
            label: body_type,
          });
        });
      }
      if (response.exterior_color.length) {
        response.exterior_color.options = [{ value: null, label: 'All' }];
        response.exterior_color.forEach((exterior_color) => {
          this.form.exterior_color.options.push({
            value: exterior_color,
            label: exterior_color,
          });
        });
      }
      this.loadingFilters = false;
    },

    async onMakeChange() {
      // Reset the model's dropdown while we load
      this.loadingModels = true;

      // Reset the model
      this.filters.model = null;

      // Fetch the related models for this make
      const models = await this.feed.getModels(this.filters.make);

      if (models.length) {
        // Reset the models drop down
        // this.form.model.options = [{label: 'All', value: null}]

        models.forEach((model) => {
          this.form.model.options.push({
            label: `${model.model} (${model.count.toLocaleString('en-GB')})`,
            value: model.model,
          });
        });

        if (this.$route.query.model) {
          this.filters.model = this.$route.query.model;
        }
      }

      this.loadingModels = false;
    },

    getAgeOptions() {
      const currentYear = new Date().getFullYear();
      const maxAge = 14;

      for (let i = 0; i <= maxAge; i++) {
        let yearOption = currentYear - i;
        this.form.min_age.options.push({ label: yearOption, value: yearOption });
        this.form.max_age.options.push({ label: yearOption, value: yearOption });
      }
    },

    hasUserLocation() {
      return this.filters.lat !== null && this.filters.lng !== null;
    },

    async getPostcodeCoords() {
      if (!this.filters.postcode) {
        this.filters.radius = null;
        this.filters.lat = null;
        this.filters.lng = null;
        return;
      }

      this.errors.postcode.notFound = false;
      this.loadingPostcode = true;
      try {
        const response = await this.googleMaps.getPostcodeCoords(this.filters.postcode);

        if (response.results.length) {
          this.filters.lat = response.results[0].geometry.location.lat();
          this.filters.lng = response.results[0].geometry.location.lng();
        }
      } catch (e) {
        this.filters.postcode = null;
        this.filters.lat = null;
        this.filters.lng = null;
        this.filters.radius = null;
        this.errors.postcode.notFound = true;
      }
      this.loadingPostcode = false;
    },

    // Change url if car is on the dashboard or web
    viewCar(quickCode) {
      if (this.$route.meta.requiresAuth === false) {
        return this.$router.push({
          name: 'car__search_view',
          params: { vehicleId: quickCode },
          query: this.$route.query,
        });
      }

      return this.$router.push({
        name: 'dashboard__search__view',
        params: { vehicleId: quickCode },
        query: this.$route.query,
      });
    },
  },

  async mounted() {
    // Assign URL params to the filter properties
    this.getUrlSearchParams();

    // Get the search results
    await this.search();

    // Get all vehicle makes (filter)
    this.getAllMakes();

    // Get filter options (based on make and model if specified)
    this.getFilters();

    if (this.filters.make !== null) {
      this.onMakeChange();
    }

    // Populate the 'age' drop down options
    this.getAgeOptions();

    if (this.filters.payment === 'pcp') {
      if (this.filters.min_price === null) {
        this.filters.min_price = 7059;
      }
      if (this.filters.max_mileage === null) {
        this.filters.max_mileage = 70000;
      }
      if (this.filters.max_age === null) {
        this.filters.max_age = 2018;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.filter-menu {
  @media (min-width: 1025px) {
    display: block !important;
  }

  .field {
    input,
    select {
      @apply shadow-none text-base;
    }

    @apply mt-0 mb-3;
  }

  @apply mb-6 lg:mb-0;
}

.summary {
  display: inline-block;
}

:disabled {
  opacity: 0.35;
}

.loader {
  height: 100%;
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  background: rgba(243, 244, 246, 0.85);
  padding-top: 90px;
}
.vehicle-container {
  min-height: 390px;

  .vehicle-details {
    min-height: 185px;
  }
}

.car {
  &__body {
    animation: shake 0.2s ease-in-out infinite alternate;
  }

  &__line {
    transform-origin: right center;
    stroke-dasharray: 22;
    animation: line 0.8s ease-in-out infinite;
    animation-fill-mode: both;

    &--top {
      animation-delay: 0s;
    }

    &--middle {
      animation-delay: 0.2s;
    }

    &--bottom {
      animation-delay: 0.4s;
    }
  }
}

@keyframes shake {
  0% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(3%);
  }
}

@keyframes line {
  0% {
    stroke-dashoffset: 22;
  }

  25% {
    stroke-dashoffset: 22;
  }

  50% {
    stroke-dashoffset: 0;
  }

  51% {
    stroke-dashoffset: 0;
  }

  80% {
    stroke-dashoffset: -22;
  }

  100% {
    stroke-dashoffset: -22;
  }
}

.filter-search-button-container {
  box-shadow: 0 -6px 15px rgba(0, 0, 0, 0.2);
}

.app-type-label {
  @apply bg-flow-blue text-white absolute;

  font-weight: 700;
  padding: 3px 24px 0;
  transform: rotate(-45deg);
  left: -28px;
  top: 28px;
  text-transform: uppercase;
  font-size: 11px;
  width: 130px;
  text-align: center;
}
</style>
