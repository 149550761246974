import { render, staticRenderFns } from "./Spinner.vue?vue&type=template&id=33b2ecae&scoped=true"
import script from "./Spinner.vue?vue&type=script&lang=js"
export * from "./Spinner.vue?vue&type=script&lang=js"
import style0 from "./Spinner.vue?vue&type=style&index=0&id=33b2ecae&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33b2ecae",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/bitnami/apache2/htdocs/flowcarfinance/tmp-dealer/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('33b2ecae')) {
      api.createRecord('33b2ecae', component.options)
    } else {
      api.reload('33b2ecae', component.options)
    }
    module.hot.accept("./Spinner.vue?vue&type=template&id=33b2ecae&scoped=true", function () {
      api.rerender('33b2ecae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Ui/Spinner.vue"
export default component.exports