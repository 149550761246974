<template>
  <div class="progress-component">
    <div class="progress-outer">
      <div class="progress-bar" :style="'width:' + barWidth + '%'"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: {
    progress: {
      type: Number,
      default: 0,
    },

    total: {
      type: Number,
      default: 100,
    },
  },

  computed: {
    barWidth() {
      return (this.progress / this.total) * 100;
    },
  },
};
</script>

<style scoped>
.progress-component {
  @apply bg-gray-200 w-full;
  height: 5px;
}

.progress-outer {
  @apply w-full h-full;
}

.progress-bar {
  @apply bg-lime-500 h-full;

  transition: width 100ms ease-in-out;
}
</style>
