<template>
  <div>
    <modal
      name="infoModal"
      styles="border-radius:1rem;"
      classes=""
      height="auto"
      :max-width="500"
      width="90%"
      :adaptive="true"
    >
      <div class="md:p-4 text-white info-modal bg-flow-blue dealer-bg-secondary">
        <div class="relative p-6 flex-auto">
          <h3 class="leading-relaxed text-white">
            {{ steps[activeStep].title }}
          </h3>

          <div v-for="(content, index) in steps[activeStep].contents" :key="'content' + index">
            <div
              v-if="content.icon"
              class="mt-5 flex items-center justify-center h-32 w-32 rounded-md bg-white text-white"
            >
              <div class="w-full">
                <div class="icon w-full text-center">
                  <font-awesome-icon
                    :icon="['fas', content.icon]"
                    size="3x"
                    class="mt-2 mb-2 text-black"
                  />
                </div>
                <div class="leading-5 space-y-1 text-center h-8 mt-1">
                  <h4 class="uppercase text-xs xs:text-sm font-bold" style="font-size: 0.75rem">
                    {{ steps[activeStep].iconTitle }}
                  </h4>
                </div>
              </div>
            </div>
            <p class="mt-4 text-lg leading-relaxed" v-html="content.text"></p>
            <div
              v-if="steps[activeStep].buttons && steps[activeStep].buttons.length"
              class="actions"
            >
              <router-link
                class="mt-4 text-white border-white inline-block"
                v-for="(button, i) in steps[activeStep].buttons"
                :key="i"
                :to="button.route"
                >{{ button.label }}</router-link
              >
            </div>
          </div>
        </div>

        <!--footer-->
        <div class="flex pb-6 pt-3 px-6 rounded-b">
          <button
            @click="close()"
            class="close-btn flex items-center justify-center h-12 w-12 rounded-full hover:dealer-bg-secondary hover:text-white border-2 border-white text-white"
            type="button"
            style="transition: all 0.15s ease"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="times"
              class="svg-inline--fa fa-times fa-w-11"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 352 512"
            >
              <path
                fill="currentColor"
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
              ></path>
            </svg>
          </button>

          <span class="relative z-0 inline-flex shadow-sm rounded-md ml-auto fa-2x">
            <button
              @click="activeStep--"
              v-if="activeStep > 0"
              type="button"
              class="flex items-center justify-center h-12 w-12 rounded-full border-2 border-white text-white hover:dealer-bg-primary hover:text-white"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="angle-left"
                class="svg-inline--fa fa-angle-left fa-w-8"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 256 512"
              >
                <path
                  fill="currentColor"
                  d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
                ></path>
              </svg>
            </button>

            <button
              @click="activeStep++"
              v-if="activeStep < steps.length - 1"
              type="button"
              class="ml-5 flex items-center justify-center h-12 w-12 rounded-full border-2 border-white text-white hover:dealer-bg-primary hover:text-white"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="angle-right"
                class="svg-inline--fa fa-angle-right fa-w-8"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 256 512"
              >
                <path
                  fill="currentColor"
                  d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                ></path>
              </svg>
            </button>
          </span>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: mapState(['dealer']),
  name: 'Guide',
  data() {
    return {
      activeStep: 0,
      steps: [
        {
          title: 'Welcome to your Flow Car Finance dashboard',
          contents: [
            {
              text: '<p>Let us give you a quick tutorial on using the dashboard to help process your application.</p>',
            },
            { text: '<p>Alternatively, message us directly using the chat button</p>' },
          ],
        },
        {
          title: 'Completing your application',
          contents: [
            {
              text: "<p class='mb-4'>All that's left to do now is choose a vehicle and a quote you're happy with. We'll do all of the leg work and keep you informed every step of the way.</p>",
            },
          ],
          buttons: [{ route: 'calculator', label: 'Add a car' }],
        },
        {
          title: 'Your credit score',
          iconTitle: 'Credit score',
          contents: [
            { text: '<p>This is where you can view your personalised credit report</p>' },
            { icon: 'chart-pie' },
            {
              text: "<p>With this, we'll estimate the APR you'll likely to be offered along with how your score is affected by your report.</p>",
            },
          ],
        },
      ],
      // isGuideShowing: this.$cookies.get('infoModal') !== 'false'
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
  mounted() {
    if (this.dealer.name) {
      this.steps[0].title = `Welcome to your ${this.dealer.name} dashboard`;
    }
  },
};
</script>
<style lang="scss" scoped>
.vm--modal {
  width: 100px !important;
}
</style>
