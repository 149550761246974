<template>
  <ValidationProvider
    :mode="validationMode"
    :rules="rules"
    v-slot="{ errors, valid }"
    :name="veeName ? _uid + '_' + veeName : _uid + '_' + name"
    ref="validator"
  >
    <div class="mt-1 flex rounded-md">
      <div class="relative flex items-stretch flex-grow focus-within:z-10 text-input-button">
        <input
          @change="onChange"
          v-model="localValue"
          :autocomplete="autocomplete"
          :tabindex="tabindex"
          :id="id"
          :name="name"
          class="h-12 m:leading-6 text-lg form-input-button"
          :class="{ 'border-red-300': errors.length, uppercase: isUppercase }"
          :placeholder="placeholder"
        />

        <button
          @click="clickHandler()"
          class="rounded-md bg-flow-blue dealer-bg-secondary -ml-px relative inline-flex items-center space-x-2 px-8 py-2 text-md font-bold focus:outline-none transition duration-150 ease-in-out uppercase"
          :class="{
            'opacity-50 cursor-not-allowed pointer-events-none': isDisabled,
            'switch focus:outline-none focus:border-flow-blue focus:shadow-outline-flow-teal active:bg-tf-flow-teal':
              isSwitch,
            '': !isSwitch,
            'switch-on bg-tf-flow-teal text-white': selected && isSwitch,
            'switch-off bg-white dealer-text-primary border-tf-flow-teal': !selected && isSwitch,
          }"
          :disabled="isDisabled"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="mr-2 h-4 w-4"
            fill="#ffffff"
          >
            <path
              d="M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1l124 124 17 17L478.1 512l-17-17-124-124z"
            />
          </svg>
          {{ valueButton }}
        </button>
      </div>
    </div>

    <p class="error-message" v-if="errors && errors.length">{{ errors[0] }}</p>
    <p v-else-if="hint" class="input-hint mt-2 text-sm text-gray-500">{{ hint }}</p>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'TextInput',

  components: {
    ValidationProvider,
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
    errors() {
      return this.$refs.validator.errors;
    },
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    veeName: {
      type: String,
      default: '',
    },
    tabindex: {
      type: Number,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    placeholder: {
      type: String,
      default: null,
    },
    isUppercase: {
      type: Boolean,
      default: false,
    },
    validationMode: {
      type: String,
      default: 'lazy',
    },

    valueButton: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isSwitch: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onChange() {
      this.$emit('input', this.value);
    },

    reset() {
      this.localValue = null;
    },

    getValidator() {
      return this.$refs.validator;
    },

    clickHandler() {
      if (!this.isDisabled) {
        this.$emit('click');
      }
    },
  },
};
</script>
