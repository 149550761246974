<template>
  <ValidationProvider
    :mode="validationMode"
    :rules="rules"
    v-slot="{ errors, valid }"
    :name="veeName ? _uid + '_' + veeName : _uid + '_' + name"
    ref="validator"
  >
    <div class="mt-1 flex form-input-icon">
      <span class="sm:font-semibold inline-flex items-center px-4 text-gray-500 text-lg">
        <i :class="icon"></i>
      </span>
      <input
        @change="onChange"
        v-model="localValue"
        :autocomplete="autocomplete"
        :tabindex="tabindex"
        :id="id"
        :name="name"
        class="form-input border-none sm:leading-5 mt-0"
        :class="{
          'border-red-300': errors.length,
          uppercase: isUppercase,
        }"
        :placeholder="placeholder"
      />
    </div>

    <div class="error-message" v-if="errors && errors.length">{{ errors[0] }}</div>
    <p v-else-if="hint" class="input-hint mt-2 text-sm text-gray-500">{{ hint }}</p>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'TextInputIcon',

  components: {
    ValidationProvider,
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
    errors() {
      return this.$refs.validator.errors;
    },
  },

  props: {
    isNumber: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    value: {
      type: String | Number,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
    veeName: {
      type: String,
      default: '',
    },
    tabindex: {
      type: Number,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    placeholder: {
      type: String,
      default: null,
    },
    isUppercase: {
      type: Boolean,
      default: false,
    },
    validationMode: {
      type: String,
      default: 'lazy',
    },
    icon: {
      type: String,
      default: null,
    },
  },

  methods: {
    onChange() {
      this.$emit('input', this.value);
      this.$emit('change');
    },

    reset() {
      this.localValue = null;
    },

    getValidator() {
      return this.$refs.validator;
    },
  },
};
</script>
