<template>
  <section class="applications">
    <vue-headful
      description="At Flow car Finance we think car finance should be fair, simple & sincere. With over 30 years of knowledge, expertise and listening to you, we came up with an effortless car, van or motorcycle buying experience."
      url="https://flowcarfinance.co.uk/applications"
    />

    <section id="hero" class="hero bg-flow-blue dealer-bg-banner w-full relative">
      <div class="max-w-7xl mx-auto text-white py-16 px-4 sm:px-6 xl:px-0 w-full">
        <h1 class="font-title relative text-3xl text-white">Welcome back {{ firstName }}</h1>
        <h4 class="mt-4 font-title relative text-2xl text-white font-light">
          Would you like to start a new application or check up on an existing one?
        </h4>
        <div class="mt-8">
          <span class="flex justify-start">
            <button
              @click="createNewApplication"
              class="button border-2 border-flow-teal dealer-btn-primary-outer text-flow-blue bg-flow-teal hover:bg-flow-blue hover:text-white"
            >
              New application
            </button>
          </span>
        </div>
      </div>
    </section>

    <main class="bg-gray-100">
      <div class="max-w-7xl mx-auto text-white py-16 px-4 sm:px-6 xl:px-0 w-full">
        <h3 class="text-flow-blue dealer-text-secondary">Existing Applications</h3>

        <div v-if="loading" class="flex items-center justify-center z-30">
          <div
            class="spinner rounded-full w-10 h-10 spinner--2"
            style="border: 2px solid #7ac5b6; border-top-color: transparent"
          ></div>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div
            @click="viewApplication(application.reference)"
            v-for="(application, i) in applications"
            :key="i"
            class="rounded-md bg-white text-flow-blue dealer-text-secondary shadow-md cursor-pointer flex h-36 min-h-full"
          >
            <div
              class="uppercase font-semibold flex items-center justify-center bg-flow-teal dealer-bg-secondary hover:bg-opacity-90 text-white h-full rounded-l-md text-center px-2 sm:px-5 text-sm md:text-lg flex-col"
            >
              <span>View</span> <span class="hidden md:block ml-2"> Application</span>
            </div>
            <div class="px-4 py-4 md:px-8 md:py-6 col-span-3 sm:col-span-2 w-full overflow-hidden">
              <div class="flex-row space-y-2 md:space-y-2">
                <div class="inline-flex justify-between w-full space-x-3 items-center">
                  <div class="text-xl flex">
                    Ref<span class="hidden sm:block">erence</span>:
                    <strong class="ml-2">{{ application.reference }}</strong>
                  </div>
                  <div class="h-5 text-xs text-gray-800 truncate flex items-center space-x-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                      />
                    </svg>
                    <span>{{ application.created_at | moment('Do MMM YYYY') }}</span>
                  </div>
                </div>

                <div
                  v-if="
                    application.display_vehicle != null &&
                    application.display_vehicle.source != 'dummy'
                  "
                  class="text-xl truncate w-10/12"
                >
                  <strong>{{ application.display_vehicle.make }}</strong
                  >, {{ application.display_vehicle.model }},
                  {{ application.display_vehicle.derivative }}
                </div>
                <div v-else class="text-xl truncate">No quotes on vehicles</div>
              </div>
              <div
                class="flex space-x-2 mt-3"
                v-if="application.latest_status != null && application.has_applied_with_real_quote"
              >
                <div
                  class="text-xs lg:text-sm bg-flow-blue rounded-full py-0.5 px-4 text-white shadow-md"
                >
                  {{ application.latest_status.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
import { mapState } from 'vuex/dist/vuex.esm.browser';
import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import Spinner from '@/components/Ui/Spinner';

export default {
  name: 'Applications',

  components: {
    Spinner,
  },

  computed: mapState(['dashboard', 'dealer']),

  data() {
    return {
      applications: null,
      firstName: this.$store.state.dashboard.user.name,
      loading: false,
    };
  },

  methods: {
    /**
     * Get all applications
     * @returns {Promise<AxiosResponse<any>>}
     */
    async getApplications() {
      return axios
        .get(this.getApiBaseUrl() + '/me/all/applications')
        .then((r) => {
          if (r.data.success) {
            return r.data.data.applications;
          }
          throw new Error(r.data.errors);
        })
        .catch((e) => {
          console.error('Failed retrieving applications', e);
        });
    },

    async viewApplication(reference) {
      return await this.$router.push({
        name: 'application-dashboard',
        params: { reference: reference },
      });
    },

    /**
     * Create a new application and applicant based on their last previous one
     */
    createNewApplication() {
      this.$emit('loading', true, 'Creating application');
      axios
        .get(`${this.getApiBaseUrl()}/me/applications/duplicate-latest`)
        .then(async (r) => {
          if (r.data.success && r.data.data.application && r.data.data.applicant) {
            await this.$store.commit('setDashboardApplication', r.data.data.application);
            await this.$store.commit('setDashboardApplicant', r.data.data.applicant);
            this.$emit('loading', false);
            return await this.$router.push({ name: 'applications__review' });
          }
          throw new Error(
            "Failed to create new application and applicant but the API didn't throw an exception."
          );
        })
        .catch((e) => {
          this.$emit('loading', false);
          Bugsnag.notify(e);
          alert(
            'Sorry, something went wrong creating your new application. Please get in touch if this problem persists.'
          );
        });
    },
  },
  async mounted() {
    this.loading = true;
    this.applications = await this.getApplications();
    this.loading = false;
  },
};
</script>
