<template>
  <div v-if="cards.length" class="align-center grid grid-cols-2 md:grid-cols-3 gap-6 mt-8">
    <!-- Cards -->
    <template v-for="(card, i) in cards">
      <div
        v-if="card.visible"
        :key="i"
        class="cards relative w-full md:h-44 md:w-44 lg:h-46 lg:w-46 xl:h-48 xl:w-48 mx-auto sm:mx-0"
        :class="{ 'opacity-25 cursor-default ': !card.active }"
      >
        <!-- Cards -->
        <div
          @click="navigateToCard(card)"
          :id="card.id"
          class="card bg-flow-teal dealer-bg-cards hover:opacity-80"
        >
          <div class="w-full">
            <template v-if="card.id === 'messages' && dashboard.messages.unread > 0">
              <div class="unread-counter flex justify-center items-center">
                {{ dashboard.messages.unread }}
              </div>
            </template>
            <div :class="classIcon" class="h-20 w-full flex justify-center items-center">
              <i class="fal -mt-3 w-full text-4xl lg:text-5xl" :class="'fa-' + card.iconName"></i>
            </div>
            <div class="leading-5 space-y-1 text-center h-8">
              <h4 class="uppercase text-xs xs:text-sm font-bold" :class="classTitle">
                {{ card.title }}
              </h4>
            </div>
            <div v-if="card.cardText" :class="classText">
              <span class="mx-auto">{{ card.cardText }}</span>
            </div>
            <div v-if="card.iconlabel" :class="classIconLabel">
              <span class="mx-auto">{{ card.iconlabel }}</span>
            </div>
            <div v-if="card.iconCheck" :class="classIconCheck">
              <span class="mx-auto">
                <i class="fas fa-check"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!--Call Us Nav card (Desktop)-->
    <div
      @click="show('callModal')"
      class="hidden md:block cards relative w-full md:h-44 md:w-44 lg:h-46 lg:w-46 xl:h-48 xl:w-48 mx-auto sm:mx-0"
    >
      <div class="card bg-flow-teal dealer-bg-cards hover:opacity-80">
        <div class="w-full">
          <div :class="classIcon" class="h-20 w-full flex justify-center items-center">
            <i class="fal fa-mobile -mt-3 w-full text-4xl lg:text-5xl"></i>
          </div>
          <div class="leading-5 space-y-1 text-center h-8">
            <h4 class="uppercase text-xs xs:text-sm font-bold" :class="classTitle">Call Us</h4>
          </div>
        </div>
      </div>
    </div>

    <!--Call Us Nav card (Mobile)-->
    <a
      :href="dealer.phone ? 'tel:' + dealer.phone : 'tel:01614063994'"
      title=""
      class="block md:hidden cards relative w-full md:h-44 md:w-44 lg:h-46 lg:w-46 xl:h-48 xl:w-48 mx-auto sm:mx-0"
    >
      <div class="card bg-flow-teal dealer-bg-cards hover:opacity-80">
        <div class="w-full">
          <div :class="classIcon" class="h-20 w-full flex justify-center items-center">
            <i class="fal fa-mobile -mt-3 w-full text-4xl lg:text-5xl"></i>
          </div>
          <div class="leading-5 space-y-1 text-center h-8">
            <h4 class="uppercase text-xs xs:text-sm font-bold" :class="classTitle">Call Us</h4>
          </div>
        </div>
      </div>
    </a>

    <modal
      name="callModal"
      id="callModal"
      styles="border-radius:1rem;"
      classes="modal"
      height="auto"
      width="300px"
      :scrollable="true"
    >
      <div
        class="bg-flow-blue text-white dealer-bg-secondary p-6 sm:p-8 text-white leading-5 text-center"
      >
        <div class="flex flex-row mb-3">
          <svg
            @click="hide()"
            class="w-6 h-6 ml-auto cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
            fill="#fff"
          >
            <path
              d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
            />
          </svg>
        </div>
        <div class="mb-5 flex justify-center items-center">
          <svg
            class="w-5 h-auto text-white mr-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
            fill="#fff"
          >
            <path
              d="M192 416c0 17.7-14.3 32-32 32s-32-14.3-32-32 14.3-32 32-32 32 14.3 32 32zM320 48v416c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h224c26.5 0 48 21.5 48 48zm-32 0c0-8.8-7.2-16-16-16H48c-8.8 0-16 7.2-16 16v416c0 8.8 7.2 16 16 16h224c8.8 0 16-7.2 16-16V48z"
            />
          </svg>
          <h2 v-if="dealer.phone" class="text-white mb-0">{{ dealer.phone }}</h2>
          <h2 v-else class="text-white mb-0">0161 4063994</h2>
        </div>
        <div class="pb-5 pt-3">
          <a
            v-if="dealer.phone"
            :href="`tel:${dealer.phone}`"
            title=""
            class="dealer-btn-primary-outer mb-4 mr-0 sm:mb-0 sm:mr-4"
            >Call Us</a
          >
          <a
            v-else
            href="tel:01614063994"
            title="01614063994"
            class="mr-3 lg:px-3 xl:px-4 dealer-btn-primary"
            target="_blank"
            >Call Us</a
          >
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Button from '@/components/Form/Button';

export default {
  components: { Button },
  data() {
    return {
      cards: [],
      classText:
        'action-label absolute right-0 align-middle text-center w-8 h-8 inline-flex items-center px-0 py-0.5 rounded-full text-sm font-medium leading-5 bg-red-600 text-white shadow-md border-2 sm:border-3 border-white',
      classIconCheck:
        'action-label absolute right-0 align-middle text-center w-8 h-8 inline-flex items-center px-1 py-0.1 sm:px-1.2 sm:py-0.5 rounded-full text-sm font-medium leading-5 dealer-bg-secondary text-white shadow-md border-3 border-white',
      classIconLabel:
        'action-label absolute right-0 align-middle text-center w-8 h-8 inline-flex items-center px-0 py-0.5 rounded-full text-sm font-medium leading-5 bg-red-600 text-white shadow-md border-2 sm:border-3 border-white',
      classIcon: 'text-white text-center',
      classTitle: 'text-white',
    };
  },

  computed: mapState(['dashboard', 'dealer']),

  methods: {
    async navigateToCard(card) {
      if (card.route) {
        return await this.$router.push({ name: card.route, params: card.params });
      }

      if (card.extUrl) {
        return (window.location = card.extUrl);
      }

      if (card.modalName) {
        this.$modal.show(card.modalName);
      }
    },

    show(name) {
      this.$modal.show(name);
    },

    hide() {
      this.$modal.hide('callModal');
    },

    instantiateCards() {
      this.cards = [
        {
          route: 'credit-score',
          params: {
            reference: this.$store.state.dashboard.application.reference,
          },
          iconName: 'analytics',
          iconType: 'fal',
          iconSize: '',
          title: 'Credit Scores',
          label: '',
          iconCheck: false,
          extUrl: '',
          modalName: '',
          modalTitle: '',
          modalText: '',
          active: true,
          visible: true,
        },
        {
          route: 'dashboard__calculator__add-vehicle-reg',
          params: {},
          iconName: 'barcode-alt',
          iconType: 'fal',
          iconSize: '',
          title: 'Reg Lookup',
          label: '',
          iconCheck: false,
          extUrl: '',
          modalName: '',
          modalTitle: '',
          modalText: '',
          active: true,
          visible: true,
        },
        {
          route: 'search',
          params: {
            reference:
              this.$route.params.reference || this.$store.state.dashboard.application.reference,
          },
          iconName: 'cars',
          iconType: 'fal',
          iconSize: '',
          title: 'Browse Vehicles',
          label: '',
          iconCheck: false,
          extUrl: '',
          modalName: '',
          modalTitle: '',
          modalText: '',
          active: true,
          visible: this.shouldDisplayFeed(),
        },
        {
          route: 'dashboard__calculator__add-vehicle-manually',
          params: {},
          iconName: 'ballot-check',
          iconType: 'fal',
          iconSize: '',
          title: 'Add by make/model',
          label: '',
          iconCheck: false,
          extUrl: '',
          modalName: '',
          modalTitle: '',
          modalText: '',
          active: true,
          visible: true,
        },
        {
          route: 'dashboard__calculator__quotes',
          params: {},
          iconName: 'car-garage',
          iconType: 'fal',
          iconSize: '2x',
          title: 'Saved Vehicle(s)',
          label: '',
          iconCheck: false,
          extUrl: '',
          modalName: '',
          modalTitle: '',
          modalText: '',
          active: true,
          visible: true,
          id: 'calc',
        },
        {
          route: 'dashboard__terms',
          params: {},
          iconName: 'file-certificate',
          iconType: 'fal',
          iconSize: '2x',
          title: 'View Terms',
          label: '',
          iconCheck: false,
          extUrl: '',
          modalName: '',
          modalTitle: '',
          modalText: '',
          active: true,
          visible: true,
          id: 'terms',
        },
        {
          route: 'dashboard__messages',
          params: {},
          iconName: 'comment-alt-dots',
          iconType: 'fal',
          iconSize: '2x',
          title: 'Messages',
          label: '',
          iconCheck: false,
          extUrl: '',
          modalName: '',
          modalTitle: '',
          modalText: '',
          active: true,
          visible: true,
          id: 'messages',
        },
        {
          route: 'dashboard__faqs',
          params: {},
          iconName: 'question-circle',
          iconType: 'fal',
          iconSize: '2x',
          title: "Faq's",
          label: '',
          iconCheck: false,
          extUrl: '',
          modalName: '',
          modalTitle: '',
          modalText: '',
          active: true,
          visible: true,
          id: 'faqs',
        },
      ];
    },
  },
  mounted() {
    this.instantiateCards();

    if (this.dealer.name) {
      this.cards.map((card) => {
        // Hide messages for dealers
        if (card.route === 'dashboard__messages') {
          card.visible = false;
        }

        // Hide terms if custom ones weren't provided
        if (!this.dealer.acceptance_terms && card.route === 'dashboard__terms') {
          card.visible = false;
        }

        // Hide FAQs if custom ones weren't provided
        if (!this.dealer.faqs && card.route === 'dashboard__faqs') {
          card.visible = false;
        }
        return card;
      });
    }
  },
};
</script>

<style lang="scss">
.unread-counter {
  background: red;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  color: white;
  position: absolute;
  text-align: center;
  font-weight: bold;
  top: -5px;
  right: -5px;
}
</style>
