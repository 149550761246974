<template>
  <section class="view-quote">
    <h1>Your quote</h1>
    <div v-if="loading">
      <h2>Loading...</h2>
    </div>
    <div v-else>
      <ApplyObserver v-slot:default="scope">
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <QuoteCard
            @apply="scope.apply"
            v-if="quoteResults.hp"
            type="hp"
            :vehicle="quoteVehicle"
            :figures="quoteFigures"
            :result="quoteResults.hp"
          />
          <QuoteCard
            @apply="scope.apply"
            v-if="quoteResults.pcp"
            type="pcp"
            :vehicle="quoteVehicle"
            :figures="quoteFigures"
            :result="quoteResults.pcp"
          />
        </div>
      </ApplyObserver>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import QuoteCard from '@/components/Ui/Calculator/QuoteCard';
import ApplyObserver from '@/components/Dashboard/CarSearch/ApplyObserver';

export default {
  components: {
    QuoteCard,
    ApplyObserver,
  },

  data() {
    return {
      loading: false,
      quote: null,
      quoteResults: {
        hp: null,
        pcp: null,
      },
      quoteFigures: null,
      quoteVehicle: null,
    };
  },

  methods: {
    getQuote(quoteId) {
      this.loading = true;
      return axios
        .get(`${this.getApiBaseUrl()}/v1/quotes/${quoteId}.json`)
        .then((r) => {
          if (r.data.success && r.data.data.quote) {
            return r.data.data.quote;
          }
          return null;
        })
        .catch((e) => {
          console.error('There was a problem finding the quote!', e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    prepareQuoteFigures(figures, vehicle) {
      return {
        CashPrice: figures.cash_price / 100,
        OutstandingSettlement: figures.outstanding_settlement / 100,
        DepositCash: figures.deposit / 100,
        PartExchange: figures.part_exchange / 100,
        estimated_mileage: figures.annual_mileage ?? null,
        current_mileage: vehicle.mileage ?? null,
      };
    },
  },

  async mounted() {
    this.quote = await this.getQuote(this.$route.params.quoteId);

    // Organise the quote results
    this.quote.quote_results.forEach((result) => {
      if (result.type === 'HP') {
        this.quoteResults.hp = result;
      }
      if (result.type === 'PCP') {
        this.quoteResults.pcp = result;
      }
    });

    this.quoteFigures = this.prepareQuoteFigures(this.quote.quote_figures, this.quote.vehicle);

    this.quoteVehicle = this.quote.vehicle;
  },
};
</script>
