<template>
  <ValidationProvider
    v-if="rules"
    mode="eager"
    :rules="rules"
    :name="'radio-buttons' + _uid"
    ref="validator"
    v-slot="{ errors }"
  >
    <div
      class="radio-buttons grid mt-4"
      :class="'grid-cols-' + cols + ' ' + 'gap-' + gap + ' ' + classes"
    >
      <div
        @click="select(option, $event)"
        v-for="(option, i) in options"
        class="rounded-md"
        :key="i"
      >
        <button
          type="button"
          :data-option="option.label.trim().toLowerCase()"
          class="dealer-radio-buttons sm:py-3 sm:whitespace-no-wrap focus:outline-none active:outline-none justify-center w-full block items-center px-4 py-2 sm:text-lg leading-normal transition ease-in-out duration-150 hover:text-white border-2 border-flow-blue"
          :class="{
            'bg-white border-2 sm:font-semibold focus:outline-none focus:shadow-outline-blue active:bg-gray-50  ':
              !isOptionSelected(option),
            'button-active sm:font-semibold text-white dealer-radio-buttons-active border-2':
              isOptionSelected(option),
            'border-red-300 ': errors.length,
          }"
        >
          <span
            v-if="option.icon"
            :class="{
              'text-white': isOptionSelected(option),
              'text-gray-600': !isOptionSelected(option),
            }"
            class="float-left"
          >
            <i class="fas" :class="option.icon"></i>
          </span>
          <span class="mx-auto truncate">{{ option.label }}</span>
        </button>

        <transition name="slide">
          <div v-if="option.text" v-show="isOptionSelected(option)" class="w-full my-0">
            <p class="mt-3">{{ option.text }}</p>
          </div>
        </transition>
      </div>
      <input type="hidden" v-model="localValue" />
    </div>
    <div v-if="errors.length" class="error-message">{{ errors[0] }}</div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import FormLabel from './FormLabel';
export default {
  name: 'RadioButtons',
  components: {
    ValidationProvider,
    FormLabel,
  },
  watch: {
    localValue(oldVal, newVal) {
      this.$emit('changed', newVal);
    },
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    maxOptions: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    marginTop: {
      type: Boolean,
      default: true,
    },
    selectedText: {
      type: String,
      default: null,
    },
    icons: {
      type: String,
      default: null,
    },
    cols: {
      type: String,
      default: '1',
    },
    gap: {
      type: String,
      default: '4',
    },
    classes: {
      type: String,
      default: null,
    },
    value: {
      // type: String,
      default: null,
    },
  },
  data() {
    return {
      showAllOptions: false,
      localValue: null,
    };
  },
  methods: {
    /**
     * Compares the selected option to another
     */
    isOptionSelected(option) {
      const isSelected = option.value === this.localValue || option.value === this.value;

      if (isSelected) {
        this.select(option, new Event(null));
      }
      return isSelected;
    },
    /**
     * Selects an option, stores it locally (component) and emits it to the
     * assigned model
     */
    select(option, event) {
      event.preventDefault();
      this.localValue = option.value;
      this.$emit('input', this.localValue);
    },

    reset() {
      this.localValue = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.slide-enter-active {
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;
}
.slide-leave-active {
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}
.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
