<template>
  <section class="home">
    <!-- Page title -->
    <h2 v-if="dashboard.application.reference">
      <span class="text-flow-blue dealer-text-secondary">
        <span class="font-light">Reference:</span> {{ dashboard.application.reference }}
      </span>
    </h2>

    <p v-if="dashboard.application.completed === 1" class="mt-3">
      Your application has been submitted. Check the progress section to see how it's going.
    </p>
    <p v-else class="mt-3">
      Add a car by reg or <template v-if="shouldDisplayFeed()">by using our Vehicle Search</template
      ><template v-else>by make and model</template> and then hit Apply to complete your application
      and get motoring!
    </p>

    <RecentVehicle v-if="!isPrime()" />

    <!-- Navigation cards -->
    <NavCards />
  </section>
</template>

<script>
import NavCards from '@/components/Ui/Dashboard/NavCards';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import ApplicationProgress from '@/components/Ui/Dashboard/ApplicationProgress';
import RecentVehicle from '@/components/Ui/CarSearch/RecentVehicle';

export default {
  components: {
    NavCards,
    ApplicationProgress,
    RecentVehicle,
  },

  computed: mapState(['dashboard']),
};
</script>
