<template>
  <div id="image-gallery">
    <!-- Main gallery (spotlight) -->
    <template v-if="images.length">
      <div id="gallery-spotlight" class="splide">
        <div class="splide__track">
          <ul class="splide__list">
            <li v-for="(img, i) in images" :key="i" class="splide__slide">
              <img alt="Vehicle image" :src="img" />
            </li>
          </ul>
        </div>
      </div>

      <!-- Thumbnail navigation -->
      <div id="gallery-thumbnails" class="splide">
        <div class="splide__track">
          <ul class="splide__list">
            <li v-for="(img, i) in images" :key="i" class="splide__slide">
              <img alt="Vehicle thumbnail" :src="img" />
            </li>
          </ul>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide-core.min.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export default {
  data() {
    return {
      sliders: {
        main: null,
        thumbs: null,
      },
    };
  },

  props: {
    images: {
      type: Array,
      default: () => [],
      // required:true,
    },
  },

  methods: {
    initGalleries() {
      // Instantiate the sliders
      this.sliders.main = new Splide('#gallery-spotlight', {
        type: 'slide',
        heightRatio: 0.75,
        pagination: false,
        arrows: false,
        cover: true,
        speed: 500,
        gap: 10,
      });

      this.sliders.thumbs = new Splide('#gallery-thumbnails', {
        arrows: true,
        rewind: true,
        updateOnMove: true,
        fixedWidth: 100,
        fixedHeight: 75,
        isNavigation: true,
        gap: 10,
        focus: 'center',
        infinite: false,
        pagination: false,
        cover: true,
        dragMinThreshold: {
          mouse: 4,
          touch: 10,
        },
        breakpoints: {
          640: {
            fixedWidth: 66,
            fixedHeight: 38,
          },
        },
      });

      this.sliders.main.sync(this.sliders.thumbs);
      this.sliders.main.mount();
      this.sliders.thumbs.mount();
    },
  },

  mounted() {
    // TODO: Fix this janky-ass workaround!
    setTimeout(() => {
      this.initGalleries();
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
#image-gallery {
  #gallery-spotlight {
    margin-bottom: 15px;
  }

  #gallery-thumbnails {
    .splide__slide {
      opacity: 0.5;
      border-radius: 5px;
      border: none;

      &.is-active {
        opacity: 1;
      }
    }
  }

  #gallery-spotlight {
    .splide__slide {
      border-radius: 5px;
    }
  }
}
</style>
