<template>
  <div class="flex items-center min-h-screen">
    <div class="px-10 md:px-0 w-full md:w-2/3 lg:w-1/3 text-center mx-auto">
      <img v-if="dealer.logo_url" :src="dealer.logo_url" alt="" class="mx-auto mb-10" />

      <p class="text-2xl font-bold">You're almost there...</p>
      <p class="text-lg mt-10">
        We can see you have started to register an account with us. Would you like to resume
        <template v-if="journey.applicant.mobile_phone"
          >(using {{ journey.applicant.mobile_phone }})</template
        >
        or start a new one with a different phone number?.
      </p>
      <div class="actions grid grid-cols-1 sm:grid-cols-2 mt-10 gap-4">
        <button @click="resume" class="w-full sm:w-auto dealer-btn-primary ml-auto">Resume</button>
        <button @click="startAgain" class="w-full sm:w-auto dealer-btn-primary mr-auto">
          Start again
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex/dist/vuex.esm.browser';
import Logo from '@/components/Layout/Logo.vue';
import store from '@/store';

/**
 * This view is only loaded when a user attempts to login with an incomplete application first account.
 */
export default {
  components: { Logo },
  computed: mapState(['journey', 'dealer']),

  methods: {
    async resume() {
      return await this.$router.push({
        name: this.$route.params.routeName ?? 'about-you',
      });
    },

    async startAgain() {
      console.log('Resetting journey...');
      await store.dispatch('resetJourney');

      // setTimeout(async () => {
      console.log(this.journey);
      console.log('Redirecting to personal details...');
      return await this.$router.push({
        name: 'personal-details',
      });
      // }, 1000)
    },
  },
};
</script>
