<template>
  <div class="loading-container w-full h-full fixed top-0 left-0 flex flex-col items-center">
    <div class="animation">
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="45" />
      </svg>
    </div>
    <h3 class="font-bold loading-text text-center">{{ text }}</h3>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['dealer']),
  },
  props: {
    text: {
      type: String,
      default: 'One moment...',
    },
  },
};
</script>

<style scoped>
.animation {
  width: 100px;
  height: 100px;
}

svg {
  animation: 2s linear infinite svg-animation;
  max-width: 65px;
  margin: 0 auto;
}

@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

circle {
  animation: 1.4s ease-in-out infinite both circle-animation;
  display: block;
  fill: transparent;
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 280;
  stroke-width: 10px;
  transform-origin: 50% 50%;
}

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}

.loading-container {
  z-index: 1000;
  padding-top: 15%;
}
</style>
