<template>
  <div class="page page--not-found flex items-center min-h-screen p-5 md:p-10">
    <div class="text-center mx-auto self-center">
      <Logo class="mx-auto mb-16" />
      <div class="mb-16">
        <h1 class="mt-2 mb-5 text-gray-800 text-2xl">Page not found</h1>
        <h2 class="text-lg font-normal grey max-w-lg">
          Sorry about that, it looks like the page or link you are looking for no longer exists.
          Let's try and get you back on track:
        </h2>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <button
          @click="$router.push({ name: 'apply' })"
          class="dealer-btn-primary mx-auto sm:ml-auto sm:mr-px"
        >
          Apply
        </button>
        <button
          @click="$router.push({ name: 'login' })"
          class="dealer-btn-primary mx-auto sm:mr-auto sm:ml-px"
        >
          Login
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Logo from '@/components/Layout/Logo.vue';

export default {
  components: { Logo },
};
</script>
