<template>
  <button
    @click="clickHandler()"
    class="items-center cursor-pointer sm:text-xl sm:leading-relaxed flex justify-center py-2 px-3 text-base sm:font-semibold leading-6 font-medium text-white focus:outline-none focus:border-flow-blue focus:shadow-outline-flow-blue active:dealer-bg-primary transition duration-150 ease-in-out uppercase"
    :class="{
      'text-sm': small,
      'border-dealer-primary bg-transparent dealer-text-secondary border-2 hover:dealer-bg-secondary hover:dealer-text-primary':
        isOutLightBlue,
      'border-dealer-primary bg-transparent border-2 hover:dealer-bg-secondary hover:dealer-text-primary text-white':
        isOutLightBlueWhite,
      'border-dealer-primary bg-transparent dealer-text-primary border-2 hover:dealer-bg-primary hover:text-white':
        isOutBlue,
      'border-dealer-primary dealer-bg-secondary dealer-text-primary border-2 hover:dealer-bg-primary hover:text-white':
        isLightBlue,
      'border-dealer-primary dealer-bg-primary text-white border-2 hover:bg-transparent hover:dealer-text-primary hover:border-flow-blue':
        isBlue,

      'opacity-50 cursor-not-allowed pointer-events-none': isDisabled,
      'switch focus:outline-none focus:border-flow-blue focus:shadow-outline-flow-teal active:dealer-bg-secondary':
        isSwitch,

      'switch-on dealer-bg-secondary text-white': selected && isSwitch,
      'switch-off bg-white dealer-text-primary border-flow-teal': !selected && isSwitch,
      'px-0 w-12 h-12': !value,
      'px-10': value,
    }"
    :disabled="isDisabled"
  >
    <i v-if="icon" class="fal text-md sm:text-xl" :class="'fa-' + icon"></i>
    {{ value }}
  </button>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isSwitch: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    isLightBlue: {
      type: Boolean,
      default: false,
    },
    isBlue: {
      type: Boolean,
      default: false,
    },
    isOutBlue: {
      type: Boolean,
      default: false,
    },
    isOutLightBlue: {
      type: Boolean,
      default: false,
    },
    isOutLightBlueWhite: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    small: {
      type: String,
      default: null,
    },
  },
  methods: {
    clickHandler() {
      if (!this.isDisabled) {
        this.$emit('click');
      }
    },
  },
};
</script>
