<template>
  <section class="error">
    <div
      class="max-w-7xl mx-auto text-white p-4 xl:px-0 w-full text-center flex justify-center items-center h-screen"
    >
      <div class="">
        <h1>Something went wrong</h1>
        <p class="dealer-text-primary text-xl">{{ errors[currentError] }}</p>
        <div v-if="currentError !== 'GENERIC'">Code: {{ currentError }}</div>

        <div class="actions grid grid-cols-2 gap-4 mx-auto md:w-3/4 mt-8">
          <button
            @click="goBack()"
            class="btn-secondary dealer-bg-secondary hover:dealer-bg-primary hover:dealer-text-secondary"
          >
            Try again
          </button>
          <button
            @click="$router.push({ name: 'home' })"
            class="btn-primary dealer-bg-primary hover:dealer-bg-secondary hover:dealer-text-primary"
          >
            Home
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Bugsnag from '@bugsnag/js';

export default {
  data() {
    return {
      currentError: 'GENERIC',
      errors: {
        NODEALER:
          'If you were referred by a dealer, try visiting the link they provided again or contact them for more information.',
        GENERIC:
          'An unexpected error has occurred. We have been notified and will investigate the issue as soon as possible.',
        ERRFETCHDEALER: 'There was a problem finding the dealer who referred you.',
      },
    };
  },

  methods: {
    goBack() {
      return this.$router.go(-1);
    },
  },

  mounted() {
    // Disable loading screen in case it wasn't disabled before failure
    this.$emit('loading', false);

    if (this.$route.query.code) {
      const code = this.$route.query.code;
      if (typeof this.errors[code] !== 'undefined') {
        this.currentError = code;
      }
    }

    Bugsnag.notify(new Error(`"${this.currentError}" error was triggered`));
  },
};
</script>
