var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"hero pl-6 pr-8 w-full flex pt-10 pb-8 relative bg-flow-blue dealer-bg-banner",attrs:{"id":"hero"}},[_c('div',{staticClass:"relative w-full mx-auto max-w-7xl my-8 md:mt-16 items-center"},[(_vm.loading)?[_vm._m(0)]:_vm._e(),(!_vm.loading && _vm.creditReport !== null && _vm.display.creditScoreAnimation)?_c('div',{staticClass:"text-center sm:text-left sm:flex items-center"},[_c('div',{staticClass:"sm:mr-12"},[_c('vue-ellipse-progress',{attrs:{"emptyColor":"#696b8a","size":180,"color":_vm.scoreRating.color,"progress":_vm.scorePercentage,"legendValue":_vm.creditReport.uniscore,"thickness":3,"lineMode":"in 5","dash":"1 3.85","angle":90,"legendClass":'font-bold text-5xl -mt-4 rating-' + _vm.scoreRating.caption.toLowerCase()}},[_c('span',{attrs:{"slot":"legend-value"},slot:"legend-value"}),_c('p',{staticClass:"text-gray-300 text-lg -mt-1 mb-0",attrs:{"slot":"legend-caption"},slot:"legend-caption"},[_vm._v(" out of "),_c('strong',[_vm._v(_vm._s(_vm.maxScore))])])])],1),_c('div',{staticClass:"mt-4 sm:mt-0 xl:w-1/2"},[(_vm.status !== null)?[_c('h3',{staticClass:"text-white font-bold text-3xl"},[_vm._v(_vm._s(_vm.status.title))]),_c('p',{staticClass:"text-white text-base my-3"},[_vm._v(_vm._s(_vm.status.text))]),(!_vm.dashboard.application.completed)?_c('div',{staticClass:"pb-8 pt-3 max-w-7xl sm:w-full"},[(_vm.shouldDisplayFeed())?_c('button',{staticClass:"border-flow-white text-flow-white w-full dealer-btn-primary-outer sm:w-auto text-xl py-3",attrs:{"is-disabled":false},on:{"click":function($event){return _vm.$router.push({
                  name: 'search',
                  params: {
                    reference:
                      _vm.$route.params.reference || _vm.$store.state.dashboard.application.reference,
                  },
                })}}},[_vm._v(" Vehicle Search ")]):_c('button',{staticClass:"border-flow-white text-flow-white w-full dealer-btn-primary-outer sm:w-auto text-xl py-3",attrs:{"is-disabled":false},on:{"click":function($event){return _vm.$router.push({
                  name: 'calculator',
                  params: {
                    reference:
                      _vm.$route.params.reference || _vm.$store.state.dashboard.application.reference,
                  },
                })}}},[_vm._v(" Add a car ")])]):_vm._e()]:_vm._e()],2)]):_vm._e(),(!_vm.loading && _vm.creditReport === null)?[_vm._m(1),_c('p',{staticClass:"text-white text-base my-3"},[_vm._v(" We're sorry, it looks like we ran into a technical issue when fetching your credit report. If this problem persists, please do not hesitate to get in touch... ")])]:_vm._e()],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('span',{staticClass:"spinner inline-block relative rounded-full w-16 h-16 spinner--2",staticStyle:{"border-color":"rgba(255, 255, 255, 0.2)","border-top-color":"white","border-width":"3px"}}),_c('p',{staticClass:"mt-5 text-lg text-flow-white"},[_vm._v("Fetching your latest report...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"text-2xl md:text-4xl leading-9 font-extrabold text-white md:inline-block"},[_c('span',[_vm._v("Something went wrong...")])])
}]
render._withStripped = true
export { render, staticRenderFns }