<template>
  <section class="calculator">
    <div class="add-car">
      <h2 class="mb-2 text-flow-blue dealer-text-secondary">Add new vehicle</h2>
      <p>To get started, search by make and model to add your vehicle:</p>
      <div class="mt-5 grid grid-cols-1 md:grid-cols-3 gap-4">
        <div v-if="shouldDisplayFeed()" class="text-center">
          <button
            class="w-full dealer-btn-primary"
            @click="
              $router.push({
                name: 'search',
                params: {
                  reference:
                    $route.params.reference || $store.state.dashboard.application.reference,
                },
              })
            "
          >
            Browse vehicles
          </button>
        </div>
        <div class="text-center">
          <button
            class="w-full dealer-btn-primary"
            @click="$router.push({ name: 'dashboard__calculator__add-vehicle-reg' })"
          >
            Add by reg
          </button>
        </div>
        <div class="text-center">
          <button
            class="w-full dealer-btn-primary"
            @click="$router.push({ name: 'dashboard__calculator__add-vehicle-manually' })"
          >
            Add by make &amp; model
          </button>
        </div>
      </div>
    </div>

    <div v-if="dashboard.application.completed && quote !== null" class="submitted-quote mt-16">
      <div v-if="loading.quote">Loading...</div>

      <h3 class="mb-2 text-flow-blue dealer-text-secondary">Your quote</h3>
      <p>
        You have applied for {{ quote.result.type }} ({{ text[quote.result.type] }}) finance on a
        <strong>{{ quote.vehicle.make }} {{ quote.vehicle.model }}</strong
        >. You can view more details about this quote below or
        <span
          @click="
            $router.push({
              name: 'application-dashboard',
              params: { reference: dashboard.application.reference },
            })
          "
          class="underline cursor-pointer"
          >click here</span
        >
        to find out how it's going.
      </p>
      <div class="p-0 bg-flow-white rounded-md mt-5 shadow-lg lg:w-1/2">
        <div class="inline-flex space-x-3 items-center w-full border-b-1 border-gray-200 p-6">
          <div
            class="rounded-full h-12 w-12 p-1 dealer-text-primary dealer-bg-secondary flex items-center justify-center text-sm font-bold mr-4"
          >
            {{ quote.result.type }}
          </div>
          <h3 class="mb-0">
            {{ (quote.result.monthly_payment / 100) | toGbp }} <small>p/month</small>
          </h3>
        </div>

        <ul class="list-disc ml-5 md:grid grid-cols-2 p-6">
          <li>Type: {{ quote.result.type }}</li>
          <li>Term: {{ quote.result.term }} months</li>
          <li>Cash price: {{ (quote.result.cash_price / 100) | toGbp }}</li>
          <li>Deposit: {{ (quote.result.net_deposit / 100) | toGbp }}</li>
          <li>APR: {{ quote.result.apr }}%</li>
          <li>Total charge: {{ (quote.result.total_charge / 100) | toGbp }}</li>
          <!--<li>Flat rate: {{ quote.result.flat_rate.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }}%</li>-->
          <li>Future value: {{ (quote.result.residual_value / 100) | toGbp }}</li>
          <li>Final payment: {{ (quote.result.final_payment / 100) | toGbp }}</li>
          <li v-if="quote.result.type === 'PCP'">
            Excess mileage rate: {{ quote.result.excess_mileage_rate }}p/mile
          </li>
        </ul>
      </div>
    </div>

    <template v-else>
      <!-- Recently viewed (on public site) -->
      <RecentVehicle class="grid grid-cols-1 lg:grid-cols-2" />

      <!-- Previous quotes -->
      <div class="previous-quotes mt-12">
        <h3 class="mb-2 text-flow-blue dealer-text-secondary">Previous quotes</h3>

        <Loading v-if="loading.quotes" />
        <template v-else>
          <p class="mb-5">Quotes are valid for 14 days after calculating.</p>
          <div
            v-if="quotes.length"
            class="quotes mt-5 grid grid-cols-1 md:grid-cols-2 gap-4 gap-y-6"
            :class="{ 'lg:grid-cols-2': quotes.length < 2, 'lg:grid-cols-4': quotes.length > 2 }"
          >
            <SmallQuoteCard v-for="(quote, i) in quotes" :key="i" :quote="quote" />
          </div>
          <div v-else class="no-quotes grid grid-cols-1">
            <div class="bg-gray-200 p-5 rounded-xl">
              <div class="text-xl mb-2">Nothing yet!</div>
              <p>
                You do not have any previous quotes yet. Search for a vehicle to get your first
                quote.
              </p>
            </div>
          </div>
        </template>
      </div>

      <!--Please keep incase we need-->
      <!-- Previously viewed vehicles -->
      <!--<div class="previous-quotes mt-16">-->
      <!--    <h3 class="mb-2">Recently viewed</h3>-->

      <!--    <Loading v-if="loading.vehicles" />-->
      <!--    <template v-else>-->
      <!--        <p class="mb-5">Quotes are valid for 14 days after calculating.</p>-->
      <!--        <div v-if="vehicles.length" class="quotes mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">-->
      <!--            <SmallVehicleQuoteCard v-for="(vehicle, i) in vehicles" :key="i" :vehicle="vehicle" />-->
      <!--        </div>-->
      <!--        <div v-else class="no-quotes grid grid-cols-1">-->
      <!--            <div class="bg-gray-200 p-5 rounded-xl">-->
      <!--                <div class="text-xl mb-2">Nothing yet!</div>-->
      <!--                <p>You do not have any previous quotes yet. Search for a vehicle to get your first quote.</p>-->
      <!--            </div>-->
      <!--        </div>-->
      <!--    </template>-->
      <!--</div>-->
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import Loading from '@/components/Ui/Loading';
import SmallVehicleQuoteCard from '@/components/Ui/Calculator/SmallVehicleQuoteCard';
import SmallQuoteCard from '@/components/Ui/Calculator/SmallQuoteCard';
import Api from '@/lib/Api';
import RecentVehicle from '@/components/Ui/CarSearch/RecentVehicle';

export default {
  components: {
    Loading,
    SmallVehicleQuoteCard,
    SmallQuoteCard,
    RecentVehicle,
  },

  computed: mapState(['dashboard']),

  data() {
    return {
      loading: {
        vehicles: false,
        quotes: false,
        quote: false,
      },
      recentVehicle: null,
      vehicles: [],
      quotes: [],
      quote: null,
      text: {
        PCP: 'Personal Contract Purchase',
        HP: 'Hire Purchase',
      },
    };
  },

  methods: {
    ...Api,

    getRecentlyViewed(reference) {
      this.loading.vehicles = true;
      return axios
        .get(`${this.getApiBaseUrl()}/v1/quotes/vehicles/quotes.json`, {
          params: {
            application_reference: reference,
            limit: 6,
          },
        })
        .then((r) => {
          if (r.data.success && r.data.data.vehicles.length) {
            return r.data.data.vehicles;
          }
          return [];
        })
        .catch((e) => {
          console.error('Failed retrieving vehicle quotes:', e);
        })
        .finally(() => {
          this.loading.vehicles = false;
        });
    },

    getRecentQuotes(reference) {
      this.loading.quotes = true;
      return axios
        .get(`${this.getApiBaseUrl()}/v1/quotes/application/quotes.json`, {
          params: {
            application_reference: reference,
            limit: 6,
          },
        })
        .then((r) => {
          if (r.data.success && r.data.data.quotes.length) {
            return r.data.data.quotes;
          }
          return [];
        })
        .catch((e) => {
          console.error('Failed retrieving application quotes', e);
        })
        .finally(() => {
          this.loading.quotes = false;
        });
    },

    async getRecentData() {
      // Get application quotes (manually entered)
      this.quotes = await this.getRecentQuotes(this.dashboard.application.reference);

      // Get recently viewed vehicle quotes
      this.vehicles = await this.getRecentlyViewed(this.dashboard.application.reference);
    },
  },

  async mounted() {
    // If they've completed their application, show the quote they've applied for
    if (this.dashboard.application.completed) {
      // Get the quote figures, result and vehicle they've applied for
      this.loading.quote = true;
      this.quote = await this.apiGetSubmittedQuote(this.dashboard.application.reference);
      this.loading.quote = false;

      if (this.quote === null) {
        await this.getRecentData();
      }
    } else {
      await this.getRecentData();
    }
  },
};
</script>
