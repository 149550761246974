import { render, staticRenderFns } from "./CreditBanner.vue?vue&type=template&id=677506b0&scoped=true"
import script from "./CreditBanner.vue?vue&type=script&lang=js"
export * from "./CreditBanner.vue?vue&type=script&lang=js"
import style0 from "./CreditBanner.vue?vue&type=style&index=0&id=677506b0&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "677506b0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/bitnami/apache2/htdocs/flowcarfinance/tmp-dealer/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('677506b0')) {
      api.createRecord('677506b0', component.options)
    } else {
      api.reload('677506b0', component.options)
    }
    module.hot.accept("./CreditBanner.vue?vue&type=template&id=677506b0&scoped=true", function () {
      api.rerender('677506b0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Ui/Banners/CreditBanner.vue"
export default component.exports