<template>
  <nav class="dealer-header-bg-color w-full shadow-md" id="navbar" style="z-index: 3">
    <div class="max-w-7xl justify-between flex flex-shrink-0 mx-auto text-white p-4 xl:px-0 w-full">
      <div
        class="logo flex justify-center"
        @click="
          $router.push({
            name: 'application-dashboard',
            params: { reference: dashboard.application.reference },
          })
        "
      >
        <img
          v-if="dealer.logo_url"
          :src="dealer.logo_url"
          alt=""
          class="mr-auto cursor-pointer object-contain h-12 w-auto pr-3"
        />
        <Logo v-else :blue="true" class="mr-auto cursor-pointer flex justify-center"></Logo>
      </div>
      <div class="flex items-center">
        <div class="flex items-center justify-between">
          <button
            class="dealer-nav-buttons ml-3 w-10 h-10 flex items-center justify-center text-sm rounded-full focus:outline-none shadow-md"
            @click="$modal.show('infoModal')"
          >
            <i class="fas fa-info-circle text-lg hover:text-white"></i>
          </button>
          <transition
            enter-active-class="ease-out duration-500"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="ease-in duration-500"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <Guide @close="$modal.hide('infoModal')" />
          </transition>

          <!-- Profile dropdown -->
          <div class="ml-3 relative">
            <div>
              <button
                @click="toggleUserMenu"
                v-on-clickaway="hideUserMenu"
                class="dealer-nav-buttons w-10 h-10 flex items-center justify-center text-sm rounded-full focus:outline-none shadow-md"
                id="user-menu"
                aria-label="User menu"
                aria-haspopup="true"
              >
                <i class="fas fa-user text-lg"></i>
              </button>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100 transform"
              enter-class="opacity-0 scale-95"
              enter-to-class="opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75 transform"
              leave-class="opacity-100 scale-100"
              leave-to-class="opacity-0 scale-95"
            >
              <div
                v-show="userMenuOpen"
                class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg"
              >
                <div
                  class="pt-1 rounded-md bg-white shadow-xs font-bold relative z-50"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <div id="traingle"></div>
                  <p class="text-gray-800 px-4 py-2 text-sm leading-none font-bold">
                    Hi, {{ dashboard.applicant.first_name }}
                  </p>
                  <button
                    @click="$router.push({ name: 'applications__manage' })"
                    class="block text-gray-800 underline hover:no-underline text-left w-full px-4 py-2 text-sm hover:bg-gray-100 rounded-b-md transition ease-in-out duration-150"
                    role="menuitem"
                  >
                    Manage applications
                  </button>
                  <button
                    @click="logout()"
                    class="block text-gray-800 underline hover:no-underline text-left w-full px-4 py-2 text-sm hover:bg-gray-100 rounded-b-md transition ease-in-out duration-150"
                    role="menuitem"
                  >
                    Logout
                  </button>
                </div>
              </div>
            </transition>
          </div>
          <Sidebar />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Sidebar from '@/components/Ui/Sidebar';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import { mixin as clickaway } from 'vue-clickaway';
import Guide from '@/components/Ui/Guide';
import axios from 'axios';
import Logo from '@/components/Layout/Logo';

export default {
  mixins: [clickaway],

  components: {
    Sidebar,
    Guide,
    Logo,
  },

  computed: mapState(['dashboard', 'dealer']),

  data() {
    return {
      userMenuOpen: false,
    };
  },

  methods: {
    /**
     * Toggle the user's context menu
     */
    toggleUserMenu() {
      this.userMenuOpen = !this.userMenuOpen;
    },

    /**
     * Hides the user's context menu
     */
    hideUserMenu() {
      this.userMenuOpen = false;
    },

    /**
     * Logs the user out and redirects to login page.
     */
    logout() {
      this.$emit('loading', true, 'Logging out...');
      axios
        .post(this.getApiBaseUrl() + '/auth/logout')
        .then((r) => {
          this.$store.dispatch('logout');
        })
        .finally(() => {
          this.$emit('loading', false);
          this.$router.push({ name: 'login' });
        });
    },
  },
};
</script>
