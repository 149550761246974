<template>
  <ValidationObserver ref="observer" tag="div" class="manual-address" v-slot="{ invalid }">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <validation-provider
        ref="building-name"
        name="building-name"
        :rules="{ required: form.buildingNumber.value == '' }"
        v-slot="{ errors }"
      >
        <div>
          <label
            class="font-title font-bold text-lg leading-6 text-gray-900 sm:text-xl sm:leading-normal md:text-base"
            for="building_name"
            >Building name</label
          >
          <input class="form-input" id="building_name" v-model.trim="form.buildingName.value" />
          <div class="error-message" v-if="errors.length">{{ errors[0] }}</div>
        </div>
      </validation-provider>

      <ValidationProvider
        ref="building-number"
        name="building-number"
        :rules="{ required: form.buildingName.value == '' }"
        v-slot="{ errors }"
      >
        <div>
          <label
            class="font-title font-bold text-lg leading-6 text-gray-900 sm:text-xl sm:leading-normal md:text-base"
            for="building_name"
            >Building number</label
          >
          <input class="form-input" id="building_number" v-model.trim="form.buildingNumber.value" />
          <div class="error-message" v-if="errors.length">{{ errors[0] }}</div>
        </div>
      </ValidationProvider>
    </div>

    <ValidationProvider ref="street" name="street" rules="required" v-slot="{ errors }">
      <div class="mt-2">
        <label
          class="font-title font-bold text-lg leading-6 text-gray-900 sm:text-xl sm:leading-normal md:text-base"
          for="street"
          >Street</label
        >
        <input class="form-input" id="street" v-model="form.street.value" />
        <div class="error-message" v-if="errors.length">{{ errors[0] }}</div>
      </div>
    </ValidationProvider>

    <ValidationProvider ref="town" name="town" rules="required" v-slot="{ errors }">
      <div class="mt-2">
        <label
          class="font-title font-bold text-lg leading-6 text-gray-900 sm:text-xl sm:leading-normal md:text-base"
          for="town"
          >Town</label
        >
        <input class="form-input" id="town" v-model="form.town.value" />
        <div class="error-message" v-if="errors.length">{{ errors[0] }}</div>
      </div>
    </ValidationProvider>

    <ValidationProvider ref="postcode" name="postcode" rules="required" v-slot="{ errors }">
      <div class="mt-2">
        <label
          class="font-title font-bold text-lg leading-6 text-gray-900 sm:text-xl sm:leading-normal md:text-base"
          for="postcode"
          >Postcode</label
        >
        <input class="uppercase form-input" id="postcode" v-model="form.postcode.value" />
        <div class="error-message" v-if="errors.length">{{ errors[0] }}</div>
      </div>
    </ValidationProvider>

    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <div class="text-left">
        <p
          class="underline hover:no-underline cursor-pointer mt-2 sm:mt-8 mb-3"
          @click="$emit('use-address-finder')"
        >
          Back to address finder
        </p>
      </div>
      <div class="ml-auto">
        <button
          :disabled="invalid"
          :class="{ 'opacity-50': invalid }"
          @click="saveAddress"
          class="dealer-btn-primary ml-auto"
        >
          Save
        </button>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },

  computed: {
    address() {
      return {
        buildingNumber: this.form.buildingNumber.value,
        buildingName: this.form.buildingName.value,
        street: this.form.street.value,
        town: this.form.town.value,
        postcode: this.form.postcode.value,
        key: 'manually_entered',
      };
    },
  },

  data() {
    return {
      form: {
        buildingName: {
          value: '',
        },
        buildingNumber: {
          value: '',
        },
        street: {
          value: '',
        },
        town: {
          value: '',
        },
        postcode: {
          value: '',
        },
      },
    };
  },

  methods: {
    resetForm() {
      this.form.buildingName.value = '';
      this.form.buildingNumber.value = '';
      this.form.street.value = '';
      this.form.town.value = '';
      this.form.postcode.value = '';

      // Reset validation
      this.$refs.observer.reset();
    },

    async saveAddress() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.$emit('manual-address-saved', this.address);
      }
    },
  },
};
</script>
