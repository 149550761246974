<template>
  <section class="step personal-details flex-1">
    <h2>About you</h2>

    <section class="form mt-8">
      <ValidationObserver v-slot="{ invalid }" tag="div">
        <!-- Marital status field -->
        <div class="field">
          <FormLabel forInput="">Which best describes you?</FormLabel>
          <RadioButtons
            :max-options="6"
            :rules="form.marital_status.rules"
            :options="form.marital_status.options"
            v-model="form.marital_status.value"
            cols="2"
          />
        </div>

        <!-- Driving licence field -->
        <div class="field">
          <FormLabel forInput="">What kind of driving license do you have?</FormLabel>
          <RadioButtons
            :max-options="6"
            :rules="form.driving_licence_type.rules"
            :options="form.driving_licence_type.options"
            v-model="form.driving_licence_type.value"
            cols="2"
          />
        </div>

        <div class="form-actions flex">
          <button
            class="btn-primary dealer-btn-primary ml-auto"
            @click="submit"
            :disabled="invalid"
            :class="{ 'opacity-50': invalid }"
          >
            Next
          </button>
        </div>
      </ValidationObserver>
    </section>
  </section>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import RadioButtons from '@/components/Form/RadioButtons';
import FormLabel from '@/components/Form/FormLabel';
import Api from '@/lib/Api';
import Bugsnag from '@bugsnag/js';

export default {
  data() {
    return {
      form: {
        marital_status: {
          options: [
            { label: 'Married', value: 'Married' },
            { label: 'Single', value: 'Single' },
            { label: 'Cohabiting', value: 'Cohabiting' },
            { label: 'Divorced', value: 'Divorced' },
            { label: 'Widowed', value: 'Widowed' },
            { label: 'Separated', value: 'Separated' },
          ],
          value: null,
          rules: 'required',
        },
        driving_licence_type: {
          options: [
            { label: 'Full UK', value: 'UK Full' },
            { label: 'Provisional UK', value: 'UK Provisional' },
            { label: 'EU', value: 'EU' },
            { label: 'International', value: 'International' },
            { label: 'None', value: 'No Licence Held' },
          ],
          value: null,
          rules: 'required',
        },
      },
    };
  },

  components: {
    ValidationObserver,
    ValidationProvider,
    RadioButtons,
    FormLabel,
  },

  computed: mapState(['dealer', 'journey']),

  methods: {
    ...Api,

    /**
     * Stores the applicant data in Vuex, saves a new application in the API and sends a
     * verification code to the phone number provided. If all succeeds, proceed to next step.
     */
    async submit() {
      this.$emit('loading', true, 'Saving...');

      const applicantData = {
        marital_status: this.form.marital_status.value,
        driving_licence_type: this.form.driving_licence_type.value,
      };

      // Update applicant in Vuex
      await this.$store.commit('setApplicant', applicantData);

      // Update applicant in API
      const applicantUpdated = await this.updateApplicant(
        this.journey.applicant.id,
        this.journey.application.reference,
        applicantData
      );

      // If successful, move to next step
      if (applicantUpdated) {
        let dealerName = this.dealer.name;

        if (this.dealer.name) {
          // Attach dealer name to push event
          await this.apiPushEvent(
            this.journey.application.reference,
            dealerName + '_step_about_you',
            dealerName + '_feed_journey',
            dealerName + '_step',
            dealerName + '_about_you'
          );
        } else {
          // Standard push event
          await this.apiPushEvent(
            this.journey.application.reference,
            'step_about_you',
            'feed_journey',
            'step',
            'about_you'
          );
        }

        // Done, proceed to next step
        this.$emit('loading', false);
        return await this.$router.push({ name: 'address-history' });
      }

      // Error happened!
      this.$emit('loading', false);
      console.error('Error updating applicant!');
    },

    updateApplicant(applicantId, applicationReference, applicantData) {
      return axios
        .patch(
          this.getApiBaseUrl() +
            '/applications/' +
            applicationReference +
            '/applicants/' +
            applicantId,
          {
            applicant: applicantData,
          }
        )
        .then((r) => {
          return !!r.data.success;
        })
        .catch((e) => {
          console.error(e);
          Bugsnag.notify(e);
          return false;
        });
    },

    restore() {
      const applicant = this.journey.applicant;

      if (applicant.marital_status) {
        this.form.marital_status.value = applicant.marital_status;
      }

      if (applicant.driving_licence_type) {
        this.form.driving_licence_type.value = applicant.driving_licence_type;
      }
    },
  },

  mounted() {
    this.$emit('set-step', 3);

    this.restore();
  },
};
</script>
