<template>
  <section
    v-if="breadcrumbs.length"
    class="relative w-full py-3 px-6 xl:px-0 text-gray-500 hover:text-gray-700 focus:text-gray-700 navbar navbar-expand-lg navbar-light border-b-1 border-gray-300"
  >
    <div class="max-w-7xl justify-between flex flex-shrink-0 items-center mx-auto w-full">
      <nav
        class="isMobile"
        aria-label="breadcrumb"
        :class="{ 'hidden lg:inline-block': $route.meta.requiresAuth === true }"
      >
        <ol class="list-reset flex">
          <li v-for="(crumb, i) in breadcrumbs" :key="i">
            <span v-if="i > 0" class="text-gray-500 mx-2">/</span
            ><a
              href="#"
              @click="$router.push(crumb.route)"
              class="text-gray-500 hover:text-gray-600"
              >{{ crumb.title }}</a
            >
          </li>
        </ol>
      </nav>
      <div v-if="$route.meta.requiresAuth === false" class="inline-flex text-white items-center">
        <i class="fas fa-lock mx-auto text-gray-500 hover:text-gray-600"></i>
        <span class="uppercase ml-2 font-bold text-gray-500 hover:text-gray-600">Secure</span>
      </div>
      <div v-if="$route.meta.requiresAuth === true" id="slider-links" class="splide">
        <div class="grad-overlay right-overlay" />
        <div class="splide__track">
          <div class="splide__list">
            <div
              v-for="(link, index) in links"
              :key="index"
              @click="$router.push({ name: link.routeName, params: link.params })"
              class="splide__slide"
              :class="{ 'active dealer-bg-secondary': $route.name === link.routeName }"
            >
              {{ link.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide-core.min.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export default {
  data() {
    return {
      testUserIsPrime: false,
      breadcrumbs: [],
      sliders: {
        links: null,
      },
      links: [
        {
          routeName: 'home',
          label: 'Home',
          params: {
            reference:
              this.$route.params.reference || this.$store.state.dashboard.application.reference,
          },
          visible: true,
        },
        {
          routeName: 'dashboard__calculator__quotes',
          label: 'Quotes',
          params: {
            reference:
              this.$route.params.reference || this.$store.state.dashboard.application.reference,
          },
          visible: true,
        },
        // {
        // 	routeName: 'dashboard__search__details',
        // 	label: 'Search vehicles',
        // 	params: {
        // 		reference: this.$route.params.reference || this.$store.state.dashboard.application.reference
        // 	},
        // 	visible: !this.isPrime(),
        // },
        {
          routeName: 'dashboard__calculator__add-vehicle-reg',
          label: 'Reg lookup',
          params: {
            reference:
              this.$route.params.reference || this.$store.state.dashboard.application.reference,
          },
          visible: true,
        },
        {
          routeName: 'dashboard__calculator__add-vehicle-manually',
          label: 'Add by make/model',
          params: {
            reference:
              this.$route.params.reference || this.$store.state.dashboard.application.reference,
          },
          visible: true,
        },
        {
          routeName: 'credit-score',
          label: 'Credit score',
          params: {
            reference:
              this.$route.params.reference || this.$store.state.dashboard.application.reference,
          },
          visible: true,
        },
      ],
    };
  },

  watch: {
    $route(to, from) {
      this.refreshBreadcrumbs();
    },
  },

  methods: {
    refreshBreadcrumbs() {
      this.breadcrumbs = this.$route.meta.breadcrumbs ?? [];
    },

    setActiveLink() {
      for (let i = 0; i < this.links.length; i++) {
        if (this.$route.name === this.links[i].routeName) {
          return i;
        }
      }

      return 0;
    },

    navigate(link, index) {
      this.sliders.links.go(index);
      this.$router.push({ name: link.routeName });
    },

    initQuickLinks() {
      // Instantiate the sliders
      this.sliders.links = new Splide('#slider-links', {
        type: 'slide',
        arrows: false,
        rewind: true,
        speed: 1000,
        autoplay: false,
        interval: 3000,
        autoHeight: false,
        pagination: false,
        autoWidth: true,
        gap: 15,
        updateOnMove: false,
        focus: 'center',
        slideFocus: true,
        start: this.setActiveLink(),
        dragMinThreshold: {
          mouse: 4,
          touch: 10,
        },
        breakpoints: {
          640: {
            flickPower: 100,
          },
        },
      });

      this.sliders.links.mount();
    },
  },

  async mounted() {
    await this.refreshBreadcrumbs();

    // Initiate splide breadcrumb links on the dashboard only
    if (this.$route.meta.requiresAuth === true) {
      this.initQuickLinks();
    }
  },
};
</script>

<style lang="scss" scoped>
#slider-links {
  .splide__slide {
    &.active {
      @apply bg-flow-teal text-white;
    }

    @apply rounded-full bg-gray-200 text-center px-3 py-1 text-sm text-gray-600 cursor-pointer;
  }
  @media (max-width: 640px) {
    .grad-overlay {
      @apply from-gray-100 h-full absolute z-10;

      content: '';

      &.right-overlay {
        @apply right-0 bg-gradient-to-l;

        width: 40px;
      }
    }
  }

  @apply w-auto overflow-x-hidden relative sm:ml-8;
}

@media (max-width: 640px) {
  .isMobile {
    display: none;
  }
}
</style>
