<template>
  <section class="dashboard-carsearch-results">
    <div class="mt-8">
      <CarFeed />
    </div>
  </section>
</template>

<script>
import CarFeed from '@/components/Ui/CarSearch/CarFeed';
import { mapState } from 'vuex';

export default {
  components: {
    CarFeed,
  },

  computed: mapState(['dashboard']),
};
</script>
