<template>
  <div class="mt-8">
    <!-- Edit quote overlay -->
    <div
      v-if="editingQuote"
      class="w-full h-full fixed block top-0 left-0 dealer-bg-primary z-50 overflow-y-auto"
    >
      <div
        class="max-w-xl flex justify-center items-center mx-auto w-full py-8 px-4 sm:px-6 lg:px-8"
        :class="loading ? 'h-full' : 'h-auto'"
      >
        <div class="w-full">
          <template v-if="loading" class="h-full">
            <h1 class="text-white text-center">Searching for deals...</h1>
          </template>
          <template v-else>
            <h1 class="text-white">{{ vehicle.make + ' ' + vehicle.model }}</h1>
            <div class="form">
              <div class="field flex items-center space-x-3">
                <label class="text-white w-1/2" for="field-cash-price">Price</label>
                <input
                  class="text-white dealer-bg-primary w-1/2"
                  type="text"
                  disabled
                  id="field-cash-price"
                  tabindex="10"
                  :value="vehicle.price.toLocaleString('en-GB')"
                />
              </div>
              <div class="field flex items-center space-x-3">
                <label class="text-white w-1/2" for="field-mileage">Mileage</label>
                <input
                  class="text-white dealer-bg-primary w-1/2"
                  type="text"
                  disabled
                  id="field-mileage"
                  tabindex="20"
                  :value="vehicle.miles.toLocaleString('en-GB')"
                />
              </div>
              <div class="field flex items-center space-x-3">
                <label class="text-white w-1/2" for="field-deposit">Deposit</label>
                <input
                  type="text"
                  id="field-deposit"
                  tabindex="30"
                  v-model="form.DepositCash.value"
                />
              </div>
              <div class="field flex items-center space-x-3">
                <label class="text-white w-1/2" for="field-px">Part exchange</label>
                <input type="text" id="field-px" tabindex="40" v-model="form.PartExchange.value" />
              </div>
              <div class="field flex items-center space-x-3">
                <label class="text-white w-1/2" for="field-outstanding">Existing finance</label>
                <input
                  type="text"
                  id="field-outstanding"
                  tabindex="50"
                  v-model="form.OutstandingSettlement.value"
                />
              </div>
              <div v-if="!dashboard.authed" class="field flex items-center space-x-3">
                <label class="text-white w-1/2">Estimated APR</label>

                <span
                  class="text-lg w-full border-white border-2 rounded-md bg-none text-white flex h-full py-2 px-5"
                >
                  {{ form.QuoterAPR.value }}%</span
                >
              </div>
              <div class="field flex items-center space-x-3">
                <label class="text-white w-1/2" for="field-term">Term</label>
                <select
                  v-model="form.Term.value"
                  id="field-term"
                  tabindex="60"
                  class="form-select text-lg"
                >
                  <option v-for="(term, i) in form.Term.options" :key="i" :value="term.value">
                    {{ term.label }}
                  </option>
                </select>
              </div>
              <div class="field flex items-center space-x-3">
                <label class="text-white w-1/2" for="field-annual-mileage"
                  >Est. annual mileage</label
                >
                <select
                  v-model="form.AnnualMileage.value"
                  id="field-annual-mileage"
                  tabindex="70"
                  class="form-select text-lg"
                >
                  <option
                    v-for="(option, i) in form.AnnualMileage.options"
                    :key="i"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>

              <div class="actions flex items-center justify-between mt-12">
                <h6
                  @click="editingQuote = false"
                  class="cursor-pointer flex items-center dealer-text-secondary hover:dealer-text-primary mb-0"
                >
                  <i class="fas fa-long-arrow-alt-left mr-2"></i> Back
                </h6>

                <button
                  class="btn-secondary dealer-bg-secondary hover:dealer-bg-primary hover:dealer-text-secondary w-max-md"
                  @click="newQuote()"
                >
                  Get quote
                </button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div
      v-if="loading"
      class="bg-flow-blue dealer-bg-secondary text-white p-8 rounded-lg max-w-2xl w-full"
    >
      Finding our best deal...
    </div>

    <div v-else>
      <!-- No quotes available -->
      <div
        v-if="quotes.hp === null && quotes.pcp === null"
        class="dealer-bg-primary text-white p-8 rounded-lg max-w-2xl w-full"
      >
        <p>
          We're sorry, we could not find any quotes for this vehicle. For more information, please
          call or chat with us. Try altering your quote
        </p>

        <!-- Show quote helper tips (if available) -->
        <template v-if="tips.length && !dealer.name">
          <div
            v-for="(tip, i) in tips"
            :key="i"
            class="border-1 border-flow-white p-5 rounded-lg mb-3"
          >
            <h3 class="text-flow-white mb-3">{{ tip.title }}</h3>
            <p class="text-flow-white mb-0" v-html="tip.message" />
          </div>
        </template>

        <button
          @click="editingQuote = true"
          class="btn-secondary dealer-bg-secondary hover:dealer-bg-primary hover:dealer-text-secondary mt-5 px-3"
        >
          Edit quote
        </button>
      </div>
      <template v-else>
        <!-- Show quotes -->
        <div
          class="grid"
          :class="quotes.hp !== null && quotes.pcp !== null ? 'grid-cols-2' : 'grid-cols-1'"
        >
          <!-- Tab pcp -->
          <div
            v-if="quotes.pcp !== null"
            @click="toggleTabs(1)"
            class="transition ease-in cursor-pointer inline-flex items-center rounded-t-xl px-4 py-2 pt-4"
            :class="{
              'text-gray-700 bg-white': openTab !== 1,
              'bg-flow-blue dealer-bg-secondary text-white': openTab === 1,
              'bg-gray-100': openTab !== 1 && !dashboard.authed,
            }"
          >
            <div
              class="rounded-full h-12 w-12 p-1 text-flow-blue dealer-text-secondary bg-flow-teal dealer-bg-primary flex items-center justify-center text-sm font-bold mr-4"
            >
              PCP
            </div>
            <div class="leading-4">
              <h3
                class="mb-0 dealer-text-primary text-2xl"
                :class="{ 'text-gray-700': openTab !== 1, 'text-white': openTab === 1 }"
              >
                £{{ toDec(quotes.pcp.monthly_payment) }}
              </h3>
              <p class="text-sm mb-0">per month (PCP)</p>
            </div>
          </div>

          <!-- Tab hp -->
          <div
            v-if="quotes.hp !== null"
            @click="toggleTabs(2)"
            class="transition ease-in cursor-pointer inline-flex items-center rounded-t-xl px-4 py-4 border-b-1 border-white border-opacity-10"
            :class="{
              'text-gray-700 bg-white': openTab !== 2,
              'bg-flow-blue dealer-bg-secondary text-white': openTab === 2,
              'bg-gray-100': openTab !== 2 && !dashboard.authed,
            }"
          >
            <div
              class="rounded-full h-12 w-12 p-1 text-flow-blue dealer-text-secondary bg-flow-teal dealer-bg-primary flex items-center justify-center text-md font-bold mr-4"
            >
              HP
            </div>
            <div class="leading-4">
              <h3
                class="mb-0 dealer-text-primary text-2xl"
                :class="{ 'text-gray-700': openTab === 1, 'text-white': openTab !== 1 }"
              >
                £{{ toDec(quotes.hp.monthly_payment) }}
              </h3>
              <p class="text-sm mb-0">per month (HP)</p>
            </div>
          </div>
        </div>

        <!-- Tab area pcp -->
        <div
          v-if="openTab === 1"
          class="bg-flow-blue dealer-bg-secondary text-white p-6 rounded-b-lg w-full"
        >
          <template v-if="quotes.pcp !== null">
            <h3 class="text-white dealer-text-primary mb-2">Personal Contract Purchase</h3>

            <ul class="list-disc list-inside">
              <li>
                <strong class="text-flow-teal dealer-text-primary">{{ quotes.pcp.term }}</strong>
                months
              </li>
              <li>
                <strong class="text-flow-teal dealer-text-primary">{{ quotes.pcp.apr }}%</strong>
                APR
              </li>
              <!--<li><strong class="dealer-text-secondary">{{ quotes.pcp.flat_rate.toLocaleString('en-GB', {maximumFractionDigits: 2}) }}%</strong> flat rate</li>-->
              <li>
                <strong class="text-flow-teal dealer-text-primary"
                  >&pound;{{ toDec(quotes.pcp.cash_price) }}</strong
                >
                cash price
              </li>
              <li>
                <strong class="text-flow-teal dealer-text-primary"
                  >&pound;{{ toDec(quotes.pcp.net_deposit) }}</strong
                >
                deposit
              </li>
              <li v-if="quotes.pcp.annual_mileage">
                <strong class="text-flow-teal dealer-text-primary">{{
                  quotes.pcp.annual_mileage.toLocaleString()
                }}</strong>
                miles per year
              </li>
              <li>
                <strong class="text-flow-teal dealer-text-primary"
                  >&pound;{{ toDec(quotes.pcp.final_payment) }}</strong
                >
                final payment
              </li>
              <li>
                <strong class="text-flow-teal dealer-text-primary"
                  >&pound;{{ toDec(quotes.pcp.total_charge) }}</strong
                >
                total cost of credit
              </li>
              <li>
                <strong class="text-flow-teal dealer-text-primary"
                  >&pound;{{ toDec(quotes.pcp.total_payable) }}</strong
                >
                total payable
              </li>
              <li>
                <strong class="text-flow-teal dealer-text-primary"
                  >&pound;{{ toDec(quotes.pcp.residual_value) }}</strong
                >
                residual value
              </li>
              <li>
                <strong class="text-flow-teal dealer-text-primary"
                  >{{ quotes.pcp.excess_mileage_rate / 100 }}p/mile</strong
                >
                excess mileage rate
              </li>
            </ul>
            <div class="mt-5 text-white" v-if="dashboard.authed && allowApply()">
              This quote was generated {{ quotes.pcp.created_at | moment('Do MMM YYYY') }}
            </div>
            <div class="actions grid grid-cols-2 gap-4 mt-6">
              <!--If they're logged in, show this button-->
              <template v-if="dashboard.authed && allowApply()">
                <button @click="editingQuote = true" class="btn-secondary w-full btn-lg px-3">
                  Edit quote
                </button>
                <button
                  class="rounded-full uppercase font-bold border-flow-teal bg-transparent border-2 hover:bg-flow-teal hover:text-flow-blue text-white text-md sm:text-lg"
                  @click="$emit('apply', quotes.pcp)"
                >
                  Apply <span class="hidden sm:inline">for PCP</span>
                </button>
              </template>

              <!-- If they're not logged in, show this:-->
              <template v-if="!dashboard.authed">
                <button @click="editingQuote = true" class="btn-secondary w-full btn-lg px-3">
                  Edit quote
                </button>
                <button
                  class="rounded-full uppercase font-bold border-flow-teal bg-transparent border-2 hover:bg-flow-teal hover:text-flow-blue text-white text-md sm:text-lg"
                  @click="saveAnonVehicle()"
                >
                  Apply
                </button>
              </template>
            </div>
          </template>
        </div>

        <!-- Tab area hp -->
        <div
          v-if="openTab === 2"
          class="bg-flow-blue dealer-bg-secondary text-white p-6 rounded-b-lg w-full"
        >
          <template v-if="quotes.hp !== null">
            <h3 class="text-white dealer-text-primary mb-2">Hire Purchase</h3>

            <ul class="list-disc list-inside">
              <li>
                <strong class="text-flow-teal dealer-text-primary">{{ quotes.hp.term }}</strong>
                months
              </li>
              <li>
                <strong class="text-flow-teal dealer-text-primary">{{ quotes.hp.apr }}%</strong> APR
              </li>
              <!--<li><strong class="dealer-text-secondary">{{ quotes.hp.flat_rate.toLocaleString('en-GB', {maximumFractionDigits: 2}) }}%</strong> flat rate</li>-->
              <li>
                <strong class="text-flow-teal dealer-text-primary"
                  >&pound;{{ toDec(quotes.hp.cash_price) }}</strong
                >
                cash price
              </li>
              <li>
                <strong class="text-flow-teal dealer-text-primary"
                  >&pound;{{ toDec(quotes.hp.net_deposit) }}</strong
                >
                deposit
              </li>
              <li v-if="quotes.hp.annual_mileage">
                <strong class="text-flow-teal dealer-text-primary">{{
                  quotes.hp.annual_mileage.toLocaleString()
                }}</strong>
                miles per year
              </li>
              <li>
                <strong class="text-flow-teal dealer-text-primary"
                  >&pound;{{ toDec(quotes.hp.final_payment) }}</strong
                >
                final payment
              </li>
              <li>
                <strong class="text-flow-teal dealer-text-primary"
                  >&pound;{{ toDec(quotes.hp.total_charge) }}</strong
                >
                total cost of credit
              </li>
              <li>
                <strong class="text-flow-teal dealer-text-primary"
                  >&pound;{{ toDec(quotes.hp.total_payable) }}</strong
                >
                total payable
              </li>
            </ul>
            <div class="mt-5 text-white" v-if="dashboard.authed && allowApply()">
              This quote was generated on {{ quotes.hp.created_at | moment('Do MMM YYYY') }}
            </div>
            <div class="actions grid grid-cols-2 gap-4 mt-6">
              <!--If they're logged in, show this button-->
              <template v-if="dashboard.authed && allowApply()">
                <button @click="editingQuote = true" class="btn-secondary w-full btn-lg">
                  Edit <span class="hidden sm:inline">quote</span>
                </button>
                <button
                  class="rounded-full uppercase font-bold border-flow-teal bg-transparent border-2 hover:bg-flow-teal hover:text-flow-blue text-white text-md sm:text-lg"
                  @click="$emit('apply', quotes.hp)"
                >
                  Apply <span class="hidden sm:inline">for HP</span>
                </button>
              </template>

              <!-- If they're not logged in, show this:-->
              <template v-if="!dashboard.authed">
                <button @click="editingQuote = true" class="btn-secondary w-full btn-lg">
                  Edit <span class="hidden sm:inline">quote</span>
                </button>
                <button
                  v-if="!dashboard.authed"
                  class="rounded-full uppercase font-bold border-flow-teal bg-transparent border-2 hover:bg-flow-teal hover:text-flow-blue text-white text-md sm:text-lg"
                  @click="saveAnonVehicle()"
                >
                  Apply
                </button>
              </template>
            </div>
          </template>
        </div>
      </template>
    </div>

    <!-- Apply confirmation -->
    <!-- <div class="w-full h-full fixed block top-0 left-0 bg-black z-50" style="background:rgba(0,0,0,0.5)">
            <div class="p-20 dealer-bg-primary w-9/4 mx-auto">
                <h2 class="text-white">Personal Contract Purchase</h2>
                <h1 class="text-white">&pound;320.22<small>/mo</small></h1>
                <div class="text-white">
                    By pressing Apply, you are submitting a new finance application with the selected vehicle and figures.
                </div>
                <button class="btn-primary dealer-bg-primary hover:dealer-bg-secondary hover:dealer-text-primary">Close</button>
                <button class="btn-primary dealer-bg-primary hover:dealer-bg-secondary hover:dealer-text-primary">Apply</button>
            </div>
        </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import Api from '@/lib/Api';
import Button from '@/components/Form/Button';
import QuoteHelper from '@/lib/QuoteHelper';

window.FontAwesomeConfig = {
  searchPseudoElements: true,
};

export default {
  components: {
    Button,
  },
  data() {
    return {
      tips: [],
      editingQuote: false,
      openTab: 1,
      loading: false,
      quotes: {
        hp: null,
        pcp: null,
        cached: false,
      },
      form: {
        mileage: {
          value: null,
        },
        DepositCash: {
          value: 0,
        },
        PartExchange: {
          value: 0,
        },
        OutstandingSettlement: {
          value: 0,
        },
        QuoterAPR: {
          value: 9.9,
          // Keep if needed later on
          // options: [
          //     { label: 'Perfect 7.9%', value: 7.9 },
          //     { label: 'Very good 9.9%', value: 9.9 },
          //     { label: 'Fair 16.9%', value: 16.9 },
          //     { label: 'Poor 24.9%', value: 24.9 },
          //     { label: 'Very poor 29.9%', value: 29.9 }
          // ]
        },
        Term: {
          value: 48,
          options: [], // Populated in the mounted() hook
        },
        AnnualMileage: {
          options: [],
          value: null,
        },
      },
      selectedQuote: null,
    };
  },

  computed: mapState(['dashboard', 'publicCalculator', 'dealer']),

  methods: {
    ...Api,

    /**
     * Saves the current quote figures and vehicle locally and pushes user to the journey.
     * @returns {Promise<void>}
     */
    async saveAnonVehicle() {
      // Keep if needed later on
      // Save the figures for the public facing calculator (if they return to it)
      // this.$store.commit('setCalculationFigures', this.mapFigures())

      // Save the vehicle as "recently viewed" (currently only supports single vehicle)
      if (this.vehicle.quick_code) {
        this.$store.commit('setRecentlyViewedPublicVehicle', this.vehicle);
      }

      // Proceed to the journey
      await this.$router.push({ name: 'personal-details' });
    },

    /**
     * Returns true if a vehicle has been saved with a valid CAP code
     * @returns {boolean}
     */
    hasSelectedVehicle() {
      return (
        this.publicCalculator.vehicle !== null &&
        typeof this.publicCalculator.vehicle.cap_code !== 'undefined' &&
        this.publicCalculator.vehicle.cap_code !== null
      );
    },

    /**
     * Maps the form data to a compatible object to store in Vuex
     * @returns {object}
     */
    mapFigures() {
      return {
        CurrentMileage: this.form.mileage.value ?? 0,
        AnnualMileage: this.form.AnnualMileage.value ?? 0,
        CashPrice: this.vehicle.price ?? 0,
        Term: this.form.Term.value ?? 48,
        QuoterAPR: this.form.QuoterAPR.value ?? 9.9,
        PartExchange: this.form.PartExchange.value ?? 0,
        OutstandingSettlement: this.form.OutstandingSettlement.value ?? 0,
        DepositCash: this.form.DepositCash.value ?? 0,
        QuickCode: this.vehicle.quick_code,
      };
    },

    async applyForFinance(quoteResult) {
      try {
        // Submit the application and quote
        const completeApplication = await this.apiCompleteApplication(
          this.dashboard.application.reference,
          quoteResult.id
        );

        // Done, refresh the application status so the dashboard updates immediately
        await this.refreshApplication();

        alert('All done!');
      } catch (e) {
        alert('There was a problem...');
        console.error(e);
      }
    },

    async apiCompleteApplication(applicationReference, quoteResultId) {
      return axios
        .post(`${this.getApiBaseUrl()}/v1/applications/${applicationReference}/submit.json`, {
          quote_result_id: quoteResultId,
        })
        .then((r) => {
          return r.data.success;
        })
        .catch(() => {
          return false;
        });
    },

    /**
     * Configure est. annual mileage options
     */
    getAnnualMileageOptions() {
      const start = 5000;
      const to = 40000;
      const increment = 1000;

      for (let i = start; i <= to; i += increment) {
        this.form.AnnualMileage.options.push({
          value: i,
          label: i.toLocaleString('en-GB'),
        });
      }
      this.form.AnnualMileage.value = 10000;
    },

    /**
     * Toggle tabs
     */
    toggleTabs(tabNumber) {
      this.openTab = tabNumber;
    },

    /**
     * Toggle accordion
     */
    toggle(event) {
      if (this.multiple) this.item.active = !this.item.active;
      else {
        this.$parent.$children.forEach((item) => {
          if (item.$el.id === event.currentTarget.parentElement.parentElement.id)
            item.item.active = !item.item.active;
          else item.item.active = false;
        });
      }
    },

    startTransition(el) {
      el.style.height = el.scrollHeight + 'px';
    },

    endTransition(el) {
      el.style.height = '';
    },

    toDec(int) {
      return (int / 100).toLocaleString('en-GB', { minimumFractionDigits: 2 });
    },

    async newQuote() {
      this.loading = true;

      // Get a new quote that isn't cached based on the form values
      await this.getQuote(false);

      // Hide the full screen editing quote overlay
      this.editingQuote = false;

      this.loading = false;
    },

    async getQuote(cached = true) {
      this.loading = true;
      this.tips = [];

      // Set initial payload data (to save locally)
      let payload = {
        PartExchange: this.form.PartExchange.value ?? 0,
        OutstandingSettlement: this.form.OutstandingSettlement.value ?? 0,
        DepositCash: this.form.DepositCash.value ?? 0,
        Term: this.form.Term.value ?? 48,
        AnnualMileage: this.form.AnnualMileage.value ?? 10000,
      };

      // If they're logged in, use their estimate APR, otherwise use the form value
      if (this.dashboard.authed) {
        payload.QuoterAPR = this.getApr() ?? this.defaultApr();
      } else {
        payload.QuoterAPR = this.form.QuoterAPR.value;
      }

      // Keep if needed later on
      // this.$store.commit('setCalculationFigures', payload)

      // Saved all the data we need locally so time to add the missing payload data before posting
      payload = {
        ...payload,
        CashPrice: this.vehicle.price,
        _reference: this.dashboard.application.reference ?? null,
        _cached: cached,
      };

      await axios
        .post(
          `${this.getApiBaseUrl()}/v1/vehicle-feed/vehicle/${this.vehicle.quick_code}/quote.json`,
          payload
        )
        .then((r) => {
          if (r.data.success) {
            this.quotes = r.data.data;

            // Did we get any quote results?
            if (this.quotes.hp === null && this.quotes.pcp === null) {
              // Get the registration date
              const vehicleRegDate = this.vehicle.vehicle_registration_date ?? this.vehicle.year;

              const quoteHelper = new QuoteHelper(
                payload.AnnualMileage,
                payload.CashPrice,
                payload.Term,
                payload.QuoterAPR,
                payload.PartExchange,
                payload.OutstandingSettlement,
                payload.DepositCash,
                this.vehicle.miles,
                false,
                vehicleRegDate ?? null,
                false
              );
              this.tips = quoteHelper.getTips();
            }

            this.refreshTabs();
            return;
          }

          throw new Error(r.data.message ?? 'Unknown error');
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Refreshes the tabs to make sure their displaying correctly based on
     * the data that's available
     */
    refreshTabs() {
      if (this.quotes.hp !== null && this.quotes.pcp !== null) {
        return this.toggleTabs(1);
      }

      if (this.quotes.hp !== null && this.quotes.pcp === null) {
        return this.toggleTabs(2);
      }

      // Signal to the parent component that we've re-quoted.
      this.$emit('quoted');
    },
  },

  props: {
    vehicle: {
      type: [Object, null],
      default: null,
    },
  },

  mounted() {
    // Get the term drop down options
    this.form.Term.options = this.getTermOptions();

    this.getAnnualMileageOptions();

    // 15% deposit of vehicle cash price
    const defaultDeposit = 0.15 * this.vehicle.price;

    // Restore their details from storage if they've got any
    if (this.dashboard.authed) {
      this.form.mileage.value = this.publicCalculator.figures.CurrentMileage ?? 0;
      this.form.DepositCash.value = this.publicCalculator.figures.DepositCash ?? defaultDeposit;
      this.form.PartExchange.value = this.publicCalculator.figures.PartExchange ?? 0;
      this.form.OutstandingSettlement.value =
        this.publicCalculator.figures.OutstandingSettlement ?? 0;
      this.form.QuoterAPR.value = this.publicCalculator.figures.QuoterAPR ?? this.defaultApr();
      this.form.Term.value = this.publicCalculator.figures.Term ?? 48;
      this.form.AnnualMileage.value = this.publicCalculator.figures.AnnualMileage ?? 10000;
    } else {
      this.form.DepositCash.value = defaultDeposit;
    }

    this.getQuote();
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;

  li {
    position: relative;
    padding-left: 20px;

    &::before {
      content: '';
      height: 7px;
      width: 7px;
      border-radius: 100%;
      margin-right: 10px;
      position: absolute;
      left: 0;
      top: 7px;
      @apply bg-white;
    }
  }
}
</style>
