<template>
  <section class="applications">
    <vue-headful
      description="At Flow car Finance we think car finance should be fair, simple & sincere. With over 30 years of knowledge, expertise and listening to you, we came up with an effortless car, van or motorcycle buying experience."
      url="https://flowcarfinance.co.uk/applications"
    />

    <Banner
      banner_title="Review Employment History"
      banner_subtitle="We would like to confirm a few details before creating a new application, this will not take long."
    />

    <main class="bg-gray-100">
      <div class="max-w-lg mx-auto w-full px-6 lg:px-8 py-12">
        <section class="">
          <h3 v-if="form.employments.value.length">Your employment history</h3>

          <!-- Need more history... -->
          <div v-show="!valid" class="bg-red-500 text-white p-6 mb-5">
            <div class="grid grid-cols-3 gap-4 null">
              <div class="col-span-6">
                <h4 class="font-bold text-xl mb-2 text-white">We need a bit more history</h4>
                <p class="mb-0">
                  We need at least
                  {{
                    form.employments.options.minHistoryMonths > 0
                      ? form.employments.options.minHistoryMonths / 12 + ' years'
                      : form.employments.options.minHistoryMonths + ' months'
                  }}
                  of employment history to continue.
                </p>
              </div>
            </div>
          </div>

          <div class="grid grid-rows-1 divide-y divide-gray-300 text-gray-600">
            <figure
              v-for="(employment, i) in form.employments.value"
              :key="i"
              class="py-3 relative w-full flex"
            >
              <div class="flex-1">
                <div class="w-full">
                  {{ employment.occupation }}
                  <template v-if="employment.employer">at {{ employment.employer }}</template>
                </div>
                <strong>Since {{ employment.started | moment('DD/MM/YYYY') }}</strong>
              </div>
              <div class="flex items-center items-end">
                <span
                  v-if="!employment.locked"
                  @click="removeEmployment(employment.id, i)"
                  class="hover:dealer-bg-secondary cursor-pointer dealer-bg-primary text-white p-2 rounded-full w-8 h-8 flex justify-center items-center"
                >
                  <i class="fas fa-trash-alt remove-address text-md"></i>
                </span>
              </div>
            </figure>
          </div>
        </section>
        <section class="form mt-3">
          <h3>Need to add more?</h3>
          <EmploymentFinder
            :min-history-months="36"
            @historyAdded="addEmploymentHistory"
            :addressCount="form.employments.value.length"
          />

          <!-- Annual income field -->
          <ValidationObserver
            ref="observer"
            tag="div"
            class="employment"
            v-slot="{ passes, invalid }"
          >
            <div class="field" v-if="form.employments.value.length">
              <ValidationProvider
                tag="div"
                :rules="form.income.rules"
                v-slot="{ errors }"
                name="Salary"
              >
                <label for="salary">Your annual income before tax</label>
                <TextInputIcon
                  id="salary"
                  icon="fas fa-pound-sign"
                  name="salary"
                  v-model="form.income.value"
                  :is-number="true"
                  @change="form.income.dirty = true"
                />

                <span
                  v-if="form.income.value !== 0 && form.income.value < 12000 && form.income.dirty"
                  >Is this <strong>annual income before tax</strong> correct? &pound;{{
                    formatPrice(form.income.value)
                  }}</span
                >
                <div v-if="errors.length" class="text-red-500">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>

            <p class="bg-gray-300 px-5 text-sm py-4 flex justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 mr-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
                />
              </svg>
              Please confirm if your employment status is correct
            </p>

            <div v-if="error" class="bg-red-500 text-white px-5 py-4 mb-5">
              {{ error }}
            </div>

            <div class="form-actions flex items-center">
              <div
                class="flex cursor-pointer space-x-2 text-flow-blue hover:text-flow-teal"
                @click="$router.push({ name: 'applications__review' })"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>
                <span>Previous</span>
              </div>
              <button
                :disabled="!valid"
                :class="{ 'opacity-50': !valid }"
                class="dealer-btn-primary ml-auto hover:dealer-text-primary"
                @click="save"
              >
                Save
              </button>
            </div>
          </ValidationObserver>
        </section>
      </div>
    </main>
  </section>
</template>

<script>
import Banner from '@/components/Ui/Applications/Banner';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import EmploymentFinder from '@/components/Form/EmploymentFinder';
import TextInputIcon from '@/components/Form/TextInputIcon';
import axios from 'axios';
import Api from '@/lib/Api';
import methods from '@/lib/methods';
import Bugsnag from '@bugsnag/js';
import moment from 'moment';

export default {
  name: 'ReviewEmploymentHistory',
  components: {
    Banner,
    ValidationObserver,
    ValidationProvider,
    EmploymentFinder,
    TextInputIcon,
  },

  computed: {
    ...mapState(['dashboard']),
  },

  data() {
    return {
      error: null,
      valid: false,
      form: {
        income: {
          value: null,
          rules: 'required|numeric',
          dirty: false,
        },

        employments: {
          value: [],
          options: {
            minHistoryMonths: 36,
          },
        },
      },
    };
  },

  methods: {
    ...Api,

    /**
     * Returns a human readable string based on an employment.
     * e.g. Carpenter (Acme Builders)
     *      Unknown occupation (ABC Corp)
     * @param employment
     * @returns {string|*}
     */
    getEmploymentSummaryText(employment) {
      let str = employment.occupation ?? 'Unknown occupation';
      if (employment.employer) {
        str += ' (' + employment.employer + ')';
      }
      return str;
    },

    /**
     * Adds comma separators to a number
     * @param {Number} value The value to format
     */
    formatPrice(value) {
      let val = value.toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    async addEmploymentHistory(employmentData) {
      const newEmployment = await this.apiStoreEmployment(
        this.dashboard.applicant.id,
        employmentData
      );

      // Add to the front-end
      this.form.employments.value.push(newEmployment);

      // Re-order the employments chronologically
      this.form.employments.value.sort((a, b) => {
        return new Date(b.started) - new Date(a.started);
      });

      // Do we have enough history yet?
      this.validate();
    },

    /**
     * Stores an employment history against an applicant (API & Store)
     * @param {Number} applicantId
     * @param {Object} employment
     */
    apiStoreEmployment(applicantId, employment) {
      this.$emit('loading', true, 'Saving...');
      this.error = null;

      return axios
        .post(this.getApiBaseUrl() + '/applicants/' + applicantId + '/employments', {
          employment: employment,
        })
        .then((r) => {
          if (r.data.success && r.data.data?.employment?.id) {
            return {
              ...employment,
              id: r.data.data.employment.id,
            };
          }

          throw new Error(`API failed to save address for ${applicantId}`);
        })
        .catch((e) => {
          Bugsnag.notify(e);
          console.warn(e);
          this.error =
            'There was a problem saving your employment. If this issue continues, please get in touch.';
        })
        .finally(() => {
          this.$emit('loading', false);
        });
    },

    /**
     * Updated an applicant
     * @param {String} applicationRef Application's reference ID
     * @param {Number} applicantId ID of the applicant to update
     * @param {Object} applicantData Data to push
     */
    async apiUpdateApplicant(applicationRef, applicantId, applicantData) {
      this.error = null;
      return axios
        .patch(
          this.getApiBaseUrl() + '/applications/' + applicationRef + '/applicants/' + applicantId,
          {
            applicant: applicantData,
          }
        )
        .then((r) => {
          if (r.data.success) {
            return true;
          }
          throw new Error(r.data.errors);
        })
        .catch((e) => {
          Bugsnag.notify(e);
          this.error =
            'There was a problem updating your income. If this issue continues, please get in touch.';
        });
    },

    /**
     * Submits the form if there's enough employment history and
     * no Vee Validate errors
     */
    async save() {
      this.error = null;
      this.$emit('loading', true, 'Saving...');

      try {
        // Update the applicant's income
        const applicantPayload = {
          gross_income: this.form.income.value,
          gross_income_frequency: 'Yearly',
        };
        await this.apiUpdateApplicant(
          this.dashboard.application.reference,
          this.dashboard.applicant.id,
          applicantPayload
        );

        // Store in Vuex
        this.$store.commit('setDashboardApplicant', {
          ...this.dashboard.applicant,
          ...applicantPayload,
        });

        // Next step
        this.$emit('loading', false);
        return await this.$router.push({ name: 'applications__review' });
      } catch (e) {
        Bugsnag.notify(e);
        this.error =
          'There was a problem updating your employment history. If this problem continues, please get in touch.';
      }
    },

    restore() {
      this.$emit('loading', true, 'Getting your employment history');
      this.error = null;

      // Restore income if available
      if (this.dashboard.applicant.gross_income) {
        this.form.income.value = this.dashboard.applicant.gross_income;
      }

      return axios
        .get(`${methods.getApiBaseUrl()}/applicants/${this.dashboard.applicant.id}/employments`)
        .then((r) => {
          if (r.data.success) {
            this.form.employments.value = r.data.data.items;
            this.$emit('loading', false);
            return;
          }
          throw new Error(
            `API failed to return applicant's employment history (${this.dashboard.applicant.id})`
          );
        })
        .catch((e) => {
          this.$emit('loading', false);
          Bugsnag.notify(e);
          this.error =
            'There was a problem retrieving your employment history. If this problem continues, please get in touch.';
        });
    },

    /**
     * Removes the address from the front-end and API
     * @param employmentId
     * @param index
     * @returns {Promise<void>}
     */
    async removeEmployment(employmentId, index) {
      const employmentRemoved = await this.apiRemoveEmployment(
        this.dashboard.applicant.id,
        employmentId
      );

      if (this.form.employments.value[index] && employmentRemoved) {
        this.form.employments.value.splice(index, 1);
      }

      this.validate();
    },

    /**
     * Removes an address (API & Store)
     * @param {Number} applicantId
     * @param employmentId
     */
    apiRemoveEmployment(applicantId, employmentId) {
      this.$emit('loading', true, 'Removing employment');
      this.error = null;
      return axios
        .delete(
          this.getApiBaseUrl() + '/applicants/' + applicantId + '/employments/' + employmentId
        )
        .then((r) => {
          return r.data.success;
        })
        .catch((e) => {
          Bugsnag.notify(e);
          this.error =
            'There was a problem removing that employment. If the problem continues, please get in touch.';
        })
        .finally(() => {
          this.$emit('loading', false);
        });
    },

    /**
     * Returns true if we have enough address history
     * @param employments
     */
    isEmploymentHistoryEnough(employments = []) {
      const now = moment().startOf('month');
      return (
        employments.filter((employment) => {
          return (
            now.diff(moment(employment.started), 'months') >=
            this.form.employments.options.minHistoryMonths
          );
        }).length > 0
      );
    },

    /**
     * Validate the history
     */
    validate() {
      this.valid = this.isEmploymentHistoryEnough(this.form.employments.value);
    },
  },

  async mounted() {
    // Restore employment history
    await this.restore();

    this.validate();
  },
};
</script>

<style scoped></style>
