<template>
  <!-- Card Start HP -->
  <div v-if="result !== null" class="max-w-2xl w-full">
    <div class="rounded-xxl shadow-lg overflow-hidden w-full shadow-md">
      <!-- Card header -->
      <div
        class="border-b-1 border-gray-200 bg-white inline-flex items-center w-full px-5 sm:px-6 py-5 sm:py-8 text-flow-blue dealer-text-secondary sm:p-8 sm:pb-6"
      >
        <div class="sm:w-24">
          <div
            class="flex items-center w-16 h-16 sm:h-24 sm:w-24 px-auto rounded-full text-2x1 sm:text-3xl leading-5 font-semibold tracking-wide uppercase bg-flow-teal dealer-bg-primary text-white"
          >
            <span v-if="type === 'hp'" class="mx-auto">HP</span>
            <span v-if="type === 'pcp'" class="mx-auto">PCP</span>
          </div>
        </div>
        <div class="ml-4 sm:ml-0 w-full">
          <div
            class="hidden xs:block mb-2 sm:px-6 flex-auto items-baseline text-sm sm:text-lg text-flow-blue dealer-text-secondary uppercase"
          >
            <template v-if="type === 'hp'"> Hire Purchase </template>
            <template v-if="type === 'pcp'"> Personal Contract Purchase </template>
          </div>
          <div
            class="sm:px-6 flex-auto items-baseline text-3xl lg:text-5xl leading-none font-extrabold"
          >
            <span>
              {{ (result.monthly_payment / 100) | toGbp }}
            </span>
            <span class="text-sm lg:text-xl leading-8 font-medium"> /mo</span>

            <!-- TODO! -->
            <span
              v-if="type === 'hp'"
              class="float-right text-flow-blue dealer-text-secondary hover:dealer-text-secondary cursor-pointer"
              @click="show('hpInfoModal')"
              ><i
                style="width: 2rem; height: 2rem; top: -2px"
                class="fal fa-question-circle relative"
              ></i
            ></span>

            <span
              v-if="type === 'pcp'"
              class="float-right text-flow-blue dealer-text-secondary hover:dealer-text-secondary cursor-pointer"
              @click="show('pcpInfoModal')"
              ><i
                style="width: 2rem; height: 2rem; top: -2px"
                class="fal fa-question-circle relative"
              ></i
            ></span>

            <modal
              name="hpInfoModal"
              styles="border-radius:1rem;"
              classes="z-10 p-8 text-white leading-5 dealer-bg-primary"
              :adaptive="true"
              height="auto"
              :scrollable="true"
            >
              <div class="h-10 block w-full text-right">
                <span
                  class="float-right flex items-center cursor-pointer"
                  @click="$modal.hide('hpInfoModal')"
                >
                  <i class="fas fa-times fa-xs"></i>
                  <span class="ml-3 uppercase text-base">Close</span>
                </span>
              </div>
              <div class="text-base p-2 font-normal" v-html="hpInfo" />
            </modal>

            <modal
              name="pcpInfoModal"
              styles="border-radius:1rem;"
              classes="z-10 p-8 text-white leading-5 dealer-bg-primary"
              :adaptive="true"
              height="auto"
              :scrollable="true"
            >
              <div class="h-10 block w-full text-right">
                <span
                  class="float-right flex items-center cursor-pointer"
                  @click="$modal.hide('pcpInfoModal')"
                >
                  <i class="fas fa-times fa-xs"></i>
                  <span class="ml-3 uppercase text-base">Close</span>
                </span>
              </div>

              <div class="text-base p-2 font-normal" v-html="pcpInfo" />
            </modal>
          </div>
        </div>
      </div>

      <!-- Car detail -->
      <div
        v-if="vehicle"
        class="flex-1 flex flex-col justify-between bg-white border-b-1 border-gray-200 space-y-2 sm:p-7 p-6"
      >
        <div class="">
          <ul class="xs:grid xs:grid-cols-2 md:gap-x-4 xl:mt-0 xl:col-span-2">
            <li class="py-2 flex items-center space-x-3 md:pb-2">
              <font-awesome-icon
                :icon="['fal', 'car-side']"
                class="dealer-text-secondary text-3xl sm:text-2xl fa-fw"
              />
              <div class="text-flow-blue dealer-text-secondary xs:text-sm sm:text-xs md:text-base">
                {{ vehicle.make }}
              </div>
            </li>
            <li class="py-2 flex items-center space-x-3 md:pb-2">
              <font-awesome-icon
                :icon="['fal', 'steering-wheel']"
                class="dealer-text-secondary text-3xl sm:text-2xl fa-fw"
              />
              <div class="text-flow-blue dealer-text-secondary xs:text-sm sm:text-xs md:text-base">
                {{ vehicle.model }}
              </div>
            </li>
            <li class="py-2 flex items-center space-x-3 md:pb-2">
              <font-awesome-icon
                :icon="['fal', 'calendar-alt']"
                class="dealer-text-secondary text-3xl sm:text-2xl fa-fw"
              />
              <div class="text-flow-blue dealer-text-secondary xs:text-sm sm:text-xs md:text-base">
                Registered in {{ vehicle.first_registered | moment('YYYY') }}
              </div>
            </li>
            <li v-if="vehicle.vrm" class="py-2 flex items-center space-x-3 md:pb-2">
              <font-awesome-icon
                :icon="['fas', 'ellipsis-h']"
                class="dealer-text-secondary text-3xl sm:text-2xl fa-fw"
              />
              <div
                class="text-flow-blue dealer-text-secondary xs:text-sm sm:text-xs md:text-base uppercase"
              >
                {{ vehicle.vrm }}
              </div>
            </li>
            <li v-if="figures.current_mileage" class="py-2 flex items-center space-x-3 md:pb-2">
              <font-awesome-icon
                :icon="['fal', 'tachometer-alt']"
                class="dealer-text-secondary text-3xl sm:text-2xl fa-fw"
              />
              <div class="text-flow-blue dealer-text-secondary xs:text-sm sm:text-xs md:text-base">
                Current mileage: {{ figures.current_mileage | thousands }}
              </div>
            </li>
            <li class="py-2 flex items-center space-x-3 md:pb-2">
              <font-awesome-icon
                :icon="['fal', 'road']"
                class="dealer-text-secondary text-3xl sm:text-2xl fa-fw"
              />
              <div class="text-flow-blue dealer-text-secondary xs:text-sm sm:text-xs md:text-base">
                {{ figures.estimated_mileage | thousands }} miles/year est.
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- <FlowButton @click="show('emailModal')" isOutBlue class="text-center mr-6 ml-auto"  icon="envelope" /> -->

      <div
        class="align-middle py-6 px-8 w-full bg-white inline-flex items-center"
        v-if="allowApply()"
      >
        <button @click="applyForFinance(result, true)" class="dealer-btn-primary mx-auto">
          Apply
        </button>
      </div>
      <div
        class="align-middle py-6 px-8 w-full bg-white inline-flex items-center"
        v-else-if="isPrime()"
      >
        You have already applied for finance. To make changes to your account, please chat with or
        call us.
      </div>
      <div class="align-middle py-6 px-8 w-full bg-white inline-flex items-center" v-else>
        <button @click="show('nonPrimeModal')" class="dealer-btn-primary mx-auto">Apply</button>
      </div>

      <div
        class="rounded-b-lg flex-1 flex flex-col justify-between bg-flow-blue dealer-bg-secondary space-y-2 sm:p-7 p-6"
      >
        <!-- Finance detail -->
        <div>
          <ul class="sm:grid sm:grid-cols-2 md:gap-x-4 xl:mt-0 xl:col-span-2">
            <li class="flex items-center space-x-3 md:pb-2">
              <div class="flex-shrink-0">
                <i class="fas fa-circle w-3 h-3 text-flow-teal dealer-text-primary"></i>
              </div>
              <div class="text-white sm:text-sm">
                <span v-if="type === 'hp'" class="font-bold">Hire Purchase</span>
                <span v-if="type === 'pcp'" class="font-bold">Personal Contract Purchase</span>
              </div>
            </li>
            <li class="flex items-center space-x-3 md:pb-2">
              <div class="flex-shrink-0">
                <i class="fas fa-circle w-3 h-3 text-flow-teal dealer-text-primary"></i>
              </div>
              <div class="text-white sm:text-sm">
                <span class="font-bold">{{ result.term }}</span> months
              </div>
            </li>
            <li class="flex items-center space-x-3 md:pb-2">
              <div class="flex-shrink-0">
                <i class="fas fa-circle w-3 h-3 text-flow-teal dealer-text-primary"></i>
              </div>
              <div class="text-white sm:text-sm">
                <span class="font-bold">
                  {{ (result.cash_price / 100) | toGbp }}
                </span>
                Cash Price
              </div>
            </li>
            <li class="flex items-center space-x-3 md:pb-2">
              <div class="flex-shrink-0">
                <i class="fas fa-circle w-3 h-3 text-flow-teal dealer-text-primary"></i>
              </div>
              <div class="text-white sm:text-sm">
                <span class="font-bold">{{ (result.net_deposit / 100) | toGbp }}</span>
                Deposit
              </div>
            </li>
            <!--<li class="flex items-center space-x-3 md:pb-2">-->
            <!--    <div class="flex-shrink-0"><i class="fas fa-circle w-3 h-3 text-flow-teal dealer-text-primary"></i></div>-->
            <!--    <div class="text-white sm:text-sm">-->
            <!--        <span class="font-bold">{{ creditAmount | toGbp }}</span>-->
            <!--        Credit Amount-->
            <!--    </div>-->
            <!--</li>-->
            <li class="flex items-center space-x-3 md:pb-2">
              <div class="flex-shrink-0">
                <i class="fas fa-circle w-3 h-3 text-flow-teal dealer-text-primary"></i>
              </div>
              <div class="text-white sm:text-sm">
                <span class="font-bold"> {{ result.apr }}% </span>
                APR
              </div>
            </li>
            <li class="flex items-center space-x-3 md:pb-2">
              <div class="flex-shrink-0">
                <i class="fas fa-circle w-3 h-3 text-flow-teal dealer-text-primary"></i>
              </div>
              <div class="text-white sm:text-sm">
                <span class="font-bold">
                  {{ (result.total_charge / 100) | toGbp }}
                </span>
                Total Cost of Credit
              </div>
            </li>
            <li class="flex items-center space-x-3 md:pb-2">
              <div class="flex-shrink-0">
                <i class="fas fa-circle w-3 h-3 text-flow-teal dealer-text-primary"></i>
              </div>
              <div class="text-white sm:text-sm">
                <span class="font-bold">
                  {{ (result.total_payable / 100) | toGbp }}
                </span>
                Total Payable
              </div>
            </li>
            <!--<li class="flex items-center space-x-3 md:pb-2">-->
            <!--    <div class="flex-shrink-0"><i class="fas fa-circle w-3 h-3 text-flow-teal dealer-text-secondary"></i></div>-->
            <!--    <div class="text-white sm:text-sm">-->
            <!--        <span class="font-bold">-->
            <!--            {{ result.flat_rate.toLocaleString('en-GB', {maximumFractionDigits: 2}) }}%-->
            <!--        </span>-->
            <!--        Flat Rate-->
            <!--    </div>-->
            <!--</li>-->
            <li v-if="result.residual_value" class="flex items-center space-x-3 md:pb-2">
              <div class="flex-shrink-0">
                <i class="fas fa-circle w-3 h-3 text-flow-teal dealer-text-primary"></i>
              </div>
              <div class="text-white sm:text-sm">
                <span class="font-bold">
                  {{ (result.residual_value / 100) | toGbp }}
                </span>
                Future Value
              </div>
            </li>
            <li class="flex items-center space-x-3 md:pb-2">
              <div class="flex-shrink-0">
                <i class="fas fa-circle w-3 h-3 text-flow-teal dealer-text-primary"></i>
              </div>
              <div class="text-white sm:text-sm">
                <span class="font-bold">
                  {{ (result.final_payment / 100) | toGbp }}
                </span>
                Final Payment
              </div>
            </li>
            <li v-if="result.excess_mileage_rate" class="flex items-center space-x-3 md:pb-2">
              <div class="flex-shrink-0">
                <i class="fas fa-circle w-3 h-3 text-flow-teal dealer-text-primary"></i>
              </div>
              <div class="text-white sm:text-sm">
                <span class="font-bold"> {{ result.excess_mileage_rate / 100 }}p/mile </span>
                Excess mileage rate
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <modal
      name="nonPrimeModal"
      id="nonPrimeModal"
      styles="border-radius:1rem;"
      classes="info-modal"
      height="auto"
      :max-width="500"
      width="90%"
      :adaptive="true"
    >
      <div class="p-8 text-white bg-flow-blue dealer-bg-secondary">
        <div class="relative flex-auto">
          <h3 class="leading-relaxed text-flow-teal dealer-text-primary">
            Are we nearly there yet?
          </h3>

          <div class="w-full">
            <p class="mt-4 text-lg leading-relaxed">
              We're checking for the best APR rate for you, so you get our very best deal. This
              usually takes a few minutes, and we'll text you when it's done. Then you can add a
              car, view your personalised deal and hit apply. This will come into our team and
              they'll contact you to get things motoring!
            </p>
            <p v-if="!dealer.name" class="mt-4 text-lg leading-relaxed">
              After 8 pm in the evening, some of our lenders are closed. If this is the case, we'll
              let you know first thing in the morning.
            </p>
          </div>
        </div>

        <!--footer-->
        <div class="flex pb-6 pt-3 rounded-b">
          <button
            @click="$modal.hide('nonPrimeModal')"
            class="close-btn flex items-center justify-center h-12 w-12 rounded-full hover:bg-gray-700 hover:text-white border-2 border-flow-teal dealer-border-primary text-white"
            type="button"
            style="transition: all 0.15s ease"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="times"
              class="svg-inline--fa fa-times fa-w-11"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 352 512"
            >
              <path
                fill="currentColor"
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
              ></path>
            </svg>
          </button>

          <span class="relative z-0 inline-flex shadow-sm rounded-md ml-auto fa-2x">
            <button
              @click="$router.push({ name: 'home' })"
              class="flex items-center justify-center h-12 w-12 rounded-full hover:bg-gray-700 hover:text-white border-2 border-flow-teal dealer-border-primary text-white"
              type="button"
              style="transition: all 0.15s ease"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="home"
                class="svg-inline--fa fa-home fa-w-11"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 536 512"
              >
                <path
                  fill="currentColor"
                  d="M541 229.16l-61-49.83v-77.4a6 6 0 0 0-6-6h-20a6 6 0 0 0-6 6v51.33L308.19 39.14a32.16 32.16 0 0 0-40.38 0L35 229.16a8 8 0 0 0-1.16 11.24l10.1 12.41a8 8 0 0 0 11.2 1.19L96 220.62v243a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-128l64 .3V464a16 16 0 0 0 16 16l128-.33a16 16 0 0 0 16-16V220.62L520.86 254a8 8 0 0 0 11.25-1.16l10.1-12.41a8 8 0 0 0-1.21-11.27zm-93.11 218.59h.1l-96 .3V319.88a16.05 16.05 0 0 0-15.95-16l-96-.27a16 16 0 0 0-16.05 16v128.14H128V194.51L288 63.94l160 130.57z"
                ></path>
              </svg>
            </button>
          </span>
        </div>
      </div>
    </modal>
  </div>
  <div v-else class="text-center">
    <div class="rounded-xxl overflow-hidden w-full shadow-md text-center py-15 px-10 h-full flex">
      <h3 class="self-center">
        Sorry, there weren't any <template v-if="type === 'hp'">hire purchase</template
        ><template v-if="type === 'pcp'">personal contract purchase</template> results for this
        quote.
      </h3>
    </div>
  </div>
  <!-- Card End -->
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    figures: {
      type: Object,
      required: true,
    },
    vehicle: {
      type: Object,
      required: true,
    },
    result: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
  },

  computed: {
    // TODO: Fix this! (returning NaN)
    creditAmount() {
      return (
        parseFloat(this.figures.cash_price / 100) +
        parseFloat(this.figures.outstanding_settlement / 100) -
        parseFloat(this.figures.deposit_cash / 100) -
        parseFloat(this.figures.part_exchange / 100)
      );
    },
    ...mapState(['dashboard', 'dealer']),
  },

  data() {
    return {
      pcpInfo:
        '<p class="mb-4">PCP is the most popular type of car finance because it allows you the freedom to have low monthly payments for nearly new or used cars since you’re not paying the total cost of the car.</p><h4 class="text-white">1) Deposit & Delivery</h4><p class="mb-4">The more you pay as a deposit, the lower your monthly payments will be. We also have zero deposit options available.</p><h4 class="text-white">2) Monthly Payments</h4><p class="mb-4">Monthly payments are fixed and are low due to the fact that you\'re only repaying a part of the car\'s cost.</p><h4 class="text-white">3) Return, Buy or Upgrade</h4><p class="mb-4">At the end of your contract, you can return the car and owe us nothing, provided the car is in good condition and under the pre-arranged mileage limit.</p><p class="mb-4">If you want to keep the car, you can refinance it and pay the remaining balance to own the car.</p><p class="mb-4>You can opt-in to exchange the car, if the value of the car is worth more than the remaining balance, this is called equity. You can use this equity towards a deposit on a new PCP contract, which will help you to pay even lower monthly repayments on your next car.</p>',
      hpInfo:
        '<p class="mb-4">HP splits the cost of a new or used car into monthly installments of small payments. Since the payment covers the total cost of the car,  the monthly payments are higher than on a PCP agreement, but the interest charges per year are usually lower.</p><h4 class="text-white">1) Deposit & Delivery</h4><p class="mb-4">Paying a deposit upfront reduces the final total cost of the car and also lowers your monthly payments. Zero deposit options are usually available.</p><h4 class="text-white">2) Monthly Payments</h4><p class="mb-4">You then pay for the rest of the car with a series of monthly payments that are fixed covering the length of the car finance agreement.</p><h4 class="text-white">3) You Now Own the Car</h4><p class="mb-4">Once you\'ve paid the final installment of your monthly payments, the car legally belongs to you now.</p>',
    };
  },

  methods: {
    show(name) {
      this.$modal.show(name);
    },
    hide() {
      this.$modal.hide('pcpModal');
      this.$modal.hide('hpModal');
    },

    async applyForFinance(quoteResult, allowMultipleSubmissions = false) {
      this.$emit('apply', quoteResult, allowMultipleSubmissions);
    },
  },
};
</script>
