<template>
  <div class="px-5 py-6 sm:p-7 border-3 border-gray-600 rounded-xxl shadow-md">
    <div class="inline-flex items-center w-full">
      <div
        class="col-span-1"
        :class="{
          'sm:order-last mr-5 sm:mr-0 sm:ml-auto': align == 'right',
          'sm:order-first mr-5': align == 'left',
        }"
      >
        <div
          v-if="tip.passed"
          class="border-flow-grey dealer-border-primary sm:bg-transparent border-5 text-xl sm:text-2xl rounded-full h-12 w-12 sm:h-32 sm:w-32 flex items-center justify-center text-flow-blue dealer-text-secondary font-bold"
        >
          <span class="capitalize hidden sm:block">Pass</span>
          <i class="fas fa-check block sm:hidden"></i>
        </div>
        <div
          v-else
          class="border-red-500 bg-red-500 sm:bg-transparent border-5 text-xl sm:text-2xl rounded-full h-12 w-12 sm:h-32 sm:w-32 flex items-center justify-center dealer-text-secondary font-bold"
        >
          <span class="capitalize hidden sm:block">Fail</span>
          <i class="fas fa-times block sm:hidden"></i>
        </div>
      </div>
      <div class="col-span-2 sm:mr-5">
        <h4
          class="text-xl sm:text-2xl font-bold text-flow-blue dealer-text-secondary"
          v-html="tip.fibre_evaluation_rule.description_name"
        />

        <div class="mt-1 text-sm sm:text-md sm:text-lg text-flow-blue dealer-text-secondary">
          <div v-if="tip.passed">
            {{ tip.fibre_evaluation_rule.description_positive }}
          </div>
          <div v-else>
            {{ tip.fibre_evaluation_rule.description_negative }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tip: {
      type: Object,
      required: true,
    },
    align: {
      type: String,
      default: 'left',
    },
  },

  methods: {
    /**
     * Returns a colour keyword based on the type of tip
     * @param {String} hasPassed
     */
    getCssClassFromType(hasPassed) {
      let classes = '';
      if (hasPassed) {
        classes += 'border-red-500 bg-red-500 sm:bg-transparent';
      } else {
        classes += 'border-flow-teal dealer-bg-secondary sm:bg-transparent';
      }
      classes +=
        'sm:bg-transparent flex items-center justify-center dealer-text-primary font-bold border-0';

      return classes;
    },
  },
};
</script>
