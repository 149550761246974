<template>
  <section class="add-vehicle-by-reg">
    <div class="form">
      <ValidationObserver v-slot="{ invalid }">
        <div class="field">
          <label>Registration</label>
          <TextInputButton
            :rules="form.vrm.rules"
            id="vehicle-reg"
            name="vehicle-reg"
            v-model="form.vrm.value"
            :is-uppercase="true"
            validation-mode="aggressive"
            @keyup.enter.native="lookupVehicleVrm()"
            @click="lookupVehicleVrm()"
            autocomplete="on"
            :valueButton="loading.vehicle ? 'Searching...' : 'Lookup'"
            :is-disabled="invalid"
            placeholder="Enter Reg"
          />
          <div class="text-red-500 mt-3" v-if="form.vrm.error">{{ form.vrm.error }}</div>
        </div>

        <Loading v-if="loading.vehicle" />

        <div
          v-if="
            vehicle !== null &&
            !loading.vehicle &&
            vehicle.cap_code !== null &&
            form.vrm.value !== null
          "
          class="mt-8"
        >
          <div class="w-full mb-4 bg-white shadow overflow-hidden rounded-lg">
            <div class="px-5 py-5 sm:px-6 flex items-center w-full">
              <h3 class="text-lg leading-6 font-medium text-gray-900 mb-0">
                <span class="font-semibold">{{ vehicle.make }}</span> {{ vehicle.model }}
              </h3>
              <p class="text-sm text-gray-500 mb-0 ml-4">
                {{ vehicle.first_registered | moment('YYYY') }} {{ vehicle.colour }}
                {{ vehicle.fuel }}
              </p>

              <button
                @click="resetForm"
                class="ml-auto hover:no-underline background-transparent font-bold uppercase py-1 text-sm outline-none focus:outline-none mr-1 mb-1"
                type="button"
                style="transition: all 0.15s"
              >
                <span
                  class="bg-gray-200 rounded-full h-10 w-10 flex items-center justify-center text-black hover:dealer-bg-secondary hover:text-white dealer-text-primary md:hidden"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="fill:#000 w-6 h-6"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M345 137l17-17L328 86.1l-17 17-119 119L73 103l-17-17L22.1 120l17 17 119 119L39 375l-17 17L56 425.9l17-17 119-119L311 409l17 17L361.9 392l-17-17-119-119L345 137z"
                    />
                  </svg>
                </span>
                <span class="hidden md:block underline">Wrong car?</span>
              </button>
            </div>
          </div>
        </div>

        <div class="field actions flex justify-between mt-8">
          <h6
            @click="backToQuotes()"
            class="cursor-pointer flex items-center dealer-text-primary hover:dealer-text-primary"
          >
            <i class="fas fa-long-arrow-alt-left mr-2"></i> Back to quotes
          </h6>
          <button
            class="dealer-btn-primary"
            :disabled="invalid"
            @click="$router.push({ name: 'dashboard__calculator__figures' })"
            :class="{ 'opacity-50 cursor-not-allowed pointer-events-none': vehicle === null }"
          >
            Next
          </button>
        </div>
      </ValidationObserver>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import TextInputButton from '@/components/Form/TextInputButton.vue';
import axios from 'axios';
import Loading from '@/components/Ui/Loading';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    TextInputButton,
  },

  data() {
    return {
      results: null,
      form: {
        vrm: {
          value: '',
          rules: 'required',
          error: null,
        },
      },
      vehicle: null,
      loading: {
        vehicle: false,
      },
    };
  },

  computed: mapState(['dashboard']),

  mounted() {
    // If they have a VRM saved from the journey, pre-populate field and search for vehicle
    if (
      (this.dashboard.application.application_question?.vrm ||
        this.dashboard.application.application_property_vrm) &&
      this.vehicle === null &&
      this.form.vrm.value === ''
    ) {
      this.form.vrm.value =
        this.dashboard.application.application_question?.vrm ??
        this.dashboard.application.application_property_vrm;
      this.lookupVehicleVrm();
    }
  },

  methods: {
    backToQuotes() {
      this.$router.push({
        name: 'dashboard__calculator__quotes',
      });
    },

    resetForm() {
      this.$store.commit('setVehicle', null);
      this.form.vrm.value = null;
    },

    lookupVehicleVrm() {
      this.loading.vehicle = true;
      this.form.vrm.error = null;
      // this.form.vrm.error = this.$router.push({path: '/test'})

      axios
        .get(process.env.VUE_APP_API_VEHICLE_LOOKUP, {
          params: {
            vrm: this.form.vrm.value,
          },
        })
        .then((r) => {
          if (r.data.success && r.data.data.vehicle) {
            this.vehicle = this.mapVehicle(r.data.data.vehicle);
            this.$store.commit('setVehicle', this.vehicle);
            return;
          }
          this.form.vrm.error = 'Sorry, we could not a vehicle matching that registration.';
          this.vehicle = null;
        })
        .catch((e) => {
          console.error('Failed getting vehicle!', e);
          this.form.vrm.error = 'We ran into an issue while searching for that vehicle.';
          this.vehicle = null;
        })
        .finally(() => {
          this.loading.vehicle = false;
        });
    },

    mapVehicle(vehicle) {
      return {
        cap_code: vehicle.cap_code,
        cap_id: vehicle.cap_id,
        co2_rating: vehicle.co2_rating,
        colour: vehicle.colour,
        derivative: vehicle.derivative,
        engine_size: vehicle.engine_size,
        first_registered: vehicle.first_registered,
        fuel: vehicle.fuel,
        gears: vehicle.gears,
        imported: vehicle.imported,
        make: vehicle.make,
        model: vehicle.model,
        transmission: vehicle.transmission,
        vin: vehicle.vin,
        vrm: vehicle.vrm,
        addedManually: false,
      };
    },
  },
};
</script>
