<template>
  <section class="application-management">
    <nav
      class="bg-flow-blue dealer-header-bg-color w-full shadow-md border-b border-white border-opacity-30"
      id="navbar"
      style="z-index: 3"
    >
      <div
        class="max-w-7xl justify-between flex flex-shrink-0 mx-auto text-white py-4 px-4 sm:px-6 xl:px-0 w-full"
      >
        <a :href="dealer.url" class="logo flex justify-center" v-if="dealer.logo_url">
          <img
            :src="dealer.logo_url"
            class="mr-auto cursor-pointer object-contain h-12 w-auto pr-3"
            alt=""
          />
        </a>
        <div class="logo flex justify-center" @click="$router.push({ name: 'home-page' })" v-else>
          <Logo :teal="true" class="mr-auto cursor-pointer"></Logo>
        </div>

        <div class="inline-flex items-center">
          <button
            @click="logout()"
            class="flex items-center justify-center px-4 text-flow-blue hover:text-white space-x-3 bg-flow-teal dealer-btn-primary border-transparent hover:border-flow-teal hover:bg-transparent rounded-full focus:outline-none h-10 shadow-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
              />
            </svg>
            <span>Logout</span>
          </button>
        </div>
      </div>
    </nav>

    <router-view @loading="loading" @request-toggle-chat="$emit('request-toggle-chat')" />

    <Footer />
  </section>
</template>

<script>
import Logo from '@/components/Layout/Logo';
import Footer from '@/components/Layout/Footer';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import ProgressBar from '@/components/Ui/ProgressBar';
import Breadcrumbs from '@/components/Ui/Breadcrumbs';
import Api from '@/lib/Api';
import axios from 'axios';

export default {
  name: 'ApplicationManagement',
  components: {
    Logo,
    ProgressBar,
    Footer,
    Breadcrumbs,
  },
  data() {
    return {
      displayPartnerHeader: false,
    };
  },

  computed: mapState(['dealer', 'dashboard']),
  methods: {
    ...Api,

    /**
     * Logs the user out and redirects to login page.
     */
    logout() {
      this.$emit('loading', true, 'Logging out...');
      axios
        .post(this.getApiBaseUrl() + '/auth/logout')
        .then((r) => {
          this.$store.dispatch('logout');
        })
        .finally(() => {
          this.$emit('loading', false);
          this.$router.push({ name: 'login' });
        });
    },

    loading(state = false, text = 'One moment...') {
      this.$emit('loading', state, text);
    },
  },
  async mounted() {
    // Inject dealer styles if available
    this.injectDealerStyles();

    // Update favicon if set
    if (this.dealer.favicon_url) {
      let favicon = document.querySelector('link[rel="icon"]');
      return (favicon.href = this.dealer.favicon_url);
    }
  },
};
</script>

<style scoped></style>
