<template>
  <div class="form-label form-label-v2 flex items-center justify-between">
    <label :for="forInput" class="text-base">
      <span class="font-bold text-tf-flow-blue"><slot /></span>
    </label>
    <template v-if="tooltip !== null">
      <ToolTip :title="tooltipTitle" :text="tooltipBody" class="ml-1 -mt-1" />
    </template>
  </div>
</template>

<script>
import ToolTip from '@/components/Form/ToolTip';

export default {
  name: 'FormLabel',
  components: {
    ToolTip,
  },

  computed: {
    tooltipTitle() {
      if (this.tooltip) {
        if (typeof this.tooltip === 'object' && this.tooltip.title) {
          return this.tooltip.title;
        }
        return this.tooltip;
      }
      return '';
    },

    tooltipBody() {
      if (this.tooltip) {
        if (typeof this.tooltip === 'object' && this.tooltip.body) {
          return this.tooltip.body;
        }
      }
      return '';
    },
  },

  props: {
    forInput: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String | Object,
      default: null,
    },
  },

  methods: {
    /**
     * Opens a specific tooltip modal
     */
    openTooltip() {
      this.$refs['info_' + this._uid].show();
    },

    closeTooltip() {
      this.$refs['info_' + this._uid].hide();
    },
  },
};
</script>
