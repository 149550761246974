<template>
  <section class="dashboard-carsearch-details">
    <div class="form grid grid-cols-1 md:grid-cols-2 md:gap-1 divide-x">
      <div>
        <div class="mb-10 md:mb-5">
          <h2 class="text-flow-blue dealer-text-secondary">Search for your car</h2>
          <div class="field grid grid-cols-2 gap-4">
            <div>
              <label class="flex items-center" for="filter-postcode">Postcode/Town</label>
              <input
                id="filter-postcode"
                @change="getPostcodeCoords()"
                @keyup="form.postcode.notFound = false"
                type="text"
                v-model="form.postcode.value"
                placeholder="Postcode/Town"
                :class="{ 'opacity-50 pointer-events-none': loading.postcode }"
              />
              <div v-if="form.postcode.notFound" class="text-red-500">
                Couldn't find that location
              </div>
            </div>

            <div>
              <label class="flex items-center" for="radius">Distance</label>
              <select
                name="radius"
                id="radius"
                v-model="form.radius.value"
                class="z-1 form-select uppercase sm:leading-relaxed py-2 pl-3 pr-5 block text-lg"
                :class="{
                  'opacity-50 pointer-events-none': !hasUserLocation() || !form.postcode.value,
                }"
              >
                <option
                  :key="key"
                  :value="option.value"
                  v-for="(option, key) in form.radius.options"
                >
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>

          <div class="field grid grid-cols-2 gap-4">
            <div>
              <label class="flex items-center" for="price"
                >Vehicle make
                <ToolTip text="Vehicle brand" class="ml-1 -mt-1" />
              </label>
              <select
                @change="onMakeChange()"
                name="make"
                id="price"
                v-model="form.make.value"
                class="z-1 form-select uppercase sm:leading-relaxed py-2 pl-3 pr-5 block text-lg"
              >
                <option :key="key" :value="option.value" v-for="(option, key) in form.make.options">
                  {{ option.label }}
                </option>
              </select>
            </div>

            <div>
              <label class="flex items-center" for="model"
                >Vehicle model
                <ToolTip text="Vehicle model type" class="ml-1 -mt-1" />
              </label>
              <select
                name="model"
                id="model"
                v-model="form.model.value"
                class="z-1 form-select uppercase sm:leading-relaxed py-2 pl-3 pr-5 block text-lg"
                :disabled="form.model.disabled"
              >
                <option value="null">All</option>
                <option :key="key" :value="option.value" v-for="(option, key) in orderedItems()">
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>

          <ValidationObserver v-slot="{ invalid }">
            <div class="field grid grid-cols-2 gap-4">
              <ValidationProvider v-slot="{ errors }" tag="div">
                <label class="flex items-center mb-2" for="min-price">Minimum price</label>
                <input
                  type="number"
                  v-model="form.price.min.value"
                  id="min-price"
                  placeholder="Minimum price"
                  min="3500"
                  max="1000000"
                  step="500"
                />
                <div v-if="errors" class="text-white bg-red-500 rounded-b-md text-sm px-2">
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" tag="div">
                <label class="flex items-center mb-2" for="max-price">Maximum price</label>
                <input
                  type="number"
                  v-model="form.price.max.value"
                  id="max-price"
                  placeholder="Maximum price"
                  min="3500"
                  max="1000000"
                  step="500"
                />
                <div v-if="errors" class="text-white bg-red-500 rounded-b-md text-sm px-2">
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
            </div>

            <div class="mt-8 text-right">
              <button
                @click="submit"
                class="dealer-btn-primary"
                :class="{
                  'opacity-50 pointer-events-none': invalid || form.price.min.value === null,
                }"
              >
                Search
              </button>
            </div>
          </ValidationObserver>
        </div>

        <div class="mt-5">
          <h2 class="text-flow-blue dealer-text-secondary">Search by reference</h2>
          <p>
            If you've been provided an 8-digit vehicle reference, enter it below to quickly find a
            vehicle.
          </p>

          <ValidationObserver v-slot="{ invalid }" tag="div">
            <div class="field">
              <label class="flex items-center" for="filter-reference">Reference Number</label>
              <ValidationProvider rules="min:8|max:8|required" v-slot="{ errors }" tag="div">
                <input
                  id="filter-reference"
                  name="Reference"
                  type="text"
                  placeholder="Vehicle reference"
                  v-model="form.reference.value"
                />
                <div v-if="errors.length" class="text-red-500">{{ errors[0] }}</div>
                <div v-if="form.reference.error !== null" class="text-red-500">
                  {{ form.reference.error }}
                </div>
              </ValidationProvider>
            </div>
            <div class="mt-8 text-right">
              <button
                @click="searchByReference"
                class="dealer-btn-primary"
                :disabled="invalid || form.reference.loading"
                :class="{ 'opacity-50': invalid || form.reference.loading }"
              >
                {{ form.reference.loading ? 'Searching' : 'Search' }}
              </button>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToolTip from '@/components/Form/ToolTip';
import VehicleFeed from '@/lib/VehicleFeed';
import GoogleMaps from '@/lib/GoogleMaps';
import _ from 'lodash';

export default {
  components: {
    ToolTip,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      googleMaps: new GoogleMaps(['geocoder']),
      feed: new VehicleFeed(),
      location: {
        permission: null,
        lat: null,
        lng: null,
      },
      form: {
        postcode: {
          value: null,
          notFound: false,
        },
        radius: {
          value: null,
          options: [
            { value: 10, label: 'Within 10 miles' },
            { value: 25, label: 'Within 25 miles' },
            { value: 50, label: 'Within 50 miles' },
            { value: 75, label: 'Within 75 miles' },
            { value: 100, label: 'Within 100 miles' },
            { value: 150, label: 'Within 150 miles' },
            { value: null, label: 'Nationwide' },
          ],
        },
        make: {
          value: null,
          options: [{ value: null, label: 'Any' }],
        },
        model: {
          disabled: false,
          value: null,
          options: [],
        },
        price: {
          min: {
            value: null,
            guide: 0,
          },
          max: {
            value: null,
            guide: 500000,
          },
        },
        reference: {
          value: '',
          error: null,
          loading: false,
        },
      },

      loading: {
        postcode: false,
      },
    };
  },

  computed: {
    ...mapState(['dashboard']),
  },

  methods: {
    orderedItems() {
      return _.orderBy(this.form.model.options, 'value', 'asc');
    },

    searchByReference() {
      // Reset error messages
      this.form.reference.error = null;
      this.form.reference.loading = true;

      axios
        .get(`${this.getApiBaseUrl()}/v1/vehicle-feed/vehicle/${this.form.reference.value}.json`)
        .then(async (r) => {
          if (r.data.success && r.data.data?.vehicle?.quick_code) {
            await this.$router.push({
              name: 'dashboard__search__view',
              params: {
                vehicleId: this.form.reference.value,
              },
            });
          }
        })
        .catch((e) => {
          // TODO: Show more details error messages (currently assumes 404)
          this.form.reference.error = "We couldn't find a vehicle with that reference";
        })
        .finally(() => {
          this.form.reference.loading = false;
        });
    },

    async getApplicantAddress() {
      this.loading.postcode = true;
      const addresses = await axios.get(
        `${this.getApiBaseUrl()}/v1/applicants/${this.dashboard.applicant.id}/addresses.json`,
        {
          params: {
            current: true,
          },
        }
      );
      this.loading.postcode = false;

      if (addresses.data.success && addresses.data.data?.item?.postcode) {
        this.form.postcode.value = addresses.data.data.item.postcode;
        this.getPostcodeCoords();
      }
    },

    async getPostcodeCoords() {
      if (!this.form.postcode.value) {
        this.form.radius.value = null;
        return;
      }

      this.form.postcode.notFound = false;
      this.loading.postcode = true;
      try {
        const response = await this.googleMaps.getPostcodeCoords(this.form.postcode.value);

        if (response.results.length) {
          this.location.lat = response.results[0].geometry.location.lat();
          this.location.lng = response.results[0].geometry.location.lng();

          // Set the radius to 75 miles as default
          this.form.radius.value = 75;
        }
      } catch (e) {
        this.form.postcode.value = null;
        this.location.lat = null;
        this.location.lng = null;
        this.form.radius.value = null;
        this.form.postcode.notFound = true;
      }
      this.loading.postcode = false;
    },

    async submit() {
      // Proceed to next step
      return await this.$router.push({
        name: 'dashboard__search__results',
        query: {
          make: this.form.make.value,
          model: this.form.model.value,
          min_price: this.form.price.min.value,
          max_price: this.form.price.max.value,
          sort: 'price',
          order: 'asc',
          postcode: this.form.postcode.value,
          lat: this.location.lat,
          lng: this.location.lng,
          radius: this.form.radius.value,
          page: 1,
        },
      });
    },

    resetMakeField() {
      this.form.make.options = [{ label: 'Any', value: null }];
      this.form.make.value = null;
    },

    resetModelField() {
      this.form.model.options = [];
      this.form.model.value = null;
    },

    async getAllMakes() {
      this.resetMakeField();
      const makes = await this.feed.getMakes();

      if (makes.length) {
        makes.forEach((make) => {
          this.form.make.options.push({
            label: `${make.make} (${make.count.toLocaleString('en-GB')})`,
            value: make.make,
          });
        });
      }
    },

    async onMakeChange() {
      this.form.model.disabled = true;

      // Reset the models drop down while we load
      this.resetModelField();

      // Fetch the related models for this make
      const models = await this.feed.getModels(this.form.make.value);

      if (models.length) {
        models.forEach((model) => {
          this.form.model.options.push({
            label: `${model.model} (${model.count.toLocaleString('en-GB')})`,
            value: model.model,
            min_price: model.min_price ?? null,
            max_price: model.max_price ?? null,
          });
        });
      }

      this.form.model.disabled = false;
    },

    hasUserLocation() {
      return this.location.lat !== null && this.location.lng !== null;
    },
  },

  async mounted() {
    // Reset the make and model drop down fields
    this.resetModelField();

    // Get all vehicle makes
    this.getAllMakes();

    if (this.form.postcode.value === null) {
      this.getApplicantAddress();
    }
  },
};
</script>

<style lang="scss" scoped>
:disabled {
  opacity: 0.35;
}
</style>
