<template>
  <section class="step employment-history flex-1">
    <h2>Employment</h2>
    <h5 class="grey">Let's take a look at your employment history.</h5>

    <section class="form mt-8">
      <!-- If they've added their employment but we need more... -->
      <div
        id="need-more-history"
        v-show="
          !isEmploymentHistoryEnough(journey.applicant.employment.employments) &&
          journey.applicant.employment.employments.length
        "
        class="mt-6 bg-red-500 p-6 mb-5"
      >
        <div class="grid grid-cols-3 gap-4 null">
          <div class="col-span-6">
            <h4 class="font-bold text-xl mb-2 text-white">We need a bit more history</h4>
            <p class="mb-0 text-white">
              We need at least
              {{
                form.employments.options.minHistoryMonths > 0
                  ? form.employments.options.minHistoryMonths / 12 + ' years'
                  : form.employments.options.minHistoryMonths + ' months'
              }}
              of employment history to continue.
            </p>
          </div>
        </div>
      </div>

      <!-- If employment history is enough -->
      <div
        v-show="
          isEmploymentHistoryEnough(journey.applicant.employment.employments) &&
          journey.applicant.employment.employments &&
          journey.applicant.employment.employments.length
        "
        class="bg-green-500 text-white p-6 mb-5"
      >
        <div class="grid grid-cols-3 gap-4 null">
          <div class="col-span-6">
            <span class="font-bold text-xl mb-2">You've provided enough history, thanks!</span>
          </div>
        </div>
      </div>

      <div v-if="!isEmploymentHistoryEnough(journey.applicant.employment.employments)">
        <EmploymentFinder
          :min-history-months="36"
          @historyAdded="addEmploymentHistory"
          :addressCount="journey.applicant.employment.employments.length"
        />
      </div>

      <div
        v-if="
          journey.applicant.employment.employments &&
          journey.applicant.employment.employments.length
        "
        class="previous-employment mt-10"
      >
        <FormLabel>Previous employers</FormLabel>
        <div
          id="previous-employment"
          v-for="employment in journey.applicant.employment.employments"
          :key="employment.id"
          class="mt-2 text-left sm:py-3 sm:whitespace-no-wrap focus:outline-none active:outline-none inline-flex w-full items-center py-2 text-md leading-normal font-medium transition ease-in-out duration-150 pl-12 pr-5 relative mb-2"
        >
          <span
            class="cursor-pointer w-10 h-10 flex justify-center items-center absolute left-0"
            @click="removeEmployment(employment.id)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="remove-employment w-8 h-8"
              viewBox="0 0 384 512"
            >
              <path
                d="M192 233.4L59.5 100.9 36.9 123.5 169.4 256 36.9 388.5l22.6 22.6L192 278.6 324.5 411.1l22.6-22.6L214.6 256 347.1 123.5l-22.6-22.6L192 233.4z"
              />
            </svg>
          </span>

          <span v-if="employment.type === 'Retired'" class="truncate" title="Retired"
            ><span v-if="employment.started" class="text-sm font-bold mr-2"
              >{{ IsCalendarDate(employment.started) }},</span
            >
            Retired</span
          >
          <span v-else-if="employment.type === 'Unemployed'" class="truncate" title="Unemployed">
            <span v-if="employment.started" class="text-sm font-bold mr-2"
              >{{ IsCalendarDate(employment.started) }},</span
            >Unemployed</span
          >
          <span v-else class="truncate" :title="getEmploymentSummaryText(employment)"
            ><span v-if="employment.started" class="text-sm font-bold mr-2"
              >{{ formatDate(employment.started, 'MMMM Y') }},</span
            >
            {{ getEmploymentSummaryText(employment) }}</span
          >
        </div>
      </div>

      <!-- Annual income field -->
      <ValidationObserver ref="observer" tag="div" class="employment" v-slot="{ passes, invalid }">
        <div class="field" v-if="journey.applicant.employment.employments.length">
          <ValidationProvider
            tag="div"
            :rules="form.income.rules"
            v-slot="{ errors }"
            name="Salary"
          >
            <FormLabel for-input="salary">Your annual income before tax</FormLabel>
            <TextInputIcon
              id="salary"
              icon="fas fa-pound-sign"
              name="salary"
              v-model="form.income.value"
              :is-number="true"
              @change="form.income.dirty = true"
            />

            <span v-if="form.income.value !== 0 && form.income.value < 12000 && form.income.dirty"
              >Is this <strong>annual income before tax</strong> correct? &pound;{{
                formatPrice(form.income.value)
              }}</span
            >
            <div v-if="errors.length" class="error-message">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>

        <!-- Navigation -->
        <div
          class="form-actions flex"
          v-if="isEmploymentHistoryEnough(journey.applicant.employment.employments)"
        >
          <button class="dealer-btn-primary ml-auto" @click="submitIfValid">Next</button>
        </div>
      </ValidationObserver>
    </section>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import EmploymentFinder from '@/components/Form/EmploymentFinder';
import FormLabel from '@/components/Form/FormLabel.vue';
import TextInputIcon from '@/components/Form/TextInputIcon';
import moment from 'moment';
import axios from 'axios';
import Api from '@/lib/Api';

export default {
  name: 'Employment',
  components: {
    ValidationObserver,
    ValidationProvider,
    EmploymentFinder,
    TextInputIcon,
    FormLabel,
  },

  computed: {
    ...mapState(['dealer', 'journey']),
  },

  data() {
    return {
      valid: false,
      form: {
        income: {
          value: 0,
          rules: 'required|numeric',
          dirty: false,
        },

        employments: {
          value: [],
          options: {
            minHistoryMonths: 36,
          },
        },
      },
      nextPage: 'app_medium__privacy',
    };
  },

  methods: {
    ...Api,

    getEmploymentSummaryText(employment) {
      let str = employment.occupation ?? 'Unknown occupation';
      if (employment.employer) {
        str += ' (' + employment.employer + ')';
      }
      return str;
    },

    /**
     * Adds comma separators to a number
     * @param {Number} value The value to format
     */
    formatPrice(value) {
      let val = value.toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    /**
     * Validates all fields on this step (DOB and address history)
     */
    async isValid() {
      const isValid = await this.$refs.observer.validate();
      this.valid =
        isValid && this.isEmploymentHistoryEnough(this.journey.applicant.employment.employments);
    },

    async addEmploymentHistory(employmentData) {
      try {
        // Add the address to the database and Vuex store
        await this.apiStoreEmployment(this.journey.applicant.id, employmentData);

        // Scroll back up if we need more history
        // if(!this.isEmploymentHistoryEnough(this.journey.applicant.employment.employments)) {
        // this.scrollToField('need-more-history')
        // }
      } catch (e) {
        // TODO: Bugsnag
        console.error(e);
      }

      // Validate
      await this.isValid();
    },

    /**
     * Stores an employment history against an applicant (API & Store)
     * @param {Number} applicantId
     * @param {Object} employment
     */
    apiStoreEmployment(applicantId, employment) {
      this.$emit('loading', true, 'Saving...');
      return axios
        .post(this.getApiBaseUrl() + '/applicants/' + applicantId + '/employments', {
          employment: employment,
        })
        .then((r) => {
          if (r.data.success && r.data.data?.employment?.id) {
            const employmentToStore = {
              ...employment,
              id: r.data.data.employment.id,
            };
            this.$store.commit('addApplicantEmployment', employmentToStore);
          } else {
            // TODO: Nice error handling
            throw new Error('Failed to save employment');
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.$emit('loading', false);
        });
    },

    /**
     * Fired when an address is removed from the address finder
     * Replaces our array of addresses with the updated one
     * @param id
     */
    async removeEmployment(id) {
      // Remove the address from the database and Vuex store
      await this.apiRemoveEmployment(this.journey.applicant.id, id);

      // Validate
      await this.isValid();
    },

    /**
     * Removes an address (API & Store)
     * @param {Number} applicantId
     * @param employmentId
     */
    apiRemoveEmployment(applicantId, employmentId) {
      this.$emit('loading', true, 'Removing employer');
      return axios
        .delete(
          this.getApiBaseUrl() + '/applicants/' + applicantId + '/employments/' + employmentId
        )
        .then((r) => {
          if (r.data.success) {
            return this.$store.commit('removeApplicantEmployment', employmentId);
          } else {
            throw new Error(r);
          }
        })
        .catch((e) => {
          // If we can't find the address in the API, remove it from the local store and carry on
          if (e.response?.status === 404) {
            return this.$store.commit('removeApplicantEmployment', employmentId);
          }

          console.error('⚠:', e);
        })
        .finally(() => {
          this.$emit('loading', false);
        });
    },

    /**
     * Updated an applicant
     * @param {String} applicationRef Application's reference ID
     * @param {Number} applicantId ID of the applicant to update
     * @param {Object} applicantData Data to push
     */
    async apiUpdateApplicant(applicationRef, applicantId, applicantData) {
      return axios
        .patch(
          this.getApiBaseUrl() + '/applications/' + applicationRef + '/applicants/' + applicantId,
          {
            applicant: applicantData,
          }
        )
        .then((r) => {
          if (r.data.success) {
            return true;
          }
          throw new Error(r.data.errors);
        });
    },

    /**
     * Submits the form if there's enough employment history and
     * no Vee Validate errors
     */
    async submitIfValid() {
      const isValid = await this.$refs['observer'].validate();

      if (isValid) {
        this.$emit('loading', true, 'Saving...');
        try {
          // Update the applicant's income
          await this.apiUpdateApplicant(
            this.journey.application.reference,
            this.journey.applicant.id,
            {
              gross_income: this.form.income.value,
              gross_income_frequency: 'Yearly',
            }
          );

          // Store in Vuex, no need to * 100
          this.$store.commit('setApplicant', {
            employment: {
              gross_income: this.form.income.value,
              employments: this.journey.applicant.employment.employments,
            },
          });

          let dealerName = this.dealer.name;

          if (this.dealer.name) {
            // Attach dealer name to push event
            await this.apiPushEvent(
              this.journey.application.reference,
              dealerName + '_step_employment_history',
              dealerName + '_feed_journey',
              dealerName + '_step',
              dealerName + '_employment_history'
            );
          } else {
            // Standard push event
            await this.apiPushEvent(
              this.journey.application.reference,
              'step_employment_history',
              'feed_journey',
              'step',
              'employment_history'
            );
          }

          // Next step
          this.$emit('loading', false);

          // If they haven't been referred by a dealer, ask them additional questions.
          if (this.hasDealer()) {
            return await this.$router.push({ name: 'privacy' });
          }
          return await this.$router.push({ name: 'vehicle' });
        } catch (e) {
          console.error(e);
        }
      }
    },

    /**
     * Returns true if we have enough address history
     * @param employments
     */
    isEmploymentHistoryEnough(employments = []) {
      const now = moment().startOf('month');
      return (
        employments.filter((employment) => {
          return (
            now.diff(moment(employment.started), 'months') >=
            this.form.employments.options.minHistoryMonths
          );
        }).length > 0
      );
    },

    formatDate(date, format) {
      return moment(date).format(format);
    },
  },

  mounted() {
    this.$emit('set-step', 5);

    // Restore income if needed
    if (this.journey.applicant.employment.gross_income) {
      this.form.income.value = this.journey.applicant.employment.gross_income;
    }

    // Restore employment history
    if (
      this.journey.applicant.employment.employments &&
      this.journey.applicant.employment.employments.length
    ) {
      this.form.employments.value = this.journey.applicant.employment.employments;
    }
  },
};
</script>
<style lang="scss" scoped>
#salary {
  box-shadow: none !important;
}
</style>
