<template>
  <section class="calculator-outer">
    <div class="calculator">
      <router-view @loading="loading" />
    </div>
  </section>
</template>

<script>
import Banner from '@/components/Ui/Dashboard/Banner';
import Breadcrumbs from '@/components/Ui/Breadcrumbs';

export default {
  components: {
    Banner,
    Breadcrumbs,
  },

  methods: {
    loading(state = false, text = 'One moment...') {
      this.$emit('loading', state, text);
    },
  },
};
</script>
