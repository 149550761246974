<template>
  <div class="text-center mt-16 text-xl w-full md:w-1/2 mx-auto">
    <strong>Need to talk? Our concierge team are here to help you every step of the way</strong>
    <a :href="'tel:' + tel" class="hidden md:block mt-8 mx-auto text-3xl font-bold">{{ tel }}</a>
    <a :href="'tel:' + tel" class="btn-secondary inline-block md:hidden mt-10 mx-auto">Call now</a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tel: '01943660703',
    };
  },
};
</script>
