<template>
  <section class="add-vehicle-manually">
    <div class="form">
      <!-- Year and plate -->
      <div class="field mb-8">
        <label for="year-plate">Select year &amp; plate</label>
        <select
          v-model="form.year.value"
          @change="getMakes"
          name="select-year-plate"
          id="year-plate"
          class="form-select"
        >
          <option :key="key" :value="option.value" v-for="(option, key) in form.year.options">
            {{ option.label }}
          </option>
        </select>
      </div>

      <!-- Select make -->
      <transition name="fade">
        <div v-if="form.year.value !== null" class="field mb-8">
          <label for="make">Select make</label>

          <svg
            v-if="form.make.loading"
            class="animate-spin h-8 w-8 my-3"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              fill="#272A5C"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <select
            v-else
            v-model="form.make.value"
            @change="getModels"
            name="select-make"
            id="make"
            class="form-select"
          >
            <option :key="key" :value="key" v-for="(option, key) in form.make.options">
              {{ option.label }}
            </option>
          </select>
        </div>
      </transition>

      <!-- Select model -->
      <transition name="fade">
        <div v-show="form.make.value !== null" class="field mb-8">
          <label for="model">Select model</label>

          <svg
            v-if="form.model.loading"
            class="animate-spin h-8 w-8 my-3"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              fill="#272A5C"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <select
            v-else
            v-model="form.model.value"
            @change="getDerivatives"
            name="select-model"
            id="model"
            :disabled="form.model.loading"
            class="form-select"
          >
            <!--<option :key="key" :value="option.value" v-for="(option, key) in form.model.options">-->
            <option :key="key" :value="key" v-for="(option, key) in form.model.options">
              {{ option.label }}
            </option>
          </select>
        </div>
      </transition>

      <!-- Select derivative -->
      <transition name="fade">
        <div v-show="form.model.value !== null" class="field mb-8">
          <label for="derivative">Select derivative</label>

          <svg
            v-if="form.derivative.loading"
            class="animate-spin h-8 w-8 my-3"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              fill="#272A5C"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <select
            v-else
            v-model="form.derivative.value"
            name="select-derivative"
            id="derivative"
            :disabled="form.derivative.loading"
            class="form-select"
          >
            <option
              :key="key"
              :value="option.cap_code"
              v-for="(option, key) in form.derivative.options"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
      </transition>

      <!-- Buttons -->
      <div class="field actions flex justify-between mt-8">
        <h6
          @click="$router.push({ name: 'dashboard__calculator__quotes' })"
          class="cursor-pointer flex items-center dealer-text-primary hover:dealer-text-primary"
        >
          <i class="fas fa-long-arrow-alt-left mr-2"></i> Back to quotes
        </h6>

        <transition name="fade">
          <button
            v-show="form.derivative.value !== null"
            @click="submit"
            class="btn-primary dealer-btn-primary hover:dealer-bg-secondary hover:dealer-text-primary"
          >
            Confirm
          </button>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment';
import Api from '@/lib/Api';

export default {
  name: 'AddVehicleManually',
  data() {
    return {
      makes: [],
      models: [],
      derivatives: [],

      form: {
        year: {
          value: null,
          options: [],
          errors: null,
          default: 'Select',
          loading: false,
        },
        make: {
          value: null,
          options: [],
          errors: null,
          default: 'Select',
          loading: false,
        },
        model: {
          value: null,
          options: [],
          errors: null,
          default: 'Select',
          loading: false,
        },
        derivative: {
          value: null,
          options: [],
          errors: null,
          default: 'Select',
          loading: false,
        },
      },
    };
  },

  methods: {
    ...Api,

    getYears() {
      let startYear = parseInt(moment().subtract(14, 'years').format('YYYY')),
        endYear = parseInt(moment().format('YYYY')),
        options = [];

      for (let i = startYear; i <= endYear; i++) {
        let plateBaseline = i - 2000,
          plate1 = plateBaseline + 49,
          plate2 = plateBaseline,
          plate3 = plate1 + 1;
        plate2 = (plate2 + '').padStart(2, '0');

        let item1 = {
          label: i + ' - ' + plate1 + ' plate',
          value: i + '-01-01',
        };
        options.push(item1);

        if (i === endYear) {
          if (moment(i + '-03-01').isAfter(moment())) {
            // Don't show future plates
            continue;
          }
        }

        let item2 = {
          label: i + ' - ' + plate2 + ' plate',
          value: i + '-03-01',
        };
        options.push(item2);

        if (i === endYear) {
          if (moment(i + '-09-01').isAfter(moment())) {
            // Don't show future plates
            continue;
          }
        }

        let item3 = {
          label: i + ' - ' + plate3 + ' plate',
          value: i + '-09-01',
        };
        options.push(item3);
      }

      return options.reverse();
    },

    initYears() {
      this.form.year.options = this.getYears();
    },

    prepareCapResults(results) {
      return results.map((i) => {
        return {
          value: i.id,
          label: i.name,
        };
      });
    },

    async getMakes() {
      this.form.make.errors = [];
      this.form.make.loading = true;
      this.form.make.value = null;
      this.form.model.value = null;
      this.form.derivative.value = null;
      this.form.make.options = [];
      const results = await this.apiGetCapMakes(1, this.form.year.value);
      this.form.make.loading = false;

      if (results === null) {
        this.form.make.errors.push('There was problem getting a list of vehicle makes.');
        return;
      }

      this.makes = results.makes;
      this.form.make.options = this.prepareCapResults(results.makes);
    },

    async getModels() {
      this.form.model.errors = [];
      this.form.model.loading = true;
      this.form.model.value = null;
      this.form.derivative.value = null;
      this.form.model.options = [];
      const results = await this.apiGetCapModels(
        this.makes[this.form.make.value].id,
        this.form.year.value
      );
      this.form.model.loading = false;

      if (results === null) {
        this.form.models.errors.push('There was problem getting a list of vehicle models.');
        return;
      }

      this.models = results.models;
      this.form.model.options = this.prepareCapResults(results.models);
    },

    async getDerivatives() {
      this.form.derivative.errors = [];
      this.form.derivative.loading = true;
      this.form.derivative.value = null;
      this.form.derivative.options = [];
      const results = await this.apiGetCapDerivatives(
        this.models[this.form.model.value].id,
        this.form.year.value
      );
      this.form.derivative.loading = false;

      if (results === null) {
        this.form.derivative.errors.push('There was a problem retrieving the vehicle derivatives.');
        return;
      }

      this.derivatives = results.derivatives;
      this.form.derivative.options = results.derivatives.map((derivative) => {
        return {
          cap_code: derivative.cap_code,
          name: derivative.name,
        };
      });
    },

    submit() {
      let vehicleData = {
        make: this.makes[this.form.make.value].name,
        model: this.models[this.form.model.value].name,
        cap_code: this.form.derivative.value,
        first_registered: this.form.year.value,
        addedManually: true,
      };

      this.$store.commit('setVehicle', vehicleData);
      this.$router.push({ name: 'dashboard__calculator__figures' });
    },
  },

  async mounted() {
    this.initYears();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.45s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
