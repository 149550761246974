<template>
  <section class="applications">
    <vue-headful
      description="At Flow car Finance we think car finance should be fair, simple & sincere. With over 30 years of knowledge, expertise and listening to you, we came up with an effortless car, van or motorcycle buying experience."
      url="https://flowcarfinance.co.uk/applications"
    />

    <Banner
      banner_title="Review Address History"
      banner_subtitle="We would like to confirm a few details before creating a new application, this will not take long."
    />

    <main class="bg-gray-100">
      <div class="max-w-lg mx-auto w-full px-6 lg:px-8 py-12">
        <section class="">
          <h3 v-if="form.addresses.value.length">Your address history</h3>

          <!-- If they've added an address but we need more... -->
          <div v-show="!valid" class="bg-red-500 text-white p-6 mb-5">
            <div class="grid grid-cols-3 gap-4 null">
              <div class="col-span-6">
                <h4 class="font-bold text-xl mb-2 text-white">We need a bit more history</h4>
                <p class="mb-0">
                  We need at least
                  {{
                    form.addresses.options.minHistoryMonths > 0
                      ? form.addresses.options.minHistoryMonths / 12 + ' years'
                      : form.addresses.options.minHistoryMonths + ' months'
                  }}
                  of address history to continue.
                </p>
              </div>
            </div>
          </div>

          <div class="grid grid-rows-1 divide-y divide-gray-300 text-gray-600">
            <figure
              v-for="(address, i) in form.addresses.value"
              :key="i"
              class="py-3 relative flex"
            >
              <div class="flex-1">
                <div :title="getAddressDisplayString(address)" class="w-full">
                  {{ address.building_name || address.building_number }} {{ address.street }},
                  {{ address.town }}, {{ address.postcode }}
                </div>
                <strong>Moved in {{ address.moved_in | moment('DD/MM/YYYY') }}</strong>
              </div>
              <div class="flex items-end items-center">
                <span
                  v-if="!address.locked"
                  @click="removeAddress(address.id, i)"
                  class="hover:dealer-bg-secondary cursor-pointer dealer-bg-primary text-white p-2 rounded-full w-8 h-8 flex justify-center items-center"
                >
                  <i class="fas fa-trash-alt remove-address text-md"></i>
                </span>
              </div>
            </figure>
          </div>
        </section>
        <section class="form">
          <!-- Address finder -->
          <div class="field">
            <AddressFinder
              postcode-label="What's your current postcode?"
              address-label="Select your address"
              :min-history-months="36"
              @addressAdded="addAddressHistory"
              placeholder="Search address"
              :saved-addresses-count="form.addresses.value.length"
              :should-validate="false"
            />
          </div>

          <p class="bg-gray-300 px-5 text-sm py-4 flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 mr-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
              />
            </svg>
            Please confirm if your address details are correct
          </p>

          <div v-if="error" class="bg-red-500 text-white px-5 py-4 mb-5">
            {{ error }}
          </div>

          <div class="form-actions flex items-center">
            <div
              class="flex cursor-pointer space-x-2 text-flow-blue hover:text-flow-teal"
              @click="$router.push({ name: 'applications__review' })"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                />
              </svg>
              <span>Previous</span>
            </div>
            <button
              :disabled="!valid"
              :class="{ 'opacity-50': !valid }"
              class="dealer-btn-primary ml-auto hover:dealer-text-primary"
              @click="save"
            >
              Save
            </button>
          </div>
        </section>
      </div>
    </main>
  </section>
</template>

<script>
import Banner from '@/components/Ui/Applications/Banner';
import axios from 'axios';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import AddressFinder from '@/components/Form/AddressFinder.vue';
import FormLabel from '@/components/Form/FormLabel';
import Api from '@/lib/Api';
import methods from '@/lib/methods';
import Bugsnag from '@bugsnag/js';
import moment from 'moment';

export default {
  name: 'ReviewPersonalDetails',
  components: {
    Banner,
    ValidationObserver,
    ValidationProvider,
    AddressFinder,
    FormLabel,
  },
  data() {
    return {
      error: null,
      form: {
        addresses: {
          value: [],
          options: {
            minHistoryMonths: 36,
          },
        },
      },
      valid: false,
    };
  },

  computed: mapState(['dashboard']),

  methods: {
    ...Api,

    /**
     * Maps an address ready for submitting
     * @param {Object} address
     * @param applicantId
     */
    prepareApiAddress(address, applicantId) {
      let movedInDate = null;
      try {
        movedInDate = address.date.format('YYYY-MM-DD');
      } catch (e) {
        Bugsnag.notify(e);
      }

      return {
        // Required
        postcode: address.address?.postcode ?? null,
        residential_status: address.residentialStatus ?? null,
        moved_in: movedInDate,
        street: address.address?.street ?? null,

        // Optional (but really should be required!)
        building_name: address.address?.buildingName ?? null,
        building_number: address.address?.buildingNumber ?? null,
        town: address.address?.town ?? null,
        key: address.address?.key ?? null,
        applicant_id: applicantId ?? null,
        organisation: null,
        flat: null,
        county: null,
        country: null,
        locality: null,
        latitude: null,
        longitude: null,
        display: address.address?.display,
      };
    },

    /**
     * Returns the full address string
     * @param address
     * @returns {string|string|*|string}
     */
    getAddressDisplayString(address) {
      // If we've got a display property, return it
      if (address.display) {
        return address.display;
      }

      // Don't have a display property so create our own one from the data we have.
      let display =
        (address.building_name ? address.building_name + ', ' : '') +
        (address.building_number ? address.building_number + ', ' : '') +
        (address.street ? address.street + ', ' : '') +
        (address.town ? address.town + ', ' : '') +
        (address.postcode ? address.postcode : '');

      // If the last char is a comma, strip it
      if (display[display.length - 1] === ',') {
        return display.substr(0, display.length - 1);
      }

      // Otherwise, return the full thing
      return display;
    },

    /**
     * Fired when an address is added from the address finder
     * Saves the newly added address
     * @param {Object} data 'all': All addresses so far, 'current': Newly added address, 'hasEnoughHistory': Whether we have enough history
     */
    async addAddressHistory(data) {
      // Add the address to the database and Vuex store
      const newAddress = await this.apiStoreAddress(
        this.dashboard.applicant.id,
        this.prepareApiAddress(data.current, this.dashboard.applicant.id)
      );

      // Add the address on the front-end
      this.form.addresses.value.push(newAddress);

      // Re-order the addresses on the front-end by the moved in date
      this.form.addresses.value.sort((a, b) => {
        return new Date(b.moved_in) - new Date(a.moved_in);
      });

      // Do we have enough history yet?
      this.validate();
    },

    /**
     * Stores an address against an applicant (API & Store)
     * @param {Number} applicantId
     * @param {Object} address
     */
    apiStoreAddress(applicantId, address) {
      this.$emit('loading', true, 'Saving address');
      return axios
        .post(methods.getApiBaseUrl() + '/applicants/' + applicantId + '/addresses', {
          address: address,
        })
        .then((r) => {
          if (r.data.success && r.data.data?.address?.id) {
            // Return the new address
            return {
              ...address,
              id: r.data.data.address.id,
            };
          }
          throw new Error(`Failed to save address for applicant ${this.dashboard.applicant.id}`);
        })
        .catch((e) => {
          Bugsnag.notify(e);
          this.error =
            'There was a problem saving your address. If this problem continues, please get in touch';
        })
        .finally(() => {
          this.$emit('loading', false);
        });
    },

    /**
     * Adds all addresses and updates the applicant's DOB and progresses
     * to the next step
     */
    async save() {
      if (this.isAddressHistoryEnough(this.form.addresses.value)) {
        // Back to the overview screen
        return await this.$router.push({ name: 'applications__review' });
      }
    },

    /**
     * Restores address history from the API.
     */
    restore() {
      this.$emit('loading', true, 'Getting your address history');
      this.error = null;
      axios
        .get(`${methods.getApiBaseUrl()}/applicants/${this.dashboard.applicant.id}/addresses`)
        .then((r) => {
          if (r.data.success) {
            this.form.addresses.value = r.data.data.items;

            // Validate
            this.valid = this.isAddressHistoryEnough(this.form.addresses.value);

            this.$emit('loading', false);
            return;
          }
          throw new Error(
            `API failed to return applicant's address history (${this.dashboard.applicant.id})`
          );
        })
        .catch((e) => {
          this.$emit('loading', false);
          Bugsnag.notify(e);
          this.error =
            'There was a problem retrieving your address history. If this problem continues, please get in touch.';
        });
    },

    /**
     * Removes the address from the front-end and API
     * @param addressId
     * @param index
     * @returns {Promise<void>}
     */
    async removeAddress(addressId, index) {
      const addressRemoved = await this.apiRemoveAddress(this.dashboard.applicant.id, addressId);

      if (this.form.addresses.value[index] && addressRemoved) {
        this.form.addresses.value.splice(index, 1);
      }

      this.validate();
    },

    /**
     * Removes an address (API & Store)
     * @param {Number} applicantId
     * @param {Object} address
     */
    apiRemoveAddress(applicantId, addressId) {
      this.$emit('loading', true, 'Removing address');
      return axios
        .delete(this.getApiBaseUrl() + '/applicants/' + applicantId + '/addresses/' + addressId)
        .then((r) => {
          return r.data.success;
        })
        .catch((e) => {
          Bugsnag.notify(e);
        })
        .finally(() => {
          this.$emit('loading', false);
        });
    },

    /**
     * Returns true if we have enough address history
     * @param {Array} addresses
     */
    isAddressHistoryEnough(addresses = []) {
      const now = moment().startOf('month');
      return (
        addresses.filter((address) => {
          return (
            now.diff(moment(address.moved_in), 'months') >=
            this.form.addresses.options.minHistoryMonths
          );
        }).length > 0
      );
    },

    validate() {
      this.valid = this.isAddressHistoryEnough(this.form.addresses.value);
    },
  },

  async mounted() {
    // Restore values from Vuex if we have them
    await this.restore();

    this.validate();
  },
};
</script>

<style scoped></style>
