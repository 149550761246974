<template>
  <div
    v-if="getHumanString(filter.type)"
    class="inline-flex items-center py-1 pl-3 pr-1 rounded-full uppercase text-xs font-medium dealer-bg-primary text-white"
  >
    <span>{{ getHumanString(filter.type) }}</span>
    <button
      @click="clickHandler()"
      type="button"
      class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-white hover:dealer-bg-secondary hover:dealer-text-primary focus:outline-none focus:dealer-bg-primary focus:text-white"
    >
      <span class="sr-only">Remove small option</span>
      <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
        <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    clickHandler() {
      this.$emit('click');
    },

    getHumanString(filterType) {
      const filters = {
        make: `Make: ${this.filter.value}`,
        model: `Model: ${this.filter.value}`,
        min_price: `From: £${this.filter.value}`,
        max_price: `To: £${this.filter.value}`,
        postcode: `Postcode: ${this.filter.value}`,
        radius: `Within: ${this.filter.value} miles`,
        city: `City: ${this.filter.value}`,
        body_type: `Type: ${this.filter.value}`,
        exterior_color: `Colour: ${this.filter.value}`,
        doors: `${this.filter.value} doors`,
        min_age: `From: ${this.filter.value}`,
        max_age: `To: ${this.filter.value}`,
        min_mileage: `From: ${this.filter.value} miles`,
        max_mileage: `To: ${this.filter.value} miles`,
        fuel_type: `Fuel: ${this.filter.value}`,
        transmission: `Gearbox: ${this.filter.value}`,
      };

      if (filters[filterType]) {
        return filters[filterType];
      }

      return false;
    },
  },
};
</script>
