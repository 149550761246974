<template>
  <section class="dashboard-messages">
    <div class="bg-white shadow overflow-hidden rounded-md">
      <ul class="divide-y divide-gray-200">
        <li
          class="message border-b-1 border-gray-300 item block px-5 py-3"
          v-for="(message, i) in dashboard.messages.inbox"
          :key="i"
          @click="openMessage(i)"
          :class="{ 'bg-blue-100': !message.read, 'bg-gray-100': message.read }"
        >
          <div class="grid grid-cols-2 gap-3 cursor-pointer">
            <div :class="{ 'font-bold': !message.read }">
              <span class="icon mr-3">
                <span :class="{ hidden: !message.read }">
                  <i class="fas fa-envelope-open"></i>
                </span>
                <span :class="{ hidden: message.read }">
                  <i class="fas fa-envelope"></i>
                </span>
              </span>
              <span class="subject">{{ message.title }}</span>
            </div>
            <div class="text-right">
              {{ moment(message.date).format('Do MMM YYYY h:mma') }}
            </div>
          </div>
          <div v-show="currentMessageIndex === i" class="content p-2">
            <div class="inner mt-3 rounded-md p-8 bg-white shadow-md" v-html="message.copy" />
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Banner from '@/components/Ui/Dashboard/Banner';
import Breadcrumbs from '@/components/Ui/Breadcrumbs';
import { mapState } from 'vuex/dist/vuex.esm.browser';

export default {
  components: {
    Banner,
    Breadcrumbs,
  },

  computed: mapState(['dashboard']),

  data() {
    return {
      currentMessageIndex: null,
    };
  },

  methods: {
    openMessage(index) {
      if (this.currentMessageIndex !== index) {
        this.currentMessageIndex = index;
        let currentMessage = this.dashboard.messages.inbox[index];
        currentMessage.read = true;

        // Mark it as read
        this.$store.dispatch('updateMessage', currentMessage.id, currentMessage);
      } else {
        this.currentMessageIndex = null;
      }
    },
  },
};
</script>
