import { render, staticRenderFns } from "./Logo.vue?vue&type=template&id=164020e0&scoped=true"
import script from "./Logo.vue?vue&type=script&lang=js"
export * from "./Logo.vue?vue&type=script&lang=js"
import style0 from "./Logo.vue?vue&type=style&index=0&id=164020e0&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "164020e0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/bitnami/apache2/htdocs/flowcarfinance/tmp-dealer/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('164020e0')) {
      api.createRecord('164020e0', component.options)
    } else {
      api.reload('164020e0', component.options)
    }
    module.hot.accept("./Logo.vue?vue&type=template&id=164020e0&scoped=true", function () {
      api.rerender('164020e0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Layout/Logo.vue"
export default component.exports