<template>
  <div id="app" class="app relative bg-white overflow-hidden" :class="$route.name">
    <!-- Loading overlay -->
    <Spinner v-if="isLoading.enabled" :text="isLoading.text" />
    <router-view @loading="loading" @request-toggle-chat="toggleChat()" />
  </div>
</template>

<script>
import Spinner from '@/components/Ui/Spinner';
import { mapState } from 'vuex/dist/vuex.esm.browser';

export default {
  components: {
    Spinner,
  },

  data() {
    return {
      isLoading: {
        enabled: false,
        text: null,
      },
    };
  },

  computed: mapState(['dealer']),

  methods: {
    /**
     * Enables/disables full screen loading animation.
     */
    loading(state = false, text = 'One moment...') {
      this.isLoading.enabled = state;
      this.isLoading.text = text;
    },
  },

  mounted() {
    if (process.env.NODE_ENV === 'production') {
      // Set GTM ID if Unity otherwise Flow
      if (this.dealer.name === 'Unity') {
        this.$gtm.id = 'GTM-MC69667';
      } else {
        // TODO: Check if this is still the GTM tag to use or whether it needs to change to Mag (FAO: Ed)
        this.$gtm.id = 'GTM-5N4KQRV';
      }

      // Set GTM enable to true after vue pages are loaded
      this.$gtm.enable(true);
    }

    // Add icons if no dealer id
    if (!this.dealer.id) {
      let linkMaskIcon = document.createElement('link');
      linkMaskIcon.rel = 'mask-icon';
      linkMaskIcon.href = 'img/icons/safari-pinned-tab.svg';
      linkMaskIcon.color = '#6CBAA7';
      document.head.appendChild(linkMaskIcon);

      let linkAppleTouchIcon = document.createElement('link');
      linkAppleTouchIcon.rel = 'apple-touch-icon';
      linkAppleTouchIcon.href = 'img/icons/apple-touch-icon.png';
      document.head.appendChild(linkAppleTouchIcon);
    } else {
      // Remove all generated by vue cli plugin
      let appleMeta = document.querySelector(
        'meta[name="apple-mobile-web-app-title"], meta[name="msapplication-TileImage"], link[rel="mask-icon"], link[rel="apple-touch-icon"] '
      );
      appleMeta.remove();
    }
  },
};
</script>
