<template>
  <section class="dashboard">
    <Header @request-toggle-chat="$emit('request-toggle-chat')" />
    <DashboardBanner
      v-if="$route.meta.banner === 'status' && dashboard.application.reference && display.banner"
    ></DashboardBanner>
    <StandardBanner
      v-if="$route.meta.banner === 'default'"
      :title="$route.meta.bannerTitle || $route.meta.title"
    ></StandardBanner>
    <Breadcrumbs />

    <main id="main" class="px-4 sm:px-6 py-8 md:py-16 bg-gray-100">
      <div v-if="display.page" class="mx-auto max-w-3/4 md:max-w-7xl lg:max-w-7xl w-full">
        <div class="grid gap-10 md:gap-20" :class="getColumnClasses()">
          <div class="text-flow-blue dealer-text-secondary col-span-1">
            <router-view
              v-if="$store.state.dashboard.application.id"
              @loading="loading"
              @request-toggle-chat="$emit('request-toggle-chat')"
            />
          </div>
          <div v-if="$route.name === 'home'">
            <div v-if="lastStatus() !== 'Declined' && lastStatus() !== 'User cancelled'">
              <h3 class="font-light">Progress</h3>
              <ApplicationProgress />
            </div>
          </div>
        </div>
      </div>
      <div class="border-flow-blue dealer-text-primary col-span-1">
        <div id="chatholder"></div>
      </div>
    </main>
    <Footer />
  </section>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import Footer from '@/components/Layout/Footer';
import Breadcrumbs from '@/components/Ui/Breadcrumbs';
import messages from '@/lib/messages';
import ApplicationProgress from '@/components/Ui/Dashboard/ApplicationProgress';
import methods from '@/lib/methods';
import store from '@/store';
import DashboardBanner from '@/components/Ui/Banners/DashboardBanner';
import StandardBanner from '@/components/Ui/Banners/StandardBanner';
import Header from '@/components/Layout/Dashboard/Header';
import Bugsnag from '@bugsnag/js';

export default {
  components: {
    StandardBanner,
    DashboardBanner,
    Footer,
    Breadcrumbs,
    ApplicationProgress,
    Header,
  },

  computed: mapState(['dashboard', 'dealer']),

  data() {
    return {
      status: null,
      messages: messages,
      display: {
        banner: false,
        page: false,
      },
    };
  },

  methods: {
    ...methods,

    lastStatus() {
      if (this.dashboard.application.statuses?.length) {
        return this.dashboard.application.statuses[this.dashboard.application.statuses.length - 1]
          .internal_stage;
      }
      return null;
    },

    getColumnClasses() {
      let baseClass = 'grid-cols-1';

      if (this.$route.meta.columns && !isNaN(this.$route.meta.columns)) {
        baseClass += ' md:grid-cols-' + this.$route.meta.columns;
      }

      return baseClass;
    },

    loading(state = false, text = 'One moment...') {
      this.$emit('loading', state, text);
    },

    /**
     * Decides which messages to send to the user
     */
    processMessages() {
      // Send them the IDD message ('sendMessage' only adds the message if it doesn't already exist)
      this.$store.dispatch('addMessage', messages.idd);

      // Send them the 'accepted' message if they've got an accepted status
      if (
        this.dashboard.application.statuses.filter((status) => status.internal_stage === 'Accepted')
          .length
      ) {
        this.$store.dispatch('addMessage', messages.accepted);
      }

      // Send them the 'accepted' message if they've got an accepted status
      if (
        this.dashboard.application.statuses.filter((status) => status.internal_stage === 'Declined')
          .length
      ) {
        this.$store.dispatch('addMessage', messages.declined);
      }

      // Get messages sent from the API
      axios.get(this.getApiBaseUrl() + '/me/messages').then((r) => {
        if (r.data.success && r.data.data.items.length) {
          const items = r.data.data.items;

          items.forEach((item) => {
            this.$store.dispatch('addMessage', {
              id: item.frontend_id,
              title: item.title,
              copy: item.copy,
            });
          });
        }
      });
    },
  },

  async mounted() {
    // If application reference wasn't specified, return to application management
    if (this.$route.params.reference === null) {
      return await this.$router.push({ name: 'applications__manage' });
    }

    // Get application updates
    await this.fetchApplication(this.$route.params.reference);
    this.display.banner = true;
    this.processMessages();
    this.display.page = true;

    // Inject dealer styles if available
    this.injectDealerStyles();

    // Update favicon if set
    // TODO: Should we move this to the injectDealerStyles global method?
    if (this.dealer.favicon_url) {
      let favicon = document.querySelector('link[rel="icon"]');
      favicon.href = this.dealer.favicon_url;
    }
  },
};
</script>

<style lang="scss" scoped>
.chatPulse {
  box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
  animation: pulse-blue 2s infinite;
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(39, 42, 92, 0.9);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(39, 42, 92, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(39, 42, 92, 0);
  }
}
</style>
