import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import moment from 'moment';
import { stripMobilePrefix } from '@/lib/Helpers';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

const admin = {
  logged: false,
  token: null,
};

const web = {
  reviews: [],
  usps: [],
  home: [],
  about: [],
  blog: [],
  posts: [],
  values: [],
  settings: [],
  faqs: [],
  feed: [],
  vehicles: {
    recentlyViewed: null,
  },
  calculation: {
    vehicle: null,
    figures: {
      AnnualMileage: 10000,
      CashPrice: 18000,
      Term: 36,
      QuoterAPR: 9.9,
      PartExchange: 0,
      OutstandingSettlement: 0,
      DepositCash: 0,
      CurrentMileage: 0,
    },
    quotes: [],
  },
};

const journey = {
  progress: null, // TODO
  ga_cid: null,
  applicant: {
    address: {
      addresses: [],
    },
    employment: {
      employments: [],
    },
    verified: false,
  },
  application: {
    step: 1,
  },
  partner: {
    referredAt: null,
  },
  marketingPixel: null,
  applicationQuestion: null,
  applicationProperties: [],
};

const dashboard = {
  authed: false,
  user: {},
  application: {},
  applicant: {},
  creditReport: {
    complete: false,
    uniscore: null,
    apr_estimate: null,
    apr_override: null,
    tips: [],
    route: null,
  },
  calculator: {
    vehicle: null,
    figures: {
      current_mileage: null,
      price: null,
      deposit: null,
      part_exchange: null,
      outstanding_settlement: null,
      term: null,
      estimated_mileage: null,
    },
  },
  quotes: [],
  messages: {
    inbox: [],
    unread: 0,
  },
  hasCelebrated: false,
};

const dealer = {
  acceptance_terms: null,
  css: null,
  faqs: null,
  favicon: null,
  favicon_url: null,
  id: null,
  legal_text: null,
  logo: null,
  logo_url: null,
  meta_description: null,
  name: null,
  phone: null,
  privacy_policy: null,
  slug: null,
  style_body_text_color: null,
  style_btn_primary_bg_color: null,
  style_btn_primary_radius: null,
  style_btn_primary_text_color: null,
  style_btn_secondary_bg_color: null,
  style_btn_secondary_radius: null,
  style_btn_secondary_text_color: null,
  style_fields_radius: null,
  style_footer_bg_color: null,
  style_footer_text_color: null,
  style_header_bg_color: null,
  style_header_link_color: null,
  style_header_text_color: null,
  style_login_bg: null,
  terms_conditions: null,
  updatedAt: null,
  url: null,
  hs_business_unit_id: null,
  feed_enabled: true,
};

const partner = {
  auth: false,
  data: {},
};

// Anonymous AutoQuote calculations (public facing)
const publicCalculator = {
  vehicle: null,
  figures: {
    AnnualMileage: 10000,
    CashPrice: 18000,
    Term: 48,
    QuoterAPR: 9.9,
    PartExchange: 0,
    OutstandingSettlement: 0,
    DepositCash: null,
    CurrentMileage: 0,
  },
  quotes: [],
};

export default new Vuex.Store({
  state: {
    dealer: dealer,
    journey: journey,
    dashboard: dashboard,
    web: web,
    admin: admin,
    publicCalculator: publicCalculator,
    partner: partner,
    page: null,
    blogPost: null,
  },
  mutations: {
    setAdminLoggedIn(state) {
      state.admin.logged = true;
    },

    setAdminToken(state, token) {
      state.admin.token = token;
    },

    setAdminUsername(state, username) {
      state.admin.username = username;
    },

    setDealer(state, dealer) {
      const timestamp = Math.round(new Date().getTime() / 1000);
      const payload = {
        ...state.dealer,
        ...dealer,
        updatedAt: timestamp,
      };

      state.dealer = payload;
    },

    setApplicant(state, applicant) {
      state.journey.applicant = {
        ...state.journey.applicant,
        ...applicant,
      };
    },

    overrideApplicant(state, applicant) {
      state.journey.applicant = applicant;
    },

    setApplication(state, application) {
      state.journey.application = {
        ...state.journey.application,
        ...application,
      };
    },

    addApplicantAddress(state, address) {
      state.journey.applicant.address.addresses.push(address);
    },

    removeApplicantAddress(state, addressId) {
      for (let i = 0; i < state.journey.applicant.address.addresses.length; i++) {
        if (state.journey.applicant.address.addresses[i].id === addressId) {
          state.journey.applicant.address.addresses.splice(i, 1);
        }
      }
    },

    addApplicantEmployment(state, employment) {
      state.journey.applicant.employment.employments.push(employment);
    },

    removeApplicantEmployment(state, employmentId) {
      for (let i = 0; i < state.journey.applicant.employment.employments.length; i++) {
        if (state.journey.applicant.employment.employments[i].id === employmentId) {
          state.journey.applicant.employment.employments.splice(i, 1);
        }
      }

      if (typeof state.journey.applicant.employment.employments === 'undefined') {
        state.journey.applicant.employment.employments = [];
      }
    },

    setApplicationStep(state, step) {
      if (isNaN(step)) {
        return false;
      }
      state.journey.application.step = step;
    },

    setAuthed(state, auth) {
      state.dashboard.authed = auth;
    },

    setUser(state, user) {
      state.dashboard.user = user;
    },

    setDashboardApplication(state, application) {
      state.dashboard.application = application;
    },

    setDashboardApplicant(state, applicant) {
      state.dashboard.applicant = applicant;
    },

    setCreditReport(state, creditReport) {
      state.dashboard.creditReport = {
        ...state.dashboard.creditReport,
        ...creditReport,
      };
    },

    setCreditReportAprEstimate(state, apr) {
      state.dashboard.creditReport.apr_estimate = apr;
    },
    setCreditReportAprOverride(state, apr) {
      state.dashboard.creditReport.apr_override = apr;
    },

    setQuotes(state, quotes) {
      state.dashboard.quotes = quotes;
    },

    setVehicle(state, vehicle) {
      state.dashboard.calculator.vehicle = vehicle;
    },

    setCalculatorFigures(state, figures) {
      state.dashboard.calculator.figures = figures;
    },

    setJourneyProgress(state, progress) {
      state.journey.progress = progress--;
    },

    increaseJourneyProgress(state, amount) {
      if (state.journey.progress > 100) return;
      state.journey.progress += amount;
    },

    decreaseJourneyProgress(state, amount) {
      if (!state.journey.progress) return;
      state.journey.progress -= amount;
    },

    addMessage(state, message) {
      state.dashboard.messages.inbox.push(message);
    },

    deleteMessage(state, deleteIndex) {
      state.dashboard.messages.inbox.splice(deleteIndex, 1);
    },

    setUnreadMessageCounter(state, count) {
      state.dashboard.messages.unread = count;
    },

    updateMessage(state, index, message) {
      state.dashboard.messages.inbox[index] = message;
    },

    setMessages(state, messages) {
      state.dashboard.messages.inbox = messages;
    },

    setJourney(state, journey) {
      state.journey = journey;
    },

    setDashboard(state, dashboard) {
      state.dashboard = dashboard;
    },

    setReviews(state, reviews) {
      state.web.reviews = reviews;
    },

    setUsps(state, usps) {
      state.web.usps = usps;
    },

    setHomeContent(state, home) {
      state.web.home = home;
    },

    setSettings(state, settings) {
      state.web.settings = settings;
    },

    setAbout(state, about) {
      state.web.about = about;
    },

    setBlog(state, blog) {
      state.web.blog = blog;
    },

    setPosts(state, posts) {
      state.web.posts = posts;
    },

    setBrandValues(state, values) {
      state.web.values = values;
    },

    setFaqs(state, faqs) {
      state.web.faqs = faqs;
    },

    setFeed(state, feed) {
      state.web.feed = feed;
    },

    resetCalculationVehicle(state) {
      state.publicCalculator.vehicle = null;
    },

    setCalculationVehicle(state, vehicle) {
      state.publicCalculator.vehicle = {
        ...state.publicCalculator.vehicle,
        ...vehicle,
      };
    },

    addCalculationQuote(state, quote) {
      state.publicCalculator.quote.push(quote);
    },

    setCalculationCurrentMileage(state, currentMileage) {
      state.publicCalculator.figures.CurrentMileage = currentMileage;
    },

    setCalculationFigures(state, figures) {
      state.publicCalculator.figures = {
        ...state.publicCalculator.figures,
        ...figures,
      };
    },

    setPartner(state, partner) {
      state.journey.partner = {
        ...state.journey.partner,
        ...partner,
      };
    },

    setPartnerLoggedIn(state, isLoggedIn) {
      state.partner.auth = isLoggedIn;
    },

    setPartnerData(state, payload = null) {
      state.partner.data = payload;
    },

    setMarketingPixel(state, data) {
      state.journey.marketingPixel = {
        ...state.journey.marketingPixel,
        ...data,
      };
    },

    setHasCelebrated(state, hasCelebrated) {
      state.dashboard.hasCelebrated = hasCelebrated;
    },

    setRecentlyViewedPublicVehicle(state, vehicle) {
      state.web.vehicles.recentlyViewed = vehicle;
    },

    setPage(state, page) {
      state.page = page;
    },

    setBlogPost(state, post) {
      state.blogPost = post;
    },

    setApplicationQuestion(state, data) {
      state.journey.applicationQuestion = data;
    },

    setApplicationProperties(state, data) {
      state.journey.applicationProperties = data;
    },

    mapApplicationJourney(state, application) {
      // Application details
      state.journey.application = {
        ...state.journey.application,
        ...application,
      };

      // Applicant
      if (application.applicants && application.applicants.length) {
        let applicant = application.applicants[0];
        applicant.mobile_phone = applicant.mobile_phone
          ? stripMobilePrefix(applicant.mobile_phone)
          : null;

        // Restructure the applicant in the response to match the Vuex mapping
        // TODO: This should be updated to match Mag's journey
        applicant = {
          ...applicant,
          address: {
            addresses: applicant.addresses,
          },
          employment: {
            employments: applicant.employments,
          },
        };
        delete applicant.addresses;
        delete applicant.employments;

        state.journey.applicant = {
          ...state.journey.applicant,
          ...applicant,
        };
      }
    },
  },

  actions: {
    addApplicationProperty(context, data) {
      if (!data.key || !data.value) {
        return false;
      }
      let props = context.state.journey.applicationProperties;
      props.push({
        key: data.key,
        value: data.value,
      });
      context.commit('setApplicationProperties', props);
    },

    resetApplicationProperties(context) {
      context.commit('setApplicationProperties', []);
    },

    async addMessage(context, message) {
      if (
        context.state.dashboard.messages.inbox.filter((msg) => msg.id === message.id).length === 0
      ) {
        message.read = false;
        message.date = moment().toISOString();
        context.commit('addMessage', message);
        await context.dispatch('unreadMessages');
      }
    },

    async deleteMessage(context, id) {
      const deleteIndex =
        context.state.dashboard.messages.inbox.findIndex((msg) => msg.id === id) ?? false;
      if (deleteIndex !== false) {
        context.commit('deleteMessage', deleteIndex);
        await context.dispatch('unreadMessages');
      }
    },

    async updateMessage(context, { id, message }) {
      const index =
        context.state.dashboard.messages.inbox.findIndex((msg) => msg.id === id) ?? false;
      context.commit('updateMessage', index, message);
      await context.dispatch('unreadMessages');
    },

    async unreadMessages(context) {
      const count = context.state.dashboard.messages.inbox.filter(
        (msg) => msg.read === false
      ).length;
      context.commit('setUnreadMessageCounter', count);
      await context.dispatch('orderMessages');
    },

    // Order messages by date (descending)
    orderMessages(context) {
      context.commit(
        'setMessages',
        context.state.dashboard.messages.inbox.sort((a, b) => new Date(b.date) - new Date(a.date))
      );
    },

    resetJourney(context) {
      context.commit('setJourney', {
        progress: null, // TODO
        ga_cid: null,
        applicant: {
          address: {
            addresses: [],
          },
          employment: {
            employments: [],
          },
          verified: false,
        },
        application: {
          step: 1,
        },
        partner: {
          referredAt: null,
        },
        marketingPixel: null,
        applicationQuestion: null,
        applicationProperties: [],
      });
    },

    resetDashboard(context) {
      context.commit('setDashboard', dashboard);
    },

    async logout(context) {
      await context.dispatch('resetJourney');
      await context.dispatch('resetDashboard');
    },

    resetJourneyPartner(context) {
      context.state.journey.partner = journey.partner;
    },

    resetPartner(context) {
      context.state.partner = partner;
    },

    resetDealer(context) {
      context.state.dealer = dealer;
    },
  },
  modules: {},
  plugins: [vuexLocal.plugin],
});
