<template>
  <section>
    <!-- 'View' modal -->
    <modal
      name="view-quote"
      styles="border-radius:1rem; background-color: #272A5C;"
      classes="z-10 p-8 text-white leading-5"
      :adaptive="true"
      height="auto"
      :scrollable="true"
    >
      <div class="h-10 block w-full text-right">
        <span
          class="float-right flex items-center cursor-pointer"
          @click="$modal.hide('view-quote')"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="times"
            class="svg-inline--fa fa-times fa-w-11"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 352 512"
          >
            <path
              fill="currentColor"
              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
            ></path>
          </svg>

          <span class="ml-3 uppercase text-base">Close</span>
        </span>
      </div>

      <template v-if="selectedQuoteResult">
        <h5 class="text-white mb-0">
          <template v-if="selectedQuoteResult.type === 'PCP'">
            Personal Contract Purchase
          </template>
          <template v-else> Hire Purchase </template>
        </h5>
        <h1 class="text-white mb-2">
          &pound;{{ toDec(selectedQuoteResult.monthly_payment) }} <small>p/m</small>
        </h1>
        <div class="text-base p-2 font-normal">
          <ul v-if="selectedQuoteResult !== null" class="list-disc list-inside">
            <li>
              <strong class="dealer-text-secondary">{{ selectedQuoteResult.term }}</strong> months
            </li>
            <li>
              <strong class="dealer-text-secondary">{{ selectedQuoteResult.apr }}%</strong> APR
            </li>
            <!--<li><strong>{{ selectedQuoteResult.flat_rate }}%</strong> flat rate</li>-->
            <li>
              <strong class="dealer-text-secondary"
                >&pound;{{ toDec(selectedQuoteResult.cash_price) }}</strong
              >
              cash price
            </li>
            <li>
              <strong class="dealer-text-secondary"
                >&pound;{{ toDec(selectedQuoteResult.net_deposit) }}</strong
              >
              deposit
            </li>
            <li v-if="selectedQuoteResult.annual_mileage">
              <strong class="dealer-text-secondary">{{
                selectedQuoteResult.annual_mileage.toLocaleString()
              }}</strong>
              miles per year
            </li>
            <li>
              <strong class="dealer-text-secondary"
                >&pound;{{ toDec(selectedQuoteResult.final_payment) }}</strong
              >
              final payment
            </li>
            <li>
              <strong class="dealer-text-secondary"
                >&pound;{{ toDec(selectedQuoteResult.total_charge) }}</strong
              >
              total cost of credit
            </li>
            <li>
              <strong class="dealer-text-secondary"
                >&pound;{{ toDec(selectedQuoteResult.total_payable) }}</strong
              >
              total payable
            </li>
          </ul>
        </div>

        <button
          v-if="canApply"
          @click="apply(selectedQuoteResult)"
          class="btn-secondary dealer-bg-secondary hover:dealer-bg-primary hover:dealer-text-secondary mt-4"
        >
          Apply
        </button>
      </template>
    </modal>

    <h3>Previous quotes ({{ mappedQuotes.length }}):</h3>

    <div class="shadow overflow-hidden border-b border-gray-200 rounded-lg hidden sm:block">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="dealer-bg-primary text-white">
          <tr>
            <th
              scope="col"
              class="px-2 sm:px-4 py-3 text-left text-sm font-bold uppercase tracking-wider"
            >
              Date
            </th>
            <th
              scope="col"
              class="px-2 sm:px-4 py-3 text-left text-sm font-bold uppercase tracking-wider"
            >
              PCP
            </th>
            <th
              scope="col"
              class="px-2 sm:px-4 py-3 text-left text-sm font-bold uppercase tracking-wider"
            >
              HP
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(quote, i) in mappedQuotes" :key="i">
            <td class="px-2 sm:px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
              {{ quote.created_at | moment('Do MMM YY') }}
            </td>
            <td
              v-if="quote.results.PCP !== null"
              class="px-2 sm:px-4 py-4 whitespace-nowrap text-lg text-gray-500"
            >
              &pound;{{ toDec(quote.results.PCP.monthly_payment) }}
              <span
                @click="viewQuoteResult(quote.results.PCP)"
                class="btn-primary dealer-bg-primary hover:dealer-bg-secondary hover:dealer-text-primary btn-xxs float-right"
                >View</span
              >
            </td>
            <td v-else class="px-2 sm:px-4 py-4 whitespace-nowrap text-sm text-gray-500">N/A</td>
            <td
              v-if="quote.results.HP !== null"
              class="px-2 sm:px-4 py-4 whitespace-nowrap text-lg text-gray-500"
            >
              &pound;{{ toDec(quote.results.HP.monthly_payment) }}
              <span
                @click="viewQuoteResult(quote.results.HP)"
                class="btn-primary dealer-bg-primary hover:dealer-bg-secondary hover:dealer-text-primary btn-xxs float-right"
                >View</span
              >
            </td>
            <td v-else class="px-2 sm:px-4 py-4 whitespace-nowrap text-sm text-gray-500">N/A</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="flow-root mt-3 block sm:hidden">
      <!--            <ul role="list" class="-my-5">-->
      <!--                <li v-for="(quote, i) in mappedQuotes" :key="i" class="py-3">-->
      <!--                    <div class="relative">-->
      <!--                        <h3 class="text-sm font-semibold text-gray-800 mb-3">-->
      <!--                        <div>-->
      <!--                            <span class="absolute inset-0" aria-hidden="true" />-->
      <!--                            {{ quote.created_at | moment('Do MMM YY') }}-->
      <!--                        </div>-->
      <!--                        </h3>-->
      <!--                        <div class="grid grid-cols-2 gap-4">-->
      <!--                            <button v-if="quote.results.PCP !== null" class="btn-primary dealer-bg-primary hover:dealer-bg-secondary hover:dealer-text-primary flex justify-center" @click="viewQuoteResult(quote.results.PCP)">-->
      <!--                                <div class="rounded-full h-8 w-8 p-1 dealer-text-primary dealer-bg-secondary flex items-center justify-center text-xs font-bold mr-4">PCP</div>   -->
      <!--                                &pound;{{ toDec(quote.results.PCP.monthly_payment) }}-->
      <!--                            </button>-->
      <!--                            <button v-if="quote.results.HP !== null" class="btn-primary dealer-bg-primary hover:dealer-bg-secondary hover:dealer-text-primary flex justify-center" @click="viewQuoteResult(quote.results.HP)">-->
      <!--                                <div class="rounded-full h-8 w-8 p-1 dealer-text-primary dealer-bg-secondary flex items-center justify-center text-xs font-bold mr-4">HP</div>-->
      <!--                                &pound;{{ toDec(quote.results.HP.monthly_payment) }}-->
      <!--                            </button>-->
      <!--                        </div>-->
      <!--                    </div>-->
      <!--                </li>-->
      <!--            </ul>-->

      <ol class="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500">
        <li
          class="py-4"
          v-for="(quote, i) in mappedQuotes"
          :key="i"
          v-if="quote.results.PCP !== null"
        >
          <time class="w-28 flex-none">{{ quote.created_at | moment('Do MMM YY') }}</time>
          <!--                    <p class="mt-2 flex-auto font-semibold text-gray-900 sm:mt-0">View house with real estate agent</p>-->
          <div class="flex items-center">
            <div class="dealer-text-primary text-xs font-bold mr-4">PCP</div>
            &pound;{{ toDec(quote.results.PCP.monthly_payment) }} +
          </div>
        </li>
      </ol>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      mappedQuotes: [],
      selectedQuoteResult: null,
    };
  },

  props: {
    quotes: {
      type: Array,
      default: () => [],
    },
    canApply: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    /**
     * View a previous quote result
     */
    viewQuoteResult(quoteResult) {
      this.selectedQuoteResult = quoteResult;
      this.$modal.show('view-quote');
    },

    /**
     * Correctly format saved numbers from the API
     */
    toDec(int) {
      return (int / 100).toLocaleString('en-GB');
    },

    apply(quoteResult) {
      this.$modal.hide('view-quote');
      this.$emit('apply', quoteResult);
    },
  },

  mounted() {
    /**
     * Map the quotes and results
     */
    this.quotes.forEach((quote) => {
      let results = {
        PCP: null,
        HP: null,
      };
      if (quote.quote_results.length) {
        quote.quote_results.forEach((result) => {
          results[result.type] = result;
        });
      }

      this.mappedQuotes.push({
        created_at: quote.created_at,
        results: results,
      });
    });
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;

  li {
    position: relative;
    padding-left: 20px;

    &::before {
      content: '';
      height: 7px;
      width: 7px;
      border-radius: 100%;
      margin-right: 10px;
      position: absolute;
      left: 0;
      top: 7px;
      @apply bg-white;
    }
  }
}
</style>
