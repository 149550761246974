const partnerData = {
  '60dd7e9fd82855567c2660d1': {
    name: "Fred's Army",
    colour: null,
    enable_branding: true,
    logo: null,
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '60dd7fa11847f22e5b058541': {
    name: 'Money Saving Expert',
    colour: null,
    enable_branding: true,
    logo: 'logo-mse.png',
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '60dd7f3ee64fd129eb0d4b52': {
    name: 'Honest John',
    colour: null,
    enable_branding: true,
    logo: 'logo-honest-john.png',
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '60dd96147d57743cbd671851': {
    name: 'TPNL',
    colour: null,
    enable_branding: true,
    logo: 'logo-tpnl.png',
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '60ddd5050c9c3239727724b1': {
    name: 'Uniform Benefits',
    colour: null,
    enable_branding: true,
    logo: null,
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '60ddd5165fc41e4a335a9591': {
    name: 'Dining Club',
    colour: null,
    enable_branding: true,
    logo: null,
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '60ddd5230c9c3239727724b3': {
    name: 'Credit Plus',
    colour: null,
    enable_branding: true,
    logo: null,
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '60ddd530a08e6a45be1cb881': {
    name: 'Choose Wisely',
    colour: null,
    enable_branding: true,
    logo: 'logo-choose-wisely.png',
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '60ddd5a60c9c3239727724b5': {
    name: 'Quotezone',
    colour: null,
    enable_branding: true,
    logo: 'logo-quotezone.png',
    enable_newsletter_signup: false,
    sharing_checkbox_label:
      'Quotezone would like to keep you up to date with other ways to save money and relevant products you may be interested in via email.\n\nI agree that Flow Car Finance can transfer my contact details to Quotezone and for Quotezone to contact me via email.',
  },
  '60f83f67e815c769b03761f1': {
    name: 'Your First Click',
    colour: null,
    enable_branding: true,
    logo: null,
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '60ffbee39dacb110635e5591': {
    name: 'Find My Quote',
    colour: null,
    enable_branding: true,
    logo: 'logo-find-my-quote.png',
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '611e4e77656e3872da76fa71': {
    name: 'Health Service Discounts',
    colour: null,
    enable_branding: true,
    logo: 'logo-hsd.png',
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '611e4f5b91a3cc1f046ba711': {
    name: 'Discount for Carers',
    colour: null,
    enable_branding: true,
    logo: 'logo-dfc.png',
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '611e4f7056ba097a06690f91': {
    name: 'Discount for Teachers',
    colour: null,
    enable_branding: true,
    logo: 'logo-dft.png',
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '611e4f8491a3cc1f046ba713': {
    name: 'Charity Worker Discounts',
    colour: null,
    enable_branding: true,
    logo: 'logo-cwd.png',
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '6124efd7167ee12f9c299ed2': {
    name: 'Money Expert',
    colour: null,
    enable_branding: true,
    logo: 'logo-money-expert.png',
    enable_newsletter_signup: true,
    sharing_checkbox_label:
      'I am happy for MoneyExpert to contact me with money-saving offers tailored to my profile including utilities and insurance. You can <a href="https://www.moneyexpert.com/preference-centre/" style="text-decoration:underline" target="_blank">unsubscribe</a> at any time. <a href="https://www.moneyexpert.com/privacy-policy/" style="text-decoration:underline" target="_blank">Full Privacy Policy</a>.',
  },
  '616d75fdb78bd263be174472': {
    name: 'CompareNI',
    colour: null,
    enable_branding: true,
    logo: 'logo-compare-ni.png',
    enable_newsletter_signup: true,
    sharing_checkbox_label:
      'CompareNI would like to keep you up to date with other ways to save money and relevant products you may be interested in via email. I agree that Flow Car Finance can transfer my contact details to CompareNI and for CompareNI to contact me via email.',
  },
  '61f00a7dda0fc64fb763ce61': {
    name: 'Radio Times',
    colour: null,
    enable_branding: true,
    logo: 'logo-radio-times.png',
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '624c5bb7b3be2909106096e2': {
    name: 'Smart Motoring',
    colour: null,
    enable_branding: true,
    logo: null,
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '624c5dfc93206c43996764d2': {
    name: 'TopCashback',
    colour: null,
    enable_branding: true,
    logo: 'logo-top-cashback.png',
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '624d8a87d866f9399e43c772': {
    name: 'Buyacar',
    colour: null,
    enable_branding: true,
    logo: 'logo-buy-a-car.png',
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '628b73d371ba241fb31a72b1': {
    name: 'News Now',
    colour: null,
    enable_branding: false,
    logo: null,
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '62de92ab10f82066e0381362': {
    name: 'Auto Express',
    colour: null,
    enable_branding: true,
    logo: 'logo-auto-express.png',
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '63cf9d2f435f4767582e2642': {
    name: 'MoneySOS',
    colour: null,
    enable_branding: true,
    logo: 'logo-money-sos.svg',
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  '4wq63lhzp6jvwbsg8j6wjftl': {
    name: 'Autofunder',
    colour: null,
    enable_branding: true,
    logo: null,
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
  lt7nebyxl3gthmpx1ooptdrz: {
    name: 'Motorly',
    colour: null,
    enable_branding: true,
    logo: null,
    enable_newsletter_signup: true,
    sharing_checkbox_label: null,
  },
};

export { partnerData };
