<template>
  <section class="dashboard-carfeed-search">
    <h6
      @click="backToResults()"
      class="cursor-pointer flex items-center text-flow-teal hover:text-flow-blue dealer-text-primary hover:dealer-text-secondary"
    >
      <i class="fas fa-long-arrow-alt-left mr-2"></i> Back to results
    </h6>

    <div v-if="vehicle !== null" class="grid grid-cols-1 lg:grid-cols-2 md:gap-8 mb-8">
      <section class="relative">
        <!-- <button class="btn-secondary dealer-bg-secondary hover:dealer-bg-primary hover:dealer-text-secondary btn-xs absolute z-10 right-5 top-5"><i class="fas fa-heart mr-2"></i> Save</button> -->
        <GallerySlider v-if="images.length" :images="images" />

        <CarInformation
          v-if="vehicle"
          :vehicle="vehicle"
          class="hidden lg:block test"
          :class="images.length < 4 ? '-mt-4' : 'mt-8'"
        />
      </section>

      <section class="mt-5 lg:mt-0 relative">
        <div
          class="dealer-bg-secondary text-white text-sm text-center py-1 px-4 font-bold rounded-full absolute top-3 right-0 uppercase"
          v-if="exists(vehicle.quick_code)"
        >
          {{ vehicle.quick_code }}
        </div>
        <h3 class="mb-0 text-flow-blue dealer-text-secondary">
          {{ vehicle.make }} {{ vehicle.model }}, {{ vehicle.variant }}
        </h3>
        <h5 class="mb-0 font-semibold text-flow-blue dealer-text-secondary">{{ vehicle.year }}</h5>
        <div class="text-3xl font-semibold mt-5">
          &pound;{{ vehicle.price.toLocaleString('en-GB') }}
        </div>
        <ul class="inline-flex flex-wrap items-center gap-x-4 mt-8 leading-5 space-y-1.5">
          <li v-if="exists(vehicle.year)" class="flex flex-nowrap items-center">
            <i class="fas fa-calendar mr-1"></i> <span>{{ vehicle.year }}</span>
          </li>
          <li v-if="exists(vehicle.miles)" class="flex flex-nowrap items-center">
            <i class="fal fa-road mr-1"></i>
            <span>{{ vehicle.miles.toLocaleString('en-GB') }} miles</span>
          </li>
          <li v-if="exists(vehicle.body_type)" class="flex flex-nowrap items-center">
            <i class="fas fa-car mr-1"></i> <span>{{ vehicle.body_type }}</span>
          </li>
          <li v-if="exists(vehicle.transmission)" class="flex flex-nowrap items-center">
            <span class="hidden xs:block"><i class="fas fa-cogs mr-1"></i></span>
            <span>{{ vehicle.transmission }}</span>
          </li>
          <li v-if="exists(vehicle.fuel_type)" class="flex flex-nowrap items-center">
            <i class="fas fa-gas-pump mr-1"></i> <span>{{ vehicle.fuel_type }}</span>
          </li>
          <li v-if="exists(vehicle.doors)" class="flex flex-nowrap items-center">
            <i class="fas fa-door-open mr-1"></i> <span>{{ vehicle.doors }} doors</span>
          </li>
          <li v-if="exists(vehicle.num_owners)" class="flex flex-nowrap items-center">
            <i class="fas fa-male mr-1"></i> <span>{{ vehicle.num_owners }} owner(s)</span>
          </li>
          <li v-if="vehicle.vehicle_registration_mark" class="flex flex-nowrap items-center">
            <i class="fas fa-ellipsis-h mr-1"></i>
            <span class="uppercase mr-2">{{ vehicle.vehicle_registration_mark }} </span> reg
          </li>
        </ul>

        <!-- They've already submitted an application -->
        <div
          v-if="dashboard.application.completed && quote && !quote.hidden"
          class="bg-yellow-300 p-5 rounded-md mt-10 shadow-lg mb-5"
        >
          <template v-if="loading.quote"> Fetching your submitted quote... </template>
          <template v-else-if="quote !== null">
            <!-- They have applied for this exact vehicle -->
            <div
              v-if="
                quote.vehicle.external_id && $route.params.vehicleId === quote.vehicle.external_id
              "
            >
              You have already applied for finance on this vehicle. To find out more,
              <span
                class="underline"
                @click="$router.push({ name: 'dashboard__calculator__quotes' })"
                >click here</span
              >.
            </div>

            <!-- They have applied for a different vehicle -->
            <div v-else>
              You have already applied for finance on a different vehicle. You can still quote on
              other vehicles but if you would like to make any changes to your application, please
              chat with or call us. To find out more about your current application,
              <span
                class="underline"
                @click="$router.push({ name: 'dashboard__calculator__quotes' })"
                >click here</span
              >.
            </div>
          </template>
          <div v-else>
            There was a problem loading the quote you've applied for. Please try again later.
          </div>
        </div>

        <template v-if="vehicle.vehicle_registration_mark">
          <ApplyObserver v-slot:default="scope">
            <!-- Tab group -->
            <QuoteTabs @apply="scope.apply" v-if="vehicle" :vehicle="vehicle" />

            <!-- Previous quotes -->
            <PreviousQuotes
              @apply="scope.apply"
              class="mt-5"
              v-if="existingQuotes.length"
              :quotes="existingQuotes"
            />
          </ApplyObserver>
        </template>
        <template v-else>
          <div class="p-5 md:p-10 dealer-bg-primary rounded-lg mt-5">
            <h2 class="text-flow-white">Interested?</h2>
            <p class="text-flow-white">
              We're unable to provide an online quote for this vehicle at the moment but if you are
              interested, please chat with or call us and we'll be able to help.
            </p>
            <ul class="text-flow-white">
              <li v-if="setting.telephone">
                <h3 class="text-flow-white mb-4">
                  Call on <a :href="`tel:${setting.telephone}`">{{ setting.telephone }}</a>
                </h3>
              </li>
              <li>
                Your reference number: <strong>{{ dashboard.application.reference }}</strong>
              </li>
              <li>
                This vehicle's reference: <strong>{{ vehicle.quick_code }}</strong>
              </li>
            </ul>
          </div>
        </template>
        <!--</template>-->
      </section>

      <CarInformation v-if="vehicle" :vehicle="vehicle" class="block lg:hidden mt-8" />
    </div>
  </section>
</template>

<script>
// import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
// import 'swiper/swiper-bundle.css'

import VehicleFeed from '@/lib/VehicleFeed';
import GallerySlider from '@/components/Ui/CarSearch/GallerySlider';

import QuoteTabs from '@/components/Dashboard/CarSearch/QuoteTabs';
import CarInformation from '@/components/Dashboard/CarSearch/CarInformation';
import PreviousQuotes from '@/components/Dashboard/CarSearch/PreviousQuotes';
import { mapState } from 'vuex';
// import QuoteSection from '@/components/Dashboard/CarSearch/QuoteSection'
import ApplyObserver from '@/components/Dashboard/CarSearch/ApplyObserver';
import Api from '@/lib/Api';

export default {
  components: {
    VehicleFeed,
    GallerySlider,
    QuoteTabs,
    CarInformation,
    // QuoteSection,
    ApplyObserver,
    PreviousQuotes,
  },

  // directives: {
  //     swiper: directive
  // },

  computed: {
    ...mapState(['dashboard']),
    setting() {
      return this.$store.state.web.settings;
    },
    // swiper() {
    //     return this.$refs.mySwiper.$swiper
    // }
  },

  data() {
    return {
      feed: new VehicleFeed(),
      vehicle: null,
      existingQuotes: [],
      images: [],
      items: [
        { title: 'Description', icon: 'fa-book-open', content: null },
        { title: 'Location', icon: 'fa-map-marker-alt' },
        { title: 'Other information', icon: 'fa-book-open' },
      ],
      quote: null,
      loading: {
        quote: false,
      },
    };
  },

  methods: {
    ...Api,

    async getVehicleDetails(vehicleId) {
      this.$emit('loading', true, 'Getting the keys...');
      const response = await this.feed.getVehicle(vehicleId);

      if (response === null) {
        this.$emit('loading', false);
        return await this.$router.push({ name: 'not-found' });
      }

      // Vehicle data
      this.vehicle = response.vehicle;
      this.images = this.processCarImages(response.images);

      // Existing quotes data
      this.existingQuotes = response.existing_quotes;

      // TODO: Needs to be done a better way!
      this.items[0].content = `<p><strong>${this.vehicle.heading}</strong></p><p>${this.vehicle.seller_comments}</p>`;
      // this.items[1].content = `<ul>Sold by <strong>${this.vehicle.dealer}</strong> at <strong></strong>.</p>`

      this.$emit('loading', false);
    },

    /**
     * Removes image URLs which do not exist
     * @param {Array} images Array of image URLs
     * @returns {Array} Filtered images URL array
     */
    processCarImages(images) {
      const filtered = [];

      images.forEach(async (url) => {
        await this.checkIfImageExists(url, (exists) => {
          if (exists) {
            filtered.push(url);
          }
        });
      });

      return filtered;
    },

    /**
     * Returns whether an image URL exists
     * @param url
     * @param callback
     */
    checkIfImageExists(url, callback) {
      const img = new Image();
      img.src = url;

      if (img.complete) {
        callback(true);
      } else {
        img.onload = () => {
          callback(true);
        };

        img.onerror = () => {
          callback(false);
        };
      }
    },

    exists(property) {
      return typeof property !== 'undefined' && property !== null && property !== '';
    },

    backToResults() {
      this.$router.push({
        name: 'dashboard__search__results',
        query: this.$route.query,
      });
    },
  },

  async mounted() {
    if (typeof this.$route.params.vehicleId !== undefined) {
      await this.getVehicleDetails(this.$route.params.vehicleId);

      // Is the application complete?
      if (this.dashboard.application.completed) {
        this.loading.quote = true;
        this.quote = await this.apiGetSubmittedQuote(this.dashboard.application.reference);
        this.loading.quote = false;
      }
    } else {
      // Didn't receive a vehicle ID to search for!
      await this.$router.push({ name: 'dashboard__search__details' });
    }
  },
};
</script>
