<template>
  <div class="px-5 py-6 sm:p-7 border-3 border-gray-600 rounded-xxl shadow-md">
    <div class="lg:inline-flex items-center w-full">
      <div class="col-span-1 sm:order-first mr-5">
        <div
          class="border-flow-grey dealer-border-primary bg-white sm:bg-transparent border-5 text-xl sm:text-2xl rounded-full h-12 w-12 sm:h-32 sm:w-32 flex items-center justify-center text-flow-blue dealer-text-secondary font-bold"
        >
          <span v-if="apr" class="capitalize hidden sm:block">{{ apr }}%</span>
          <i class="fas fa-check block sm:hidden"></i>
        </div>
      </div>
      <div class="col-span-2 mt-8 lg:mt-0 sm:mr-5">
        <div
          class="mt-1 text-sm sm:text-sm text-flow-blue dealer-text-secondary"
          v-html="content"
        />

        <div
          class="mt-1 text-sm sm:text-md sm:text-base text-flow-blue dealer-text-secondary underline hover:no-underline font-bold"
        >
          <!-- Tooltip (only visible if enabled) -->
          <div
            v-if="tooltip"
            class="tooltip tooltip-icon mb-6 cursor-pointer"
            @click="show('aprModal')"
          >
            More info
          </div>

          <!-- More info modal -->
          <!--<div v-if="tooltip" class="modal">{{ tooltip }}</div>-->

          <!-- Modal -->
          <modal
            v-if="tooltip"
            name="aprModal"
            styles="border-radius:1rem;"
            classes="z-50"
            :adaptive="true"
            height="auto"
            :scrollable="true"
          >
            <div
              class="p-8 pt-16 text-white leading-5 text-sm relative bg-flow-blue dealer-bg-secondary"
            >
              <span
                class="absolute top-5 right-5 flex items-center cursor-pointer"
                @click="$modal.hide('aprModal')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 352 512"
                  fill="#ffffff"
                  class="w-4"
                >
                  <path
                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                  />
                </svg>

                <span class="ml-3 uppercase text-base">Close</span>
              </span>
              {{ tooltip }}
            </div>
          </modal>

          <!-- Call to action (calculate a car) -->
          <!-- TODO: Add alternative find car options? -->
          <button
            class="dealer-btn-primary"
            @click="
              $router.push({
                name: 'calculator',
                params: {
                  reference:
                    $route.params.reference || $store.state.dashboard.application.reference,
                },
              })
            "
          >
            Add a car
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tooltip: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
    apr: {
      type: Number,
      default: null,
    },
  },

  methods: {
    /**
     * Returns a colour keyword based on the type of tip
     * @param {String} hasPassed
     */
    getCssClassFromType(hasPassed) {
      let classes = '';
      if (hasPassed) {
        classes += 'border-red-500 bg-red-500 sm:bg-transparent';
      } else {
        classes += 'border-flow-teal dealer-bg-secondary sm:bg-transparent';
      }
      classes +=
        'sm:bg-transparent flex items-center justify-center dealer-text-primary font-bold border-0';

      return classes;
    },

    show(name) {
      this.$modal.show(name);
    },
    hide() {
      this.$modal.hide('aprModal');
    },
  },
};
</script>
