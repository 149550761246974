<template>
  <div class="page page--bank">
    <div class="mx-auto max-w-7xl w-full">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-3">
        <div class="col-span-2 space-y-3">
          <h2>Please enter your bank details below</h2>
          <p>Add your bank details to access a wider pool of lenders.</p>
        </div>

        <!-- Form -->
        <div class="cols-span-2 mt-4">
          <ValidationObserver v-slot="{ invalid }">
            <!-- Sort Code -->
            <div class="mt-2 items-center">
              <label
                for="make"
                class="block text-lg font-semibold leading-5 dealer-text-primary sm:mt-px sm:pt-2"
              >
                Sort Code
              </label>

              <div class="mt-1 sm:mt-0 sm:col-span-1">
                <div class="rounded-md shadow-sm sm:w-1/2">
                  <ValidationProvider
                    :rules="
                      'min:' + form.sortcode.max + '|max:' + form.sortcode.max + '|required|numeric'
                    "
                    v-slot="errors"
                  >
                    <input
                      id="sort-code-input"
                      type="text"
                      pattern="[0-9]*"
                      ref="sort_code_0"
                      autocomplete="off"
                      @keyup="shouldFocusNext('sort_code_1', errors, $event)"
                      @keypress="isNumber($event)"
                      maxlength="6"
                      v-model="form.sortcode.value"
                      :class="{ 'has-error': errors.errors.length }"
                      class="flex-1 text-center block form-input transition duration-150 ease-in-out lg:text-md sm:leading-5 p-3 border-2 border-grey"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <!-- Account Number -->
            <div class="mt-6 items-center">
              <label
                for="make"
                class="block text-lg font-semibold leading-5 dealer-text-primary sm:mt-px sm:pt-2"
              >
                Account Number
              </label>
              <div class="mt-2 sm:mt-0 sm:col-span-1">
                <ValidationProvider rules="min:8|max:8|required|numeric" v-slot="errors">
                  <div class="rounded-md shadow-sm sm:w-1/2">
                    <input
                      id="account-number-input"
                      type="text"
                      ref="account_number"
                      v-model="form.accountnumber.value"
                      pattern="[0-9]"
                      v-on:keypress="isNumber($event)"
                      :maxlength="form.accountnumber.max"
                      class="block form-input w-full transition duration-150 ease-in-out lg:text-md sm:leading-5 p-3 border-2 border-grey text-center"
                    />
                  </div>
                  <div
                    class="bg-red-700 text-white w-1/2 text-sm rounded-b-sm"
                    :class="errors[0] ? 'py-1 px-4' : 'p-0'"
                  >
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
            </div>

            <!-- Submit Bank Details -->
            <button
              @click="submit"
              :disabled="invalid"
              @submit.prevent="handleSubmit(submit)"
              class="lg:w-1/2 mt-16 button text-center text-white dealer-bg-primary border-flow-blue border-2 hover:bg-white hover:border-2 hover:border-flow-blue hover:dealer-text-primary block w-full px-3 py-3"
              :class="{ loading: isLoading }"
            >
              <template v-if="isLoading">
                <span :class="{ loading: isLoading ? 'block' : 'hidden' }"
                  ><i class="mx-auto fal fa-spinner-third animate-spin"></i
                ></span>
                Verifying...
              </template>
              <template v-else>Submit details</template>
            </button>
            <p class="mt-3 dealer-text-primary">
              By submitting your bank details you authorise us to do a full credit check.
            </p>
            <p v-if="takingTooLong" class="text-center md:text-left mt-4">
              <strong
                >It's taking longer than usual to verify your details but we're still working on
                it.</strong
              >
            </p>
          </ValidationObserver>
        </div>
      </div>

      <div class="sm:w-1/2">
        <h2>
          <template v-if="isSuccessful"> Thank you! We've updated your details </template>
          <template v-else> Bank details already submitted </template>
        </h2>

        <div class="mt-6">
          We've already received your bank details but if you would like to update these, please
          call our customer service team on
          <strong><a href="tel:0161 406 3994" title="0161 406 3994">0161 406 3994</a></strong
          >, alternatively use the online chat.
        </div>
        <button
          @click="$router.push({ path: 'home' })"
          class="mt-6 underline hover:no-underline background-transparent font-bold uppercase py-1 text-sm outline-none focus:outline-none mr-1 mb-1"
          type="button"
          style="transition: all 0.15s ease"
        >
          Go to dashboard
        </button>
      </div>
    </div>

    <!-- Helpful error modal -->
    <modal
      name="helpfulError"
      styles="border-radius:1rem; background-color: #272A5C;"
      classes="p-8 text-white"
      :adaptive="true"
    >
      <div class="flex flex-col">
        <div>
          <h2 class="text-3xl leading-9 font-bold">{{ errorMessages.helpfulError.title }}</h2>
          <div class="mt-6">{{ errorMessages.helpfulError.message }}</div>
        </div>
        <span class="flex items-center cursor-pointer mt-4" @click="$modal.hide('helpfulError')">
          <i class="fal fa-times fa-xs"></i> <span class="ml-3 uppercase text-base">Close</span>
        </span>
      </div>
    </modal>

    <!-- Timed out modal -->
    <modal
      name="timedOut"
      styles="border-radius:1rem; background-color: #272A5C;"
      classes="p-8 text-white"
      :adaptive="true"
    >
      <div class="flex flex-col">
        <div>
          <h2 class="text-3xl leading-9 font-bold">{{ errorMessages.timedOut.title }}</h2>
          <div class="mt-6">{{ errorMessages.timedOut.message }}</div>
        </div>
        <span class="flex items-center cursor-pointer mt-4" @click="$modal.hide('timedOut')">
          <i class="fal fa-times fa-xs"></i> <span class="ml-3 uppercase text-base">Close</span>
        </span>
      </div>
    </modal>

    <!-- Invalid bank details modal -->
    <modal
      name="invalidData"
      styles="border-radius:1rem; background-color: #272A5C;"
      classes="p-8 text-white"
      :adaptive="true"
    >
      <div class="flex flex-col">
        <div>
          <h2 class="text-3xl leading-9 font-bold">{{ errorMessages.invalidData.title }}</h2>
          <div class="mt-6">{{ errorMessages.invalidData.message }}</div>
        </div>
        <span class="flex items-center cursor-pointer mt-4" @click="$modal.hide('invalidData')">
          <i class="fal fa-times fa-xs"></i> <span class="ml-3 uppercase text-base">Close</span>
        </span>
      </div>
    </modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, numeric, min, max } from 'vee-validate/dist/rules';
import Bugsnag from '@bugsnag/js';

extend('required', required);
extend('numeric', numeric);
extend('min', min);
extend('max', max);

export default {
  name: 'bank-details',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      errorMessages: {
        timedOut: {
          title: 'Connection timed out',
          message:
            'There was a problem contacting our banking verification service. We are working to resolve it. In the meantime, you can still chat with our team online, or by phone on 0161 406 3994 to resolve this issue.',
        },
        invalidData: {
          title: 'Invalid details',
          message:
            'The details provided are invalid, please confirm they are correct and try again.',
        },
        helpfulError: {
          title: 'Sorry, we ran into an issue',
          message:
            'We are aware of the problem and are working to resolve it. In the meantime, you can still chat with our team online, or by phone on 0161 406 3994 to resolve this issue.',
        },
      },
      takingTooLong: false,
      isLoading: false,
      isSuccessful: false,
      form: {
        sortcode: {
          value: null,
          max: 6,
          submitted: false,
        },
        accountnumber: {
          value: null,
          max: 8,
          submitted: false,
        },
      },
    };
  },

  methods: {
    shouldFocusNext(targetRef, errors = null, e) {
      if (errors.invalid) {
        return;
      }

      // Better way to check that the field wasn't focused with a keyboard shortcut? (Alt+TAB)
      if (this.$refs[targetRef] && e.keyCode !== 16 && e.keyCode !== 9) {
        this.$refs[targetRef].focus();
      }
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    getBankingDetails() {
      return {
        sort_code: this.form.sortcode.value.toString(),
        account_number: this.form.accountnumber.value,
      };
    },

    initStatuses() {
      if (!this.application) {
        return;
      }
      const statuses = this.application.statuses;
      if (!statuses || !statuses.length) {
        return;
      }
      this.status = this.getLatestStatus(statuses);
    },

    async submit() {
      if (!this.isLoading) {
        this.isSuccessful = false;
        let i = 0;
        let timer = setInterval(() => {
          if (i > 4) {
            this.takingTooLong = true;
            clearInterval(timer);
          }
          i++;
        }, 1000);

        this.isLoading = true;

        // Fake a successful request
        if (process.env.NODE_ENV !== 'production' && process.env.VUE_APP_BANKING_FAKE_RESULT) {
          console.log(
            '%c⚠ App in development mode - faking the call to save bank details!',
            'color:black; background:yellow; padding:5px 15px;'
          );
          setTimeout(() => {
            if (process.env.VUE_APP_BANKING_FAKE_RESULT === 'success') {
              this.application.applicant.banking_stored = true;
              this.isSuccessful = true;
              console.log(
                '%cIgnore Vuex mutation error above (this is temporary while faking a successful call',
                'color:lightblue; background:black; padding:5px 15px;'
              );
              console.log(
                '%c✔ Faked a successful response. Vuex error above can be ignored',
                'color:lightgreen; background:black; padding:5px 15px;'
              );
            } else {
              this.$modal.show('helpfulError');
              console.log(
                '%c❌ Faked a failed response',
                'color:red; background:black; padding:5px 15px;'
              );
            }

            this.isLoading = false;
            this.takingTooLong = false;
            clearInterval(timer);
          }, 3000);
        } else {
          this.apiStoreBankingDetails(
            this.reference,
            this.application.applicant,
            this.getBankingDetails()
          )
            .then((r) => {
              // Banking details submitted successfully
              if (r.data.success) {
                // Refresh the current application
                this.refreshCurrentApplication();
                this.isSuccessful = true;
              } else {
                // The API returns an error code as
                if (r.data.errors.length) {
                  let err = r.data.errors[0];

                  // Invalid data
                  if (!isNaN(err) && err === 458) {
                    this.$modal.show('invalidData');
                    return;
                  }

                  // Timed out
                  if (err === 'API408') {
                    this.$modal.show('timedOut');
                    return;
                  }
                }

                // Helpful error
                this.$modal.show('helpfulError');

                // Record the error
                Bugsnag.leaveBreadcrumb('API response', r.data, 'log');
                Bugsnag.notify(new Error('Error saving bank details'));
              }
            })
            .catch((e) => {
              this.isLoading = false;
              this.$modal.show('helpfulError');

              Bugsnag.leaveBreadcrumb('Axios exception', e, 'error');
              Bugsnag.notify(new Error('Error saving bank details (Axios exception)'));
            })
            .finally(() => {
              this.isLoading = false;

              // Clear the timer
              this.takingTooLong = false;
              clearInterval(timer);
            });
        }
      }
    },
  },
  async mounted() {
    this.initStatuses();
  },
};
</script>

<style lang="scss" scoped>
// Move this to global styles?
button:disabled {
  opacity: 0.65;
}
.has-error {
  border: 2px solid red !important;
}
</style>
