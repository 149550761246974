<template>
  <section class="dashboard-faqs">
    <template v-if="dealer.faqs">
      <div v-html="dealer.faqs" />
    </template>

    <template v-else>
      <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12">
        <div v-for="(faq, i) in faqs" :key="i">
          <dt class="text-xl leading-6 font-bold dealer-text-primary">
            {{ faq.title }}
          </dt>
          <dd class="mt-2 text-base text-gray-600" v-html="faq.copy"></dd>
        </div>
      </dl>
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'FAQs',

  computed: {
    ...mapState(['dealer']),
  },

  data() {
    return {
      scTimer: 0,
      scY: 0,
      faqs: [
        {
          title: 'Can I arrange my own finance?',
          copy: '<p>No. We’re a car finance company and not a car dealer.</p>',
        },
        {
          title: 'How can I pay for my car?',
          copy: '<p>You can reserve your car with a small holding fee of £199. The balance is financed by either Hire Purchase (HP) or Personal Contract Purchase (PCP). We can arrange what option is best for you at a very competitive rate.</p>',
        },
        {
          title: 'Will I have to negotiate with your dealers?',
          copy: '<p>No. There’s no need for you to haggle with the dealer as we operate a fixed price model. If you’re looking to buy a used car or a model that’s almost new from one of our dealers, we can handle the whole process for you.</p>',
        },
        {
          title: 'How will I receive my car?',
          copy: "<p>If you finance a used car from us, it's up to you and the dealer to complete delivery arrangements. Many dealers will deliver for a small fee depending on your location.</p>",
        },
        {
          title: 'How long will the whole process take?',
          copy: '<p>Used cars from our dealers are delivered as soon as possible once we confirm the details with you and receive your payment. We’ve delivered cars in as little as 48 hours.</p>',
        },
        {
          title: 'Why buy from Flow Car Finance?',
          copy: ' 1) Quick and easy application form online, choose your car from the nation’s best car retailers<br>2) You can shop with us to find your perfect car all from the palm of your hands<br>3) Once you’re accepted, your car is ready for collection or delivery with full e-sign and contactless options',
        },
        {
          title: 'Find the right car for you',
          copy: "<p>When your finance is accepted, we can help you find competitively priced cars from the best UK dealers, or you can find your own and calculate your deal in our dashboard by just entering the car registration number. We don't deal with finance for private sales at the moment.</p>",
        },
        {
          title: 'What if I want to speak to someone?',
          copy: "<p>We love talking to our customers.</p><p>Once you have verified your number, you can use all the messaging channels you love, like Whatsapp, Facebook Messenger, SMS, Livechat and email.</p><p>You can call us on <a href='tel: 0161 406 3994'>0161 406 3994</a> anytime during our office opening hours between 8am to 6pm on weekdays, 9am to 5pm on Saturday or 10am to-4pm on Sunday.</p>",
        },
        {
          title: 'Is it safe to buy a car without seeing it physically?',
          copy: '<p>Yes. We only list cars from our trusted partners, while all our used cars are inspected and verified against the HPI database.</p><p>Along with the warranty, you will also have a money back guarantee within 14 days from your car’s delivery date, if you decide to change your mind.</p><p>Please see our returns and cancellations policy for mileage limits and any administrative costs.</p>',
        },
        {
          title: 'Can I trade in my car?',
          copy: '<p>Yes, but most customers use our car buying service. We’ll ask our car buying partners to get you the best price from a range of UK dealers.</p><p>Fill in our easy calculator and we’ll start the ball rolling. If you decide to go ahead, we’ll give you an exact price for your existing car. We can also collect the car from your home address</p>',
        },
        {
          title: 'Can I end my finance contract early?',
          copy: 'Yes. You can return your car to us at any time with our buy back guarantee Depending on the value of your car at the time and the amount remaining on your finance agreement, you will have to pay the difference if you owe more than it is worth.</p><p>We can refinance this for you (subject to acceptance) so you reduce the amount you have to pay.</p>',
        },
        {
          title: 'What documents do I need?',
          copy: '<p>For car finance you will need a copy of your Full UK driving licence or EU driving licence or a proof of address, such as a recent utility bill or bank statement and a second proof of signature, such as a copy of your passport or debit card. Your driving licence should be in your current address. OUr advisors will help you through this online or by phone.</p>',
        },
        {
          title: 'When do I get the paperwork?',
          copy: '<p>When your car is delivered, it will already be in the process of being registered under your name. When you buy a used car, we’ll provide you with the New Keeper section from the V5 logbook as soon as possible, which verifies you as the new owner and will allow you to tax the car online.</p><p>The rest of the logbook is sent to the Driver and Vehicle Licensing Agency (DVLA), who will send you a new version of the logbook in your name.</p>',
        },
        {
          title: 'Are there any hidden fees if I use Flow Car Finance?',
          copy: '<p> No, as we are a credit broker acting on behalf of a wide panel of dealers and lenders. We make a profit from the commission of each finance agreement we arrange.</p><p>We keep our prices low as we can handle large volumes of sales. We also don’t have any fancy showrooms to pay for as we’re an online business.</p>',
        },
        {
          title: 'Who are Flow Car Finance?',
          copy: "<p>Flow Car Finance is your new fast and friendly provider of car finance on the go. We're a brand new part of DSG Group, who've been around for over 30 years now.</p><p>DSG Group operate forward-thinking car finance brands and investments, focused on technology-driven delivery.</p><p>We're a team of 120 people spread across our HQ, located in Stockport in Cheshire and regional offices in London and Glasgow with a Prestige Division operating from Yorkshire.</p>",
        },
        {
          title: 'Do all your cars come with a warranty?',
          copy: '<p>Yes. Most cars under 3 years old will have the balance of the car manufacturer warranty. As a minimum, each car will have a standard mechanical warranty of 30 days.</p><p>Vehicles sold under manufacturer approved used car programmes by franchised dealers usually have longer warranty periods with 1 year or more as standard.</p><p>Dealers will offer you the chance to upgrade your warranty or you can also do this online</p>',
        },
        {
          title: 'What is the maximum age of the vehicle you will fund?',
          copy: "<p>Each lender we use has different rules. In general terms, vehicles up to 6 years old for a personal contract plan and no more than 10 years old at the end of the agreement. Some lenders will go to 8 years old at the start on premium brands like Audi, BMW, and Mercedes-Benz, but only for drivers with a high credit score.</p><p>For Hire Purchase agreements, a maximum of 8 years old at the beginning and 12-14 years old at the end of the agreement.</p><p>If you're in doubt. chat to the team and they will be able to advise on your vehicle.</p>",
        },
        {
          title: 'How does Flow Car Finance work?',
          copy: "<div class='in-paragraph-title mt-0'>1. Quote and Apply</div><p>Once you have applied for finance, we use our FibreTM technology to instantly match your personal car finance needs to our lender products to secure the right car finance for you. You just sit back.</p><div class='in-paragraph-title'>2. Find the right car for you</div><p>When your finance is accepted, we’ll use our smart search technology to bring you competitively priced cars from the best UK dealers. Choose online or at a dealer.</p><div class='in-paragraph-title'>3. Contactless E-sign plus Click and Collect or Home Delivery</div><p>E-sign at home and complete control.. Our dealers are delivering a safe experience with enhanced hygiene and click and collect or delivery options. Delivery charges may apply.</p>",
        },
        {
          title: 'How Flow Car Finance works?',
          copy: '<p>Find the perfect car from the comfort of your hands. Collected or delivered to your door with a 14 day money-back guarantee. Buying your dream car couldn’t be easier, with our quick and easy application form, you’re only a few clicks away from getting finance that is suitable for your car.<p></p>You can pay a deposit upfront or apply for low-rate finance in a few minutes. All you have to do is sit back while we order your car and get it ready for collection or delivered to your door.</p><p>1) Apply for car finance through our quick and easy application form to receive the best competitive rates</p><p>2) Shop online to find your perfect car from our collection of 100,000+ nearly new or used cars to order</p>3) Ready for your collection or delivery with a 14 day return guarantee</p><p>4) Contact free e-sign options at approved dealers.</p>',
        },
        {
          title: 'How do your car finance options work?',
          copy: '<p>Car finance is flexible to suit a wide range of people. Initially, you have to decide how much you can afford for a deposit. If you decide to use Hire Purchase (HP), then the overall cost of your car will be divided into small monthly payments. The longer your contract, the lower your payments will be. At the end of the deal, you get to keep the car.</p><p>Personal Contract Purchase (PCP) usually has cheaper monthly payments because you only pay a small amount of the car’s total cost over the course of the contract. When the deal comes to an end, you can either decide to pay the remainder (balloon payment) and keep the car or return the car to us and you won’t have to pay the remaining balance.</p><p>We can help you to decide which car finance option is best for you. We’ll discuss the full terms and conditions before you sign up to anything. You can be rest assured we are authorised and regulated by the Financial Conduct Authority.</p>',
        },
        {
          title: 'What to expect after you order/reserve your car?',
          copy: '<p>Once you’re approved, you will be asked to sign a few documents. Usually, we’ll need your driving license or proof of address, like a recent utility bill or bank statement.</p><p>We’ll get in touch with one of our exclusive car dealers to make sure that your car is still available and ready to be collected or delivered.</p><p>For your convenience, we’ll send you a text for a delivery date of your choice to suit your life schedule.</p><p>Without a single sales person in sight, your car arrives safely and smoothly at your door-step or ready to drive away from your local collection point.</p>',
        },
        {
          title: 'Hire Purchase',
          copy: "<p>HP splits the cost of a new or used car into monthly installments of small payments. Since the payment covers the total cost of the car, the monthly payment and interest rates are usually higher than a PCP agreement.</p><div class='in-paragraph-title'>1) Deposit & Delivery</div><p>Paying a deposit upfront reduces the final total cost of the car and also lowers your monthly payments. Zero deposit options are usually available.</p><div class='in-paragraph-title'>2) Monthly Payments</div><p>You then pay for the rest of the car with a series of monthly payments that are fixed covering the length of the car finance agreement.</p><div class='in-paragraph-title'>3)  You Now Own the Car</div><p> Once you've paid the final installment of your monthly payments, the car legally belongs to you now.</p>",
        },
        {
          title: 'Finance Options',
          copy: '<p>Our flexible options with low interest rates only mean one thing, and that’s our promise to offer you car finance that’s personalised to suit your lifestyle with affordable monthly payments from our wide range panel of exclusive lenders.</p><p>Most of the time you don’t even have to pay a deposit, but you can adjust the amount of your deposit, the length of your monthly agreement and any mileage limit to find a deal that suits your current lifestyle.</p><p>Apply for car finance now by clicking here or keep reading for more information on the two most common ways of spreading the cost for your next car.</p><p>Regardless of your lifestyle and needs, if you’re thinking of owning your next car for the lowest total cost or you’d prefer an alternative route of paying low monthly installments with an option to own the car at the end of the finance agreement, we have suitable car finance for you.</p><p>Keep reading our guide below to understand which option is best for you.</p>',
        },
        {
          title: 'How does the 14 day return work?',
          copy: '<p>Once you have signed your finance documents and taken delivery, you have 14 days to cancel and revoke the agreement. You can return it us by contacting us within 14 days of delivery provided that it hasn’t been driven more than 40 miles over the mileage from your delivery paperwork.</p><p>Once you have notified us of your request to cancel, we will arrange for the car to be collected and the following charges will apply to cover our return costs.</p><p>Collection charge £199 Mainland UK, £299 Northern Ireland.</p><p>Excess Mileage Charges: 40 pence per mile after the first 30 miles</p><p>Valeting Charge £60</p><p>You should provide us with all keys and paperwork including the vehicle registration document counterpart.</p><p>You should ensure that the vehicle condition is the same as it was on delivery, specifically with regards to damage, accessories fitted, alloy wheels and in car entertainment systems.</p><p>We will not make charges if the vehicle is returned by you due to faults or damage that the dealer cannot rectify.</p>',
        },
        {
          title: 'Personal Contract Purchase (PCP)',
          copy: "<p>PCP is the most popular type of car finance because it allows you the freedom to have low monthly payments for nearly new or used cars since you’re not paying the total cost of the car.</p><div class='in-paragraph-title'>1) Deposit & Delivery</div><p>The more you pay as a deposit, the lower your monthly payments will be. We also have zero deposit options available.<div class='in-paragraph-title'>2) Monthly Payments</div><p>Monthly payments are fixed and are low due to the fact that you're only repaying a part of the car's cost.<div class='in-paragraph-title'>3)  Return, Buy or Upgrade</div><p>At the end of your contract, you can return the car and owe us nothing, provided the car is in good condition and under the pre-arranged mileage limit.</p><p>If you want to keep the car, you can refinance it and pay the remaining balance to own the car.</p><p>You can opt-in to exchange the car, if the value of the car is worth more than the remaining balance, this is called equity. You can use this equity towardsr a deposit on a new PCP contract, which will help you to pay even lower monthly repayments on your next car.</p>",
        },
      ],
      currentFaq: -10,
    };
  },

  methods: {
    faqToggle: function (newFaq) {
      if (newFaq === this.currentFaq) {
        this.currentFaq = -10;
      } else {
        this.currentFaq = newFaq;
      }
    },
    beforeEnterFaq: function (_t) {
      _t.style.display = 'block';
      _t.style.maxHeight = null;
      _t.myHeight = _t.offsetHeight;
      _t.style.maxHeight = 0;
      _t.style.display = null;
    },
    enterFaq: function (_t) {
      _t.style.maxHeight = _t.myHeight + 'px';
    },
  },
};
</script>
