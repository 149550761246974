<template>
  <section class="quote-results">
    <template v-if="results.hp === null && results.pcp === null">
      <!-- No quote results, do we have any tips? -->

      <!-- Can we show any tips? -->
      <template v-if="tips.length && !dealer.name">
        <i class="far fa-search text-gray-300 mb-8 mt-8 sm:mt-0" style="font-size: 80px"></i>
        <h3 class="dealer-text-secondary text-lg mb-8">
          Sorry, we couldn't find any quotes for those figures. We've had a look and have narrowed
          it down to the following:
        </h3>
        <div v-for="(tip, i) in tips" :key="i" class="border-2 rounded-lg shadow-md p-8 mt-5">
          <h3 class="font-bold font-2xl">{{ tip.title }}</h3>
          <p class="text-lg" v-html="tip.message" />
          <button
            @click="$router.push({ name: tip.retryRoute })"
            class="mt-2 mx-auto px-6 button btn-md text-white dealer-bg-primary hover:dealer-bg-secondary hover:dealer-text-primary border-2 border-flow-blue block md:inline-block"
          >
            {{ tip.buttonLabel }}
          </button>
        </div>
      </template>

      <!-- No tips either :( -->
      <template v-else>
        <i class="far fa-search text-gray-300 mb-8 mt-8 sm:mt-0" style="font-size: 80px"></i>
        <h3 v-if="dealer.name" class="dealer-text-secondary text-lg mb-8">
          We have not been able to find you a quote with those details. Please ensure your car is
          between 1 month old and 9 years old for HP, or between 1 month old and 5 years old for
          PCP. Also, if your car is within those age brackets and you are still not getting a quote,
          please try a shorter term such as 24 or 36 months.
        </h3>
        <h3 v-else class="dealer-text-secondary text-lg mb-8">
          Sorry, we couldn't find any quotes for the figures you provided. Please try adjusting your
          figures and try again.
        </h3>
        <button
          @click="$router.push({ name: 'dashboard__calculator__figures' })"
          class="mt-2 mx-auto px-6 button btn-md text-white dealer-bg-primary hover:dealer-bg-secondary hover:dealer-text-primary border-2 border-flow-blue block md:inline-block"
        >
          Try again
        </button>
      </template>
    </template>

    <div v-else class="mt-8 w-full">
      <ApplyObserver v-slot:default="scope" tag="div" class="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <!-- Got at least one quote result -->
        <QuoteCard
          @apply="scope.apply"
          :figures="dashboard.calculator.figures"
          :vehicle="dashboard.calculator.vehicle"
          :result="results.pcp"
          type="pcp"
        />
        <QuoteCard
          @apply="scope.apply"
          :figures="dashboard.calculator.figures"
          :vehicle="dashboard.calculator.vehicle"
          :result="results.hp"
          type="hp"
        />
      </ApplyObserver>
      <ConciergeCTA v-if="dashboard.calculator.figures.price > 40000" />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import QuoteHelper from '@/lib/QuoteHelper';
import axios from 'axios';
import QuoteCard from '@/components/Ui/Calculator/QuoteCard';
import ApplyObserver from '@/components/Dashboard/CarSearch/ApplyObserver';
import ConciergeCTA from '@/components/Ui/ConciergeCTA.vue';

export default {
  components: {
    ConciergeCTA,
    ValidationProvider,
    ValidationObserver,
    QuoteCard,
    ApplyObserver,
  },

  data() {
    return {
      results: {
        hp: null,
        pcp: null,
      },
      tips: [],
    };
  },

  computed: mapState(['dashboard', 'dealer']),

  methods: {
    mapQuoteHelperFigures() {
      return {
        AnnualMileage: this.dashboard.calculator.figures.estimated_mileage,
        CashPrice: this.dashboard.calculator.figures.price,
        Term: this.dashboard.calculator.figures.term,
        QuoterAPR: this.getApr() ?? this.defaultApr(),
        PartExchange: this.dashboard.calculator.figures.part_exchange,
        OutstandingSettlement: this.dashboard.calculator.figures.outstanding_settlement,
        DepositCash: this.dashboard.calculator.figures.deposit,
        CurrentMileage: this.dashboard.calculator.figures.current_mileage,
      };
    },

    mapQuoteHelperVehicle() {
      return {
        first_registered: this.dashboard.calculator.vehicle.first_registered,
      };
    },

    runQuoteHelper() {
      const figures = this.mapQuoteHelperFigures();
      const vehicle = this.mapQuoteHelperVehicle();

      const quoteHelper = new QuoteHelper(
        figures.AnnualMileage,
        figures.CashPrice,
        figures.Term,
        figures.QuoterAPR,
        figures.PartExchange,
        figures.OutstandingSettlement,
        figures.DepositCash,
        figures.CurrentMileage,
        false,
        vehicle.first_registered,
        false
      );
      this.tips = quoteHelper.getTips();
    },

    /**
     * Generate the payload to send to Autoquote
     */
    mapQuotePayload() {
      const vehicle = this.dashboard.calculator.vehicle;
      const figures = this.dashboard.calculator.figures;

      return {
        CAPCode: vehicle.cap_code,
        RegistrationDate: vehicle.first_registered,
        AnnualMileage: figures.estimated_mileage,
        CashPrice: figures.price,
        Term: figures.term,
        QuoterAPR: this.getApr() ?? this.defaultApr(),
        PartExchange: figures.part_exchange,
        OutstandingSettlement: figures.outstanding_settlement,
        DepositCash: figures.deposit,
        CurrentMileage: figures.current_mileage,

        // Flags
        FilterBestPrice: true,
        FilterMatchApr: true,
      };
    },

    // mapResult(result) {
    //     return {
    //         APR: result.APR,
    //         CashPrice: result.CashPrice,
    //         FinalPayment: result.FinalPayment,
    //         FlatRate: result.FlatRate,
    //         MonthlyPayment: result.MonthlyPayment,
    //         NetDeposit: result.NetDeposit,
    //         Term: result.Term,
    //         TotalCharge: result.TotalCharge,
    //         TotalPayable: result.TotalPayable,
    //         excess_mileage_rate: result.ExcessMileageRate ?? 0,
    //         id: result.InternalID ?? result.ID,
    //         residual_value: result.ResidualValue ?? 0
    //     }
    // },

    async getQuotes() {
      this.$emit('loading', true, 'Finding best quote...');
      this.tips = [];

      const quoteResults = await this.apiGetAutquoteResults(this.mapQuotePayload());
      if (quoteResults.pcp.length === 1) {
        this.results.pcp = quoteResults.pcp[0];
      }
      if (quoteResults.hp.length === 1) {
        this.results.hp = quoteResults.hp[0];
      }

      // Got no results back, run the quote helper to provide the user with some tips about their figures
      if (this.results.hp == null && this.results.pcp == null) {
        this.runQuoteHelper();
      }

      this.$emit('loading', false);
    },

    /**
     * Returns a fixed object with PCP and/or HP quote result arrays
     * @param {object} figures A complex object containing the quote figures
     * @param {*} type "PCP", "HP" or "BOTH". Default: "BOTH"
     * @returns
     */
    apiGetAutquoteResults(figures = {}, type = 'BOTH') {
      let payload = {
        PartExchange: 0,
        OutstandingSettlement: 0,
        DepositCash: 0,
        CurrentMileage: 0,

        // Additional flags
        FilterBestPrice: false,
        FilterMatchApr: false,
      };

      // Validate AnnualMileage property
      if (figures.AnnualMileage === null) {
        throw new Error('AnnualMileage property is required');
      }
      if (isNaN(figures.AnnualMileage)) {
        throw new Error('AnnualMileage property must be a valid number');
      }
      if (figures.AnnualMileage < 6000 || figures.AnnualMileage > 40000) {
        throw new Error('AnnualMileage property must be between 6,000 and 40,000');
      }
      // Round AnnualMileage to the nearest 1000
      payload.AnnualMileage = Math.round(figures.AnnualMileage / 1000) * 1000;

      // Validate cash price
      if (figures.CashPrice === null) {
        throw new Error('CashPrice property is required');
      }
      if (isNaN(figures.CashPrice)) {
        throw new Error('CashPrice property must be a valid number');
      }
      payload.CashPrice = figures.CashPrice;

      // Validate term
      if (figures.Term === null) {
        throw new Error('Term property is required');
      }
      if (isNaN(figures.Term)) {
        throw new Error('Term must be a valid number');
      }
      payload.Term = figures.Term;

      // Validate QuoterAPR
      if (figures.QuoterAPR === null) {
        throw new Error('QuoterAPR property is required');
      }
      if (isNaN(figures.QuoterAPR)) {
        throw new Error('QuoterAPR property must be a valid number');
      }
      if (figures.QuoterAPR < 0 || figures.QuoterAPR > 100) {
        throw new Error('QuoterAPR property must be between 0 and 100');
      }
      payload.QuoterAPR = figures.QuoterAPR;

      // Validate CAPCode
      if (figures.CAPCode === null) {
        throw new Error('CAPCode property is required');
      }
      payload.CAPCode = figures.CAPCode;

      // Validate RegistrationDate
      if (figures.RegistrationDate === null) {
        throw new Error('RegistrationDate property is required');
      }
      payload.RegistrationDate = figures.RegistrationDate;

      // Type of quotes to fetch
      payload.Type = type;

      // Optional fields
      payload.PartExchange = figures.PartExchange;
      payload.OutstandingSettlement = figures.OutstandingSettlement;
      payload.DepositCash = figures.DepositCash;
      payload.CurrentMileage = figures.CurrentMileage;
      payload.FilterBestPrice = figures.FilterBestPrice;
      payload.FilterMatchApr = figures.FilterMatchApr;

      // Application reference
      payload.application_reference = this.dashboard.application.reference;

      // Vehicle
      const vehicle = this.dashboard.calculator.vehicle;
      delete vehicle.addedManually;
      payload.vehicle = vehicle;

      // return axios.post(this.getApiBaseUrl()+"/calculator/dashboard", payload).then(r => {
      return axios
        .post(this.getApiBaseUrl() + '/v1/calculator/quotes/results.json', payload)
        .then((r) => {
          if (r.data.success && r.data.data.quotes) {
            return r.data.data.quotes;
          }
          throw r.data.errors;
        })
        .catch((e) => {
          console.error(e);
          // this.apiErrorHandler(e, "Error fetching Autoquote quote results")
          return {
            pcp: [],
            hp: [],
          };
        });
    },
  },

  async mounted() {
    // Have they selected a vehicle?
    // TODO: Validate quote figures too
    if (this.dashboard.vehicle === null) {
      return await this.$router.push({ name: 'dashboard__calculator__quotes' });
    }

    // Get quotes
    await this.getQuotes();
  },
};
</script>
