import {
  required,
  length,
  email,
  numeric,
  min,
  min_value,
  max_value,
  max,
} from 'vee-validate/dist/rules';
import { extend, localize } from 'vee-validate';
import { stripMobilePrefix } from '../helpers';
import * as moment from 'moment';

extend('required', required);
extend('length', length);
extend('numeric', numeric);
extend('email', {
  ...email,
  message: (field, rules) => 'Please enter a valid email',
});
extend('max_no_space', {
  ...max,
  validate: (value, rules) => {
    if (!value) {
      return false;
    }
    if (!value.length) {
      return false;
    }
    return value.trim().replace(/\s/g, '').length <= rules.length;
  },
  message: (field, rules) => 'This field can only contain a max of ' + rules.length + ' characters',
});
extend('min_value', {
  ...min_value,
  message: (field, rules) => 'The minimum value is ' + rules.min,
});
extend('max_value', {
  ...max_value,
  message: (field, rules) => 'The maximum value is ' + rules.max,
});

extend('min', {
  ...min,
  validate: (value, rules) => {
    if (!value) {
      return false;
    }
    if (!value.length) {
      return false;
    }
    return value.trim().length >= rules.length;
  },
  message: (field, rules) => 'This fields needs at least ' + rules.length + ' characters',
});

extend('smaller_than', {
  validate: (value, { compareTo }) => {
    return parseInt(value) <= parseInt(compareTo);
  },
  message: 'Invalid amount',
  params: ['compareTo'],
});

extend('year', {
  ...required,
  message: 'The year needs to be valid',
  validate: (value) => {
    let date = moment(value, 'YYYY', true);
    return date.isValid();
  },
});

extend('date', {
  ...required,
  message: 'This date needs to be valid',
  validate: (value) => {
    let date = moment(value, 'YYYY-MM-DD', true);
    return date.isValid();
  },
});

extend('year_not_future', {
  ...required,
  message: "The year can't be in the future",
  validate: (value) => {
    let year = moment(value, 'YYYY', true);
    return year.isSameOrBefore(moment().startOf('year'));
  },
});

extend('year_not_future_with', {
  validate: (value, { target }) => {
    if (['', null, undefined].indexOf(target) > -1) {
      return true;
    }
    // We don't have a day so we default to first day of the month to check (earliest possible point in the month)
    target = parseInt(target) - 1;
    let date = moment([value, target, 1]);
    return date.isValid() && date.isSameOrBefore(moment().startOf('month'));
  },
  message: "This date can't be in the future",
  params: ['target'],
});

extend('month_not_future_with', {
  validate: (value, { target }) => {
    if (['', null, undefined].indexOf(target) > -1) {
      return true;
    }
    // We don't have a day so we default to first day of the month to check (earliest possible point in the month)
    value = parseInt(value) - 1;
    let date = moment([target, value, 1]);
    return date.isValid() && date.isSameOrBefore(moment().startOf('month'));
  },
  message: "This date can't be in the future",
  params: ['target'],
});

extend('month_not_after_with', {
  validate: (value, { year, compareTo }) => {
    if (['', null, undefined].indexOf(year) > -1) {
      return true;
    }
    // We don't have a day so we default to first day of the month to check (earliest possible point in the month)
    value = parseInt(value) - 1;
    let date = moment([year, value, 1]);
    return date.isValid() && date.isSameOrBefore(compareTo);
  },
  message: 'This date is too recent',
  params: ['year', 'compareTo'],
});

extend('year_not_after_with', {
  validate: (value, { month, compareTo }) => {
    if (['', null, undefined].indexOf(month) > -1) {
      return true;
    }
    // We don't have a day so we default to first day of the month to check (earliest possible point in the month)
    month = parseInt(month) - 1;
    let date = moment([value, month, 1]);
    return date.isValid() && date.isSameOrBefore(compareTo);
  },
  message: 'This date is too recent',
  params: ['month', 'compareTo'],
});

extend('min_years_old', {
  validate: (value, { years }) => {
    let date = moment(value, 'YYYY-MM-DD', true),
      yearsAgoDate = moment().subtract(years, 'years');
    return date.isValid() && date.isSameOrBefore(yearsAgoDate);
  },
  message: (field, { years }) => {
    return 'You must be at least ' + years + ' years old';
  },
  params: ['years'],
});

extend('date_min_year', {
  validate: (value, { year }) => {
    let date = moment(value, 'YYYY-MM-DD', true),
      yearInPastDate = moment([year, 0, 1]);
    return date.isValid() && date.isSameOrAfter(yearInPastDate);
  },
  message: (field, { year }) => {
    return 'The year needs to be after ' + year;
  },
  params: ['year'],
});

extend('mobile_phone', {
  ...required,
  validate: (value) => {
    value = stripMobilePrefix(value);

    const regex = /^070|^071|^072|^073|^074|^075|^07624|^077|^078|^079/i;
    return regex.exec(value) !== null;
  },
  message: 'Please provide a valid mobile number',
  params: ['length'],
});

extend('mobile_phone_length', {
  ...length,
  validate: (value, { length }) => {
    value = stripMobilePrefix(value);
    return value.length === length;
  },
  message: (field, { length }) => {
    return 'Please provide a valid mobile number';
  },
});

extend('required_if_other_empty', {
  ...required,
  validate: (value, { target }) => {
    if (['', null, undefined].indexOf(target) > -1) {
      return ['', null, undefined].indexOf(value);
    }
    return true;
  },
  message: (field, { target }) => {
    return 'At least one between ' + field + ' and ' + target + ' is required';
  },
  params: ['target'],
});

localize({
  en: {
    messages: {
      required: 'This field is required',
    },
    fields: {
      date_of_birth: {
        required: 'Please enter your date of birth',
      },
      phone: {
        length: 'The phone number needs to contain 11 digits',
      },
    },
  },
});
